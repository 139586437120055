.item-popup-footer {
    width: 100%;
    bottom: 0px;
    height: 56px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    transition: all 0.3s;
    opacity: 0.89;
}

.item-popup-footer:hover {
    opacity: 1;
}

.item-popup-footer .btn-exit {
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    background: none;
}