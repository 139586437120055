.popup-tranche-age-billetterie-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.popup-tranche-age-billetterie-content {
  max-width: 30em;
  height: max-content;
  background-color: white;
  border: 2px solid var(--primary-color);
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3em 1fr 3em;
  width: 100%;
}

.tranche-age-header {
  grid-row: 1/1;
  background-color: var(--primary-color);
  align-items: center;
  justify-content: center;
  display: flex;  
  color: white;
}

.tranche-age {
  display: grid;
  grid-row: 2/2;
  padding: 0 10px;
  grid-template-columns: 2fr;
}

.tranche-age-row {
  display: flex;
  align-items: center;
  padding: 0 10px;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 3px 0;
}

.tranche-age-button {
  grid-row: 3/3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-direction: row;
}

.tranche-age-button button {  
  padding: 5px;
  width: 100px;
  margin:5px;
}

.tranche-age .SpinInput {
  width: 5em;
  grid-column: 2/2;
}
.tranche-age .SpinInput input {
  font-size: 1em;
}
