.container-boutique {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    position: relative
}

.products-grid .ais-search-header {
    position: relative;
    /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: .5rem 1rem 1rem .5rem;
    background: #f3f3f2;
    color: #d9d9d6;
    line-height: 1.6;
}

.d-none {
    display: none!important
}

.ais-page .ais-change-display {
    position: relative;
    top: .3125rem
}

.ais-page .ais-change-display i {
    line-height: 1
}

.products-grid .ais-pagination-container {
    align-self: center
}

.ais-instant-search-hits-header .search-header>font {
    margin-right: 8px
}

.ais-instant-search-hits-header .seo-search-query {
    font-family: "TT Norms Medium", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.375rem;
    line-height: 1
}

.products-grid .ais-instant-search-hits-header {
    text-transform: uppercase;
    margin: .625rem 0;
    padding: 0 .9375rem;
    font-family: "TT Norms Medium", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.375rem
}

.products-grid {
    padding: 0;
    margin-top: 0
}

.ais-page .ais-results-as-block .ais-hits--item {
    border: 4px solid rgb(255, 103, 76, 1);
    display: inline-block;
    margin: 4px;
    /* width: 25%; */
}

@media only screen and (min-width: 900px) {
    .ais-page .ais-hits--item {
        width: 30%;
        max-width: 30%;
    }
}

@media only screen and (min-width: 1300px) {
    .ais-page .ais-hits--item {
        width: 24%;
        max-width: 24%;
    }
}

@media only screen and (max-width: 992px) {
    .ais-page .ais-hits--item {
        width: 45%;
        max-width: 45%;
    }
}

@media only screen and (max-width: 778px) {
    .ais-page .ais-hits--item {
        width: 48%;
        max-width: 48%;
    }
}

@media only screen and (max-width: 422px) {
    .ais-page .ais-hits--item {
        width: 100%;
        max-width: 100%;
    }
}

.ais-hits--item .product-thumbnail__features {
    margin-left: 1.2rem
}

.ais-hits--item .product-thumbnail__features li {
    padding: 5px 0;
    line-height: 1.2em
}

.ais-hits--item {
    margin-top: 2.5rem;
}

.ais-hits--item, .item {
    border-bottom: .0625rem solid #d9d9d6
}

.products-grid .item {
    margin-top: 2.5rem;
    padding-bottom: 2.5rem
}

.products-grid--list .ais-hits--item, .products-grid--list .item {
    width: 100%;
    margin: 0;
    padding: 0;
    border: none
}

.products-grid--list .ais-hits--item:last-child .product-thumbnail, .products-grid--list .item:last-child .product-thumbnail {
    border-bottom: 0
}

.header--mobile {
    flex-wrap: nowrap
}

.ais-page {
    position: relative
}

.products-grid .ais-input {
    display: none
}

.ais-input-button {
    border-bottom: .0625rem solid #444
}

.ais-refine-search-label {
    color: #444
}

.ais-change-display .fa {
    line-height: 1
}

.ais-facets {
    display: none;
    padding: .625rem 1.25rem;
    float: left;
    max-width: 25%;
    width: 25%;
}

.ais-sort {
    display: inline-block;
    position: relative;
    width: 100%;
    min-width: 150px;
    vertical-align: bottom;
    color: #444
}

.ais-sort.margin-bill {
    margin-left: auto;
    position: relative;
}

.ais-sort-label {
    display: none
}

.ais-sort-orders-container::after {
    font-family: glyphs_15;
    font-size: .875rem;
    position: absolute;
    top: 1.25rem;
    right: .875rem;
    transform: translateY(-50%);
    pointer-events: none
}

.ais-sort-orders-container select::-ms-expand {
    display: none
}

.products-grid .ais-sort-by-selector {
    height: 2.5rem;
    width: 100%;
    min-width: 280px;
    margin: 0;
    padding: .5rem 1rem .5rem;
    border-radius: 0;
    color: #444;
    background-color: #fff;
    text-align-last: center;
    cursor: pointer
}

.ais-hits {
    display: flex;
    flex-wrap: wrap
}

.ais-results-as-block {
    border: none
}

.ais-block {
    width: 100%;
    border: none;
    float: left;
}

.icon--x {
    color: #c00
}

.money+.money-details {
    display: block;
    float: left;
    line-height: 1.25
}

.money-details .bottom-cart, .money-details .bottom-product {
    font-size: .875rem;
    line-height: .875rem;
    margin-top: .125rem
}

.money-details .top-product {
    color: #747679;
    font-size: .875rem
}

.money-details .eco-fees {
    flex-basis: 100%
}

.checkbox-filter {
    display: flex;
    position: relative;
    margin: 0 0 .5rem
}

.checkbox__label {
    display: block;
    color: #444;
    font-size: .875rem;
    line-height: 1.25rem;
    cursor: pointer;
    transition: all .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .checkbox__label {
        transition: none
    }
}

.checkbox__label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    border: .0625rem solid #d9d9d6;
    font-weight: 700;
    background-color: #fff;
    z-index: 0;
    transition: all .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .checkbox__label::after {
        transition: none
    }
}

.checkbox__element {
    -webkit-appearance: none;
    margin-right: .5rem;
    height: 1.25rem;
    width: 1.25rem;
    flex-shrink: 0;
    border: .0625rem solid #747679;
    text-align: center;
    transition: all .2s ease-in-out;
}

@media (prefers-reduced-motion:reduce) {
    .checkbox__element {
        transition: none
    }
}

.checkbox__element::-ms-check {
    display: none
}

.checkbox__element:disabled {
    background-color: #f3f3f2;
    border: solid thin #d9d9d6
}

.checkbox__element:checked+label::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    z-index: 1;
    text-align: center;
    color: #fff;
    font-weight: 700;
    border: 1px solid #037f96;
    content: "";
    font-family: glyphs_15;
    font-size: .625rem
}

.checkbox__element:focus {
    outline: 0
}

.checkbox__element:focus+label {
    color: #037f96
}

.checkbox__element:focus+label::after {
    border-color: #037f96
}

.ais-change-display .radio {
    display: flex;
    margin: 0 0 .5rem
}

.select--inline .select {
    margin: 0
}

.select__label {
    display: block;
    margin: 0 0 .5rem;
    color: #000
}

.select--inline .select__label {
    flex: 0 0 auto;
    margin: 0
}

.select__element {
    -webkit-appearance: none;
    height: 2.25rem;
    width: 100%;
    padding: .5rem 3.125rem .5rem .75rem;
    border: solid thin #d9d9d6;
    border-radius: 0;
    line-height: 1.15;
    font-size: 1rem
}

.select__element::-ms-expand {
    display: none
}

.select--inline .select__element {
    flex: 1 1 6.5625rem;
    width: 100%;
    background-color: #fff;
    color: #444;
    font-size: .875rem
}

.select__element:disabled {
    background-color: #f3f3f2;
    border: solid thin #d9d9d6
}

.select__element:focus {
    border-color: #037f96;
    outline: 0
}

.ais-block .select::after {
    content: "";
    font-family: glyphs_15;
    font-size: .75rem;
    position: absolute;
    right: .0625rem;
    bottom: .0625rem;
    border-left: .0625rem solid #d9d9d6;
    height: 2.125rem;
    width: 2.25rem;
    line-height: 35px;
    pointer-events: none;
    text-align: center
}

.select--inline .select::after {
    border: 0
}

.select--inline {
    display: block;
    position: relative;
    margin: 0
}

.product-quantity {
    display: flex;
    position: relative;
    margin-right: -5.875rem
}

.product-quantity__input {
    position: relative;
    top: .0625rem;
    left: .0625rem;
    z-index: 10;
    height: 2.125rem;
    width: 2.75rem;
    margin: 0;
    padding: 0 .1875rem .0625rem .1875rem;
    border: none;
    border-right: solid thin #747679;
    border-radius: 0;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none
}

.product-quantity__input:disabled {
    border: none;
    background-color: #fff
}

.product-quantity__dropdown {
    position: relative;
    right: 2.75rem;
    margin: 0;
    background: #fff
}

.product-quantity__dropdown::before {
    content: "";
    position: absolute;
    left: .875rem;
    bottom: .5rem;
    z-index: 10;
    height: .0625rem;
    width: 1rem;
    background: #d9d9d6
}

.product-quantity__dropdown::after {
    content: "";
    font-family: glyphs_15;
    font-size: .875rem;
    position: absolute;
    top: 54%;
    right: .875rem;
    transform: translateY(-50%);
    pointer-events: none
}

.product-quantity__list {
    height: 2.25rem;
    width: 5.375rem;
    margin: 0;
    border: solid thin #747679;
    border-radius: 0;
    background: #fff;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer
}

.product-quantity__dropdown-option, .product-quantity__dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.25rem;
    width: 2.625rem;
    cursor: pointer
}

.product-quantity__dropdown-trigger {
    margin: 0;
    border: solid thin #747679
}

.product-quantity__dropdown-trigger .icon {
    font-weight: 700
}

.product-quantity__dropdown-option {
    margin-bottom: 0
}

.product-quantity__dropdown-option--selected {
    border-top: solid thin #747679;
    border-bottom: solid thin #747679
}

.product-quantity input[type=number] {
    -moz-appearance: textfield
}

.product-quantity input[type=number]::-webkit-inner-spin-button, .product-quantity input[type=number]::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none
}

.product-quantity--disabled {
    cursor: not-allowed;
    opacity: .6
}

.product-quantity--disabled input, .product-quantity--disabled select {
    cursor: not-allowed
}

.products-grid .product-thumbnail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: none
}

.products-grid--list .product-thumbnail {
    border-right: none!important
}

.product-thumbnail__header {
    padding-left: 1rem;
    padding-right: 1rem;
    flex: 0 0 auto
}

.product-thumbnail__image {
    margin-bottom: 1rem
}

.products-grid .product-thumbnail__image {
    display: block;
    height: auto;
    width: 100%
}

.product-thumbnail__image-wrap {
    position: relative
}

.products-grid--list .product-thumbnail__image-wrap {
    margin-bottom: 1.875rem
}

.products-grid--list .product-thumbnail__information-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1
}

.product-thumbnail__information .product-thumbnail__rating {
    margin-bottom: .9375rem
}

.product-thumbnail__information .product-thumbnail__rating .bv_main_container .bv_text {
    color: #747679!important
}

.products-grid--list .product-thumbnail__information {
    width: 100%
}

.products-grid .product-thumbnail__icon {
    color: #747679;
    text-transform: uppercase;
    font-size: .875rem
}

.products-grid .product-thumbnail__icon .icon {
    font-size: .8125rem
}

.product-thumbnail__title {
    font-family: Barlow-Black;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    display: block;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* height: 60px; */
}

.products-grid .product-thumbnail__title {
    color: inherit;
    font-size: 1rem;
    line-height: 1.25;
}

.product-thumbnail__title:hover {
    color: rgb(255, 103, 76, 1)!important;
    text-decoration: underline!important;
    cursor: pointer;
}

.product-thumbnail__description {
    padding-bottom: 15px;
}

.products-grid .product-thumbnail__features {
    display: none
}

.product-thumbnail__footer {
    padding-left: 1rem;
    padding-right: 1rem
}

.products-grid .product-thumbnail__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.products-grid .product-thumbnail__data-wrap {
    display: inline-flex
}

.product-thumbnail__price .eco-fees {
    font-size: .875rem;
    padding-top: .3125rem
}

.products-grid--list .product-thumbnail__price .top-product {
    display: flex;
    flex-direction: column
}

.product-thumbnail__price .money {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: .3125rem;
    font-family: "TT Norms Medium", "Helvetica Neue", Arial, sans-serif
}

.products-grid--list .product-thumbnail__price .money {
    font-family: "TT Norms Medium", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.5rem
}

.product-thumbnail__price .quantity-break-label {
    margin-right: .3125rem;
    margin-bottom: .125rem;
    font-size: medium
}

.product-thumbnail__amount .product-thumbnail__amount-data {
    color: #747679;
    font-size: .875rem
}

.products-grid .product-thumbnail__amount {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.products-grid .product-thumbnail__amount-data {
    color: #747679;
    margin-bottom: .125rem
}

.products-grid--list .product-thumbnail__amount {
    margin-top: .9375rem
}

.products-grid--list .product-thumbnail__form .select {
    margin-right: -1.625rem!important
}

.products-grid--list .product-thumbnail__form .select::after {
    content: "";
    border: none
}

.products-grid--list .product-thumbnail__form .product-quantity__dropdown::after {
    right: .8125rem
}

.products-grid--list .product-thumbnail__form .select__element {
    background-color: #fff;
    width: auto;
    padding: .625rem 3.125rem .625rem .9375rem
}

.products-grid--list .product-thumbnail__form .select__element::after {
    top: .0625rem;
    right: -.3rem;
    width: 100%;
    border: none
}

.product-thumbnail__form .service-product--link {
    color: #c00;
    font-size: .875rem;
    transition: all .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .product-thumbnail__form .service-product--link {
        transition: none
    }
}

.product-thumbnail__form .service-product--link:hover {
    outline: 0;
    background: #c00;
    color: #fff;
    text-decoration: none
}

.products-grid .product-thumbnail__cta {
    margin-bottom: 0;
    min-width: auto;
    padding: .625rem
}

.product-thumbnail__packsize {
    color: #747679;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: .125rem;
    padding-left: 5px
}

/* #searchbox-container-overlay.open {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(84, 84, 84, .7);
    z-index: 9991
} */

.ais-page .ais-filter-sort-block {
    background-color: #e0e0e0;
    padding: 10px 10px 4px 10px;
    font-family: "TT Norms Regular", "Helvetica Neue", Arial, sans-serif;
}

.ais-page .ais-filter-sort-block .ais-Stats-text {
    font-size: .875rem;
    text-transform: lowercase;
    font-family: "TT Norms Medium", "Helvetica Neue", Arial, sans-serif
}

.ais-page .ais-filter-sort-block .ais-filter-sort-cta {
    position: relative;
    /* min-width: 120px; */
    text-align: right;
    text-transform: uppercase;
    color: #444;
    /* letter-spacing: .02187rem; */
    font-size: .875rem;
    display: flex;
    cursor: pointer;
}

.ais-page .ais-filter-sort-block .ais-filter-sort-cta span.icon {
    color: rgb(52, 52, 52);
    font-size: 12px;
    margin-left: 5px
}

.ais-page .ais-filter-sort-block .ais-filter-sort-cta span:hover {
    color: rgb(255, 103, 76, 1);
}

.modal-filter-sort .ais-page {
    display: block;
    padding: .625rem .5rem;
    height: 100%
}

.modal-filter-sort .ais-label {
    display: block;
    margin-bottom: 10px;
    font-family: "TT Norms Medium", "Helvetica Neue", Arial, sans-serif;
    font-size: 24px;
    letter-spacing: 1px;
    text-transform: uppercase
}

.modal-filter-sort .ais-label--filter {
    border-bottom: 3px solid #d9d9d6;
    padding: 0 8px 15px;
    margin-bottom: 0
}

.modal-filter-sort .ais-sort {
    margin-bottom: 35px;
    padding: 0 8px
}

.modal-filter-sort .ais-sort .ais-sort-orders-container {
    position: relative;
    border: 1px solid #747679;
    border-radius: 4px
}

.modal-filter-sort .ais-sort .ais-sort-orders-container:after {
    top: 21px;
    right: 10px;
    font-size: 16px;
    font-weight: 700;
    -webkit-font-smoothing: antialiased
}

.modal-filter-sort .ais-sort .ais-sort-cta {
    display: block;
    font-size: 16px;
    color: #747679;
    text-transform: uppercase;
    padding: 14px 10px;
    cursor: pointer;
    transition: none;
    font-weight: 500
}

.modal-filter-sort .ais-facets {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 230px;
    max-width: 100%;
}

.modal-filter-sort .ais-facets .ais-facet {
    margin: 0;
    padding: 5px 8px;
    color: #444;
    border-bottom: 3px solid #d9d9d6
}

.modal-filter-sort .ais-facets .ais-facet--header {
    position: relative;
    cursor: pointer;
    margin-right: 25px;
    padding: 17px 0;
    font-size: 20px;
    font-weight: 400
}

.modal-filter-sort .ais-facets .ais-facet--header:after {
    content: '+';
    font-family: glyphs_15;
    font-style: bold;
    font-variant: normal;
    font-weight: 400;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    right: -19px;
    bottom: 11px;
    font-size: 36px;
    color: #444
}

.modal-filter-sort .ais-facets .ais-facet--header.open:after {
    content: '-'
}

.modal-filter-sort .ais-facets .ais-facet--item {
    padding: 9px 0 9px 0;
    /* padding: 9px 50px 9px 0; */
}

.modal-filter-sort .ais-facets .ais-facet--item>div {
    display: flex;
    align-items: center;
}

.modal-filter-sort .ais-facets .ais-facet--checkbox {
    margin: 0 10px 0 0;
    display: block;
}

.modal-filter-sort .ais-facets .ais-facet--label {
    line-height: 1
}

.modal-filter-sort .ais-facets .ais-facet--label span {
    font-size: 22px
}

.modal-filter-sort .ais-refinement-list__wrapper {
    display: block
}

.modal-filter-sort .ais-refinement-list__wrapper.collapsed {
    /* display: none */
}

.modal-filter-sort .ais-refinement-list__wrapper .checkbox__element, .modal-filter-sort .ais-refinement-list__wrapper .checkbox__label, .modal-filter-sort .ais-refinement-list__wrapper .checkbox__label::after {
    transition: none
}

.modal-filter-sort .ais-refinement-list--list {
    margin-bottom: 9px
}

.modal-filter-sort .modal-close {
    display: block;
    cursor: pointer;
    width: 50px;
    margin: 10px 0 0 auto;
    text-align: right
}

.modal-filter-sort .modal-close .icon {
    font-size: 20px;
    color: #444
}

.ais-SortBy-select {
    height: 2.5rem;
    width: 100%;
    margin: 0;
    padding: .5rem 1rem;
    border: thin solid #d9d9d6;
    border-radius: 0;
    color: #444;
    cursor: center;
    min-width: 200px;
    /* min-width: 280px; */
    background-color: #fff;
    text-align-last: center;
    -webkit-appearance: none;
    line-height: 1.15
}

.ais-page {
    position: relative
}

.ais-page .aa-highlight {
    color: #000;
    font-weight: 700
}

.ais-page .ais-facets .ais-facet:nth-child(n+21) {
    display: none
}

.ais-page .ais-facet {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 16px;
    color: #444
}

.ais-page .ais-facet--header {
    /* padding: 20px 0; */
    padding: 10px 0;
    color: #444;
    font-size: .95em;
    font-weight: 700;
    text-transform: uppercase
}

.ais-page .ais-facet--body {
    padding: 8px 0
}

.ais-page .ais-facet--item {
    position: relative;
    width: 100%;
    padding: 6px 50px 6px 0;
    cursor: pointer
}

.ais-page .ais-facet--item>div {
    display: flex;
    vertical-align: top
}

.ais-page .ais-facet--label {
    display: inline-block;
    width: calc(100% - 30px);
    color: #444;
    font-weight: 300;
    cursor: pointer;
    margin-top: -.25em;
    margin-bottom: -.15em;
    line-height: 1.8;
    vertical-align: middle;
    margin-left: 1px;
}

.ais-page .ais-facet--active {
    font-weight: 700
}

.ais-page .ais-facet--active input {
    border: 1px solid #747679
}

.ais-page .ais-current-refined-values {
    position: relative
}

.ais-page .ais-current-refined-values--item {
    border-radius: 7px;
    color: #fff;
    background-color: #008497
}

.ais-page .ais-current-refined-values--header {
    padding-right: 72px
}

.ais-page .ais-current-refined-values--clear-all {
    color: rgb(255, 103, 76);
    float: right;
    cursor: pointer;
}

.ais-page .ais-current-refined-values--link {
    display: table;
    width: 100%;
    margin: 4px 0;
    cursor: pointer;
    text-decoration: none
}

.ais-page .ais-current-refined-values--link::after {
    content: '\2a2f';
    width: 0;
    font-size: 1.5rem;
    padding-right: 6px
}

.ais-page .ais-current-refined-values--link>div {
    display: table-cell;
    width: 100%;
    max-width: 1px;
    padding: 6px 10px;
    font-size: .9em;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.3
}

.ais-page .ais-current-refined-values--label {
    text-transform: uppercase;
    font-size: inherit
}

.ais-page .ais-input {
    width: 100%;
    background: #f3f3f2
}

.ais-page .ais-input input {
    background-color: #f3f3f2;
    font-size: 1.75rem;
    padding-bottom: .625rem;
    padding-left: 0
}

.ais-page .ais-input--label {
    display: table-cell;
    width: 0;
    padding: 8px;
    background: #444;
    color: #fff;
    vertical-align: middle;
    padding-right: 24px;
    white-space: nowrap
}

.ais-page .ais-search-box--input {
    width: 100%;
    padding: 8px 16px 8px 4px;
    border: none
}

.ais-page .ais-search-box--input::-ms-clear {
    display: none;
    height: 0;
    width: 0
}

.ais-page .ais-search-box--input, .ais-page .ais-search-box--input:focus {
    outline: 0;
    box-shadow: none
}

.ais-page .ais-input-button {
    display: table-cell;
    vertical-align: middle;
    width: 0;
    padding: 4px 8px
}

.ais-page .ais-change-display {
    display: none;
    margin: 0 1.25rem;
    vertical-align: middle
}

.ais-page .ais-change-display span {
    font-size: 1.8em;
    margin-right: 5px
}

.ais-page .ais-change-display input {
    display: none
}

.ais-page .ais-change-display label {
    cursor: inherit
}

.ais-page .ais-change-display-block, .ais-page .ais-change-display-list {
    display: inline-block;
    margin: 0 .3125rem;
    font-size: 1.75rem;
    cursor: pointer;
    fill: #d9d9d6;
    line-height: 1.6
}

.ais-page .ais-change-display-block:hover, .ais-page .ais-change-display-list:hover {
    fill: #444
}

.ais-page .ais-change-display input[type=radio]:checked~label {
    fill: #444
}

.ais-page .ais-hits::after {
    content: '';
    clear: both;
    display: block
}

.ais-page .ais-hit--title {
    color: #444;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 15px
}

.ais-page .ais-hit--subtitle {
    color: #d9d9d6;
    font-size: .9em
}

.ais-page .ais-hit--subtitle * {
    font-size: inherit
}

.ais-page .ais-hit--price {
    color: #444
}

.ais-page .ais-hit--price-striked {
    text-decoration: line-through;
    color: #d9d9d6
}

.ais-page .ais-hit--price-striked span {
    color: #444
}

.ais-page .ais-hit--price-discount {
    color: #d9d9d6
}

.ais-page .ais-hit--pack-size {
    color: #d9d9d6;
    font-size: .9em
}

.ais-page .ais-hit--cart {
    text-align: center
}

.ais-page .ais-highlight {
    position: relative;
    font-size: 1em;
    font-weight: 700;
    z-index: 1
}

.ais-page .ais-highlight::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1
}

.ais-results-size-xs .ais-page .ais-input--label {
    display: none
}

.ais-results-size-xs .ais-page .ais-sort {
    float: none
}

.ais-page .ais-results-as-list {
    border-top: 1px solid #d9d9d6
}

.ais-page .ais-results-as-list .ais-hit {
    width: 100%;
    padding: 30px 25px;
    min-height: 170px
}

.ais-page .ais-Pagination-item {
    display: inline-block;
    margin: 0 2px;
    text-align: center
}

.ais-page .ais-pagination--active {
    border-color: #444
}

.ais-Pagination-list .ais-Pagination-item {
    height: 1.25rem;
    width: 1.25rem
}

.ais-Pagination-list .ais-Pagination-item:hover {
    border: 1px solid #198294;
    background-color: #eff6f7
}

.ais-Pagination-list .ais-Pagination-item:hover:last-child {
    border-radius: 0 4px 4px 0
}

.ais-Pagination-list .ais-Pagination-item:hover:first-child {
    border-radius: 4px 0 0 4px
}

.ais-root__collapsible {
    position: relative
}

.ais-root__collapsible::after {
    content: "\E906";
    position: absolute;
    top: 1.25rem;
    right: .875rem;
    color: #747679;
    font-family: glyphs_15;
    font-size: .875rem;
    pointer-events: none;
    transform: rotate(180deg)
}

.ais-root__collapsible.ais-root__collapsed::after {
    transform: rotate(0)
}

.ais-refinement-list--list {
    max-height: 26rem;
    overflow-y: auto
}

.ais-refinement-list--range {
    min-height: 70px
}

.ais-refinement-list--range>span>span {
    height: 8px
}

.ais-refinement-list--range>span>span[class*=rail] {
    background-color: #d9d9d6;
    border-radius: 5px
}

.ais-refinement-list--range [aria-labelledby=range-slider], .ais-refinement-list--range [class*=thumb] {
    top: 24%;
    height: 24px;
    width: 24px;
    border: 2px solid #037f96;
    background: #fff;
    margin-left: -6px
}

@media screen and (min-size:990px) {
    .container-boutique {
        position: relative
    }
}

.container-boutique {
    max-width: 100%!important
}

.container-boutique.wrapper {
    padding: 0
}

.desktop-only {
    display: none;
}

.ais-refinement-list--range [class*=markLabel] {
    margin-top: 10px
}

.boutique-page-no-result {
    margin: auto;
}

@media (min-width:576px) {
    .container-boutique {
        max-width: 540px
    }
    .products-grid .ais-search-header {
        flex-direction: row;
        align-items: flex-end
    }
    .products-grid .ais-pagination-container {
        align-self: flex-end
    }
    .products-grid--list .product-thumbnail {
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 1.5rem;
        border-bottom: .0625rem solid #d9d9d6;
        margin: 0!important
    }
    .products-grid--list .product-thumbnail__header {
        display: flex;
        flex-direction: row;
        flex: 7
    }
    .product-thumbnail__image {
        min-height: 130px
    }
    .products-grid--list .product-thumbnail__image-wrap {
        display: flex;
        align-items: center;
        padding-right: .9375rem;
        margin-bottom: 0;
        border-right: .0625rem solid #d9d9d6
    }
    .products-grid--list .product-thumbnail__information {
        margin-left: .9375rem
    }
    .products-grid--list .product-thumbnail__icon {
        font-size: 1rem
    }
    .products-grid--list .product-thumbnail__title {
        font-size: 1.25rem;
    }
    .products-grid--list .product-thumbnail__features {
        display: inherit!important
    }
    .products-grid--list .product-thumbnail__footer {
        justify-content: flex-start;
        margin-left: .9375rem;
        margin-top: 1.25rem
    }
    .products-grid--list .product-thumbnail__data-wrap {
        display: block
    }
    .products-grid--list .product-thumbnail__data {
        display: flex
    }
    .products-grid--list .product-thumbnail__offer {
        display: none!important;
        flex-direction: column;
        justify-content: center;
        flex-basis: 50%;
        padding-left: .9375rem;
        border-left: .0625rem solid #d9d9d6;
        color: #747679
    }
    .products-grid--list .product-thumbnail__form {
        display: flex
    }
    .products-grid--list .product-thumbnail__form .select {
        display: inherit!important
    }
    .products-grid--list .product-thumbnail__cta {
        width: 10.375rem
    }
    .products-grid--list .product-thumbnail__packsize {
        display: block
    }
}

@media (min-width:768px) {
    .container-boutique {
        max-width: 720px
    }
    .ais-page .ais-change-display {
        display: inline-block!important
    }
    .products-grid--list>.row>[class*=col] {
        flex: 0 0 100%;
        max-width: 100%
    }
    .products-grid .ais-input {
        display: block;
        padding: .625rem 1.25rem 1.25rem
    }
    .ais-refine-search-label {
        display: none
    }
    .ais-facets {
        display: block;
        width: 25%
    }
    .ais-sort {
        width: auto
    }
    .ais-sort-label {
        display: block
    }
    .ais-sort-orders-container::after {
        top: 3.125rem
    }
    .products-grid .ais-sort-by-selector {
        margin-top: .5rem
    }
    .ais-hits {
        border-top: .0625rem solid #d9d9d6
    }
    .ais-block {
        width: 75%;
        border-left: .0625rem solid #d9d9d6
    }
    .product-details__container {
        margin-top: 1.25rem
    }
    .products-grid .product-thumbnail {
        border-right: .0625rem solid #d9d9d6
    }
    .products-grid--list .product-thumbnail {
        display: block
    }
    .products-grid--list .product-thumbnail__footer {
        align-items: flex-end
    }
    .products-grid--list .product-thumbnail__data-wrap {
        display: block
    }
    .products-grid--list .product-thumbnail__price {
        align-items: center;
        flex-basis: 50%;
        padding-right: .9375rem
    }
    .products-grid--list .product-thumbnail__price .top-product {
        align-items: flex-end
    }
    .products-grid--list .product-thumbnail__price .money {
        align-items: flex-end
    }
    .products-grid--list .product-thumbnail__offer-type {
        min-width: 2.8125rem
    }
    .products-grid--list .product-thumbnail__offer-value {
        text-align: right
    }
    .products-grid--list .product-thumbnail__amount-data {
        margin-left: 0
    }
    .products-grid--list .product-thumbnail__packsize {
        display: block
    }
    .ais-page .ais-filter-sort-block {
        display: none
    }
    .ais-SortBy-select {
        font-size: 1rem;
        margin-top: .5rem;
        text-align-last: left
    }
    .ais-page .ais-change-display {
        display: inline-block!important
    }
    .desktop-only {
        display: flex;
    }
    .ais-refinement-list--range [aria-labelledby=range-slider], .ais-refinement-list--range [class*=thumb] {
        top: 20%
    }
}

@media (min-width:992px) {
    .container-boutique {
        max-width: 960px
    }
    .select--inline .select__element {
        max-width: 6.5625rem
    }
    .select--inline {
        display: flex;
        align-items: center
    }
    .products-grid--list .product-thumbnail {
        display: flex
    }
    .products-grid--list .product-thumbnail__header {
        flex: 8
    }
    .products-grid--list .product-thumbnail__image {
        width: 7.5rem
    }
    .products-grid--list .product-thumbnail__information-container {
        flex-direction: row
    }
    .products-grid--list .product-thumbnail__information {
        width: 15.625rem
    }
    .products-grid--list .product-thumbnail__footer {
        margin-left: 0;
        margin-top: 0;
        align-items: end
    }
    .products-grid--list .product-thumbnail__data-wrap {
        display: flex
    }
    .products-grid--list .product-thumbnail__price .top-product {
        align-items: end
    }
    .products-grid--list .product-thumbnail__price .money {
        align-items: end
    }
    .products-grid--list .product-thumbnail__form {
        justify-content: flex-end
    }
    .products-grid--list .product-thumbnail__packsize {
        display: flex
    }
}

@media (min-width:1200px) {
    .container-boutique {
        max-width: 1140px
    }
    .products-grid .ais-search-header {
        justify-content: flex-start;
        padding-bottom: 1.25rem
    }
    .products-grid--list .product-thumbnail__image {
        width: 13.75rem
    }
    .products-grid--list .product-thumbnail__title {
        font-size: 1.5rem;
    }
    .container-boutique {
        max-width: 1440px!important
    }
}

@media screen and (min-width:993px) {
    .ais-page .ais-results-as-block>div>div:nth-of-type(3n+1) .ais-hit {
        margin-left: 0
    }
}

@media only screen and (max-width:1024px) {
    .ais-page .ais-facet--label>span {
        font-size: .875rem
    }
    .ais-page .ais-facet--item {
        padding: 9px 9px 9px 0
    }
}

@media only screen and (max-width:934px) {
    .ais-page .ais-facet--label>span {
        font-size: .8rem
    }
}

.ais-hit {
    position: relative
}

@media (max-width:992px) {
    .mobile-first {
        order: -1
    }
}

@media only screen and (max-width: 400px) {
    .hide-text-mobile {
        display: none;
    }
}