/* CSS de base */
.evenement-card-container {
    width: 100% !important;
    margin: none !important;
    background-color: #F2F2F2;
}
.evenement-card-container .card-sub-container {
    padding-bottom: 5px !important;
}
.content-evenement {
    margin-bottom: 20px;
    display: flex !important;
    justify-content: space-between;
    border-top: 1px solid rgb(255, 103, 76, 1);
}

.image-evenement {
    margin-top: 0px !important;
}

.body-evenement {
    width: 80%;
    padding-top: 20px;
}

.sub-title-evenement {
    text-align: left;
    font-size: 2.4em;
    font-family: 'Barlow-Bold';
    text-transform: uppercase;
    color: rgb(255, 103, 76, 1);
}

.details-prix {
    display: flex !important;
    justify-content: flex-start;
}

.details-prix span {
    display: block;
}

.details-prix div {
    margin: 5px 10px 10px -2px;
    padding: 0 0 0 10px;
    color: #555;
    min-width: 65px;
    border-left: 1px dotted #CCC;
}

.btn-achat-evenement {
    background-color: rgb(255, 248, 247);
    color: rgb(255, 103, 76, 1);
    font-family: Barlow-Black;
    border: 4px solid rgb(255, 103, 76, 1);
    padding: 4px 12px 4px 12px;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-top: 13px;
    cursor: pointer;
}

.footer-evenement {
    text-align: left;
    margin-top: 45px;
}

.stepper-evenement {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepper-evenement-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 10px;
    cursor: pointer;
}

.circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    font-size: 14px;
}

.active .circle {
    background-color: rgb(0, 152, 179);
    color: #fff;
}

.visited .circle {
    background-color: rgb(255, 103, 76);
    color: #fff;
}

.label {
    font-size: 14px;
    text-align: center;
}

.stepper-evenement-content {
    margin-bottom: 20px;
    width: 100%;
}

.stepper-evenement-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.stepper-evenement-buttons button {
    margin: 0px !important;
}

button {
    padding: 10px 20px;
    background-color: rgb(255, 103, 76);
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* CSS Step 2 */
.container-details-evenement {
    background: #F2F2F2;
}

.content-info-date-evenement div {
    margin: 8px 0px;
}

.content-body-step-2 {
    width: 100%;
}

.content-details-evenement {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    justify-content: space-between;
}

.content-details-left {
    width: 100%;
}

.content-details-left img {
    width: 100%;
}

.billet-column img {
    width: 50%;
}

.body-details-evenement {
    width: 100%;
    overflow: hidden;
}

.container-button-details-evenement {
    text-align: center;
}

.title-body-details-evenement {
    text-align: center;
}

.container-button-details-evenement button {
    margin: 26px !important;
}

.body-content-details-evenement {
    text-align: justify;
    /* padding: 0px 12px; */
    overflow-wrap: break-word;
}

.content-message-no-evenement {
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
}
.content-message-no-evenement span{
    display: table-cell;
    vertical-align: middle;
    font-family: 'Barlow-Bold' !important;
}

/* Responsive design for larger screens */
@media (min-width: 768px) {
    .content-details-evenement {
        flex-direction: row;
    }

    .content-details-left,
    .calendar-custom-wrapper {
        flex: 0.8;
        min-width: 0;
        margin: 10px;
        position: relative;
        top: 0px;
    }

    .body-details-evenement {
        flex: 2;
        min-width: 0;
        margin: 10px;
    }

    /* .calendar-custom-wrapper {
        flex: 1.5;
        min-width: 0;
        margin: 10px;
    } */
}

@media screen and (max-width: 768px) {
    .content-evenement {
        flex-direction: column !important;
    }

    .stepper-evenement,
    .stepper-evenement-buttons {
        padding-left: 6px;
        padding-right: 6px;
    }

    .image-evenement {
        padding: 6px;
    }

    .image-evenement img {
        width: 100% !important;
        height: auto !important;
    }

    .body-evenement {
        width: 100%;
    }

    .body-evenement p {
        text-align: justify !important;
    }

    .content-button-footer-evenement {
        text-align: center;
    }
}