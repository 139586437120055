.close {
    background-color: rgb(238, 226, 223);
}

.open {
    background-color: rgb(255, 249, 248);
}

.ul-container {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    overflow-y: auto;
    list-style: none;
    -webkit-overflow-scrolling: touch;
    overflow: visible;
    /* margin: "-10px"; */
}

.restaurant-tab-container {
    background-color: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    position: relative;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin: 16px 0;
    border-radius: 0;
    margin: 0;
    transition: all 0.4s linear;
    box-shadow: none;
    background-color: #F1F1F1;
}

.restaurant-tab-container:first-child {
    margin-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.restaurant-tab-container:last-child {
    margin-bottom: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.restaurant-tab-container .tab-icon {
    position: absolute;
    top: 18px;
    right: 0;
}

.closeColor {
    color: black;
}

.openColor {
    color: rgb(255, 103, 76, 1);
}

.background-color {
    background-color: #f5ece9;
}

.tab-separator {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
}

.jss467 {
    height: 0;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    height: auto;
    overflow: visible;
}

.div-containers {
    display: flex;
    width: 100%;
    display: flex;
    padding: 8px 24px 24px;
    padding-top: 0;
}

.jss450 {
    height: 60px;
    display: flex;
    padding: 0 24px 0 24px;
    min-height: 48px;
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.jss454 {
    margin: 12px 0;
    display: flex;
    flex-grow: 1;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.jss454> :last-child {
    padding-right: 32px;
}

.jss456 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-title {
    font-family: Barlow-Black;
    top: 106px;
}

@media (max-width:959.95px) {
    .tab-title {
        font-size: 17px;
        font-style: normal;
        font-weight: bold;
    }
}

@media (min-width:600px) {
    .tab-title {
        font-size: 24px;
        font-style: normal;
        font-weight: bold;
    }
}

.tab-right-text {
    margin: 0px 5px;
    display: flex;
}

.jss414 {
    flex: 1;
    display: flex;
    vertical-align: middle;
    justify-content: center;
}

.jss416 {
    color: #6a6c6e;
    margin-bottom: 10px;
}

@media (max-width:959.95px) {
    .jss416 {
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
    }
}

@media (min-width:600px) {
    .jss416 {
        font-size: 17px;
        font-style: normal;
        font-weight: normal;
    }
}

@media (max-width:600px) {
    .div-containers {
        padding: 0;
    }
}