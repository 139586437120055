#profile-page, #reservation-camps-page {
    width: 100%;
    padding-top: 32px;
    max-width: calc(100% - (100% - 1550px) / 2 - 16px);
    /* 480%: la section des règlements; (100% - 1550px) / 2: la largeur de la bande du menu principale excluant la marge de gauche; 16px: la marge à gauche des icônes de calendrier; */
    /* padding-left: 62px;
    margin-left: auto;
    margin-right: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media screen and (max-width: 720px) {
    #profile-page, #reservation-camps-page {
        padding-left: 22px;
    }
  }
  
  #profile-page .profile-div {
    /* margin-bottom: 29px; */
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0px;
  }
  
  @media screen and (max-width: 780px) {
    #profile-page .profile-div {
        width: 100%;
    }
  }
  
  #profile-page .profile-label-wrapper {
    width: 320px;
    float: left;
  }
  
  #profile-page .profile-span, #reservation-camps-page .profile-span {
    font-family: Barlow-Bold;
    font-size: 26px;
    width: 480px;
  }
  
  #profile-page .input-container, #reservation-camps-page .input-container {
    margin-bottom: 20px;
    /* max-width: 350px; */
    width: calc(50%);
    /* width: calc(100% - 342px - 40px); */
    float: left;
  }
  
  @media screen and (max-width: 780px) {
    #profile-page .input-container, #reservation-camps-page .input-container {
        width: calc(100% - 19px);
    }
  }
  
  @media screen and (max-width: 300px) {
    #profile-page {
        padding-left: 6px;
        width: 96%;
    }
  
    #profile-page .input-container {
        width: calc(100%);
    }
  }
  
  #profile-page .profile-input, #reservation-camps-page .profile-input {
    width: 100%;
  }
  
  #profile-page .profile-img {
    height: 40px;
    margin-left: 16px;
    margin-top: 12px;
    float: left;
  }
  
  #profile-page .profile-img:hover {
    cursor: pointer;
  }
  
  #profile-page .message {
    font-family: Barlow-SemiBold;
    width: 100%;
    display: inline-block;
  }
  
  .profile-separator {
    border-bottom: 2px solid black;
    width: calc(100% - 524px + 480px + 40px);
    /* 100% - 640px: width du input; 480px: width du span; 40px: width du calendrier; */
    clear: both;
  }
  
  .profile-label-wrapper-title {
    width: 100%;
  }
  
  #profile-page .profile-label-wrapper-title .profile-span, #reservation-camps-page .profile-span {
    font-family: Barlow-Black;
  }
  
  /*#profile-page .tirage-formulaire-choix-secteur {
    float: left;
    width: 25%;
  }
  
  @media screen and (max-width: 1480px) {
    #profile-page .tirage-formulaire-choix-secteur {
        width: 50%;
    }
  }
  
  @media screen and (max-width: 1050px) {
    #profile-page .tirage-formulaire-choix-secteur {
        width: 100%;
    }
  }*/
  
  #profile-save-wrapper {
    background-color: rgb(255, 103, 76, 1);
    clear: both;
    text-align: right;
    margin-top: 32px;
    margin-bottom: 32px;
    cursor: pointer;
  }
  
  @media screen and (max-width: 1210px) {
    #profile-save-wrapper {
        text-align: center;
    }
  }
  
  .btn-login {
    background-color: rgb(255, 103, 76, 1);
    clear: both;
    text-align: center;
    margin: auto;
    max-width: 480px;
  }
  
  .btn-login span {
    color: white;
  }
  
  #profile-page #profile-save-wrapper .profile-span {
    color: white;
    font-family: Barlow-Black;
    padding-right: 8px;
  }