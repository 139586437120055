#paiement-page {
    width: 100%;
    padding: 32px;
    max-width: calc(100% - (100% - 1550px) / 2 - 16px); /* 480%: la section des règlements; (100% - 1550px) / 2: la largeur de la bande du menu principale excluant la marge de gauche; 16px: la marge à gauche des icônes de calendrier; */
    padding-left: 52px;
    margin: auto;
}

@media screen and (max-width: 1550px) {
    #paiement-page {
        padding-left: 32px;
        max-width: 100%;
    }
}

@media screen and (max-width: 940px) {
    #paiement-page {
        max-width: initial;
    }
}

@media screen and (max-width: 720px) {
    #paiement-page {
        padding-left: 32px;
        margin-left: initial;
        float: left;
    }
}

.paiement-form label {
    font-family: Barlow-Bold;
    font-size: 26px;
    width: 540px;
    margin-top: 0px;
    max-width: 100%;
}

@media screen and (max-width: 720px) {
    .paiement-form label {
        margin-top: 0px;
    }
}

.paiement-form .info-client-field-error {
    display: block;
    color: brown;
    font-size: 18px;
    text-align: left;
}
.paiement-form .info-client-field-mandatory {
    color: brown;
    font-size: 26px;
}

.mandatory-input-missing {
    border-color: red !important;
    border-style: solid;
    border-width: 3px
}

.paiement-form input[type=number].info-client-left-demi-field-input {
    margin-right: 10px;
    padding-right: 0px;
    width: calc(50% - 300px);
}

.paiement-form input[type=number].info-client-right-demi-field-input {
    padding-right: 0px;
    width: calc(50% - 300px);
}

@media screen and (max-width: 780px) {
    .paiement-form input[type=number].info-client-left-demi-field-input {
        width: calc(50% - 16px);
    }

    .paiement-form input[type=number].info-client-right-demi-field-input {
        width: calc(50% - 16px);
    }
}

.paiement-form .montant-row {
    padding-bottom: 16px;
    padding-top: 12px;
    text-align: left
}

.paiement-form .montant-row span {
    padding-top: 6px;
    display:compact;
    text-align: left
}

.paiement-form div {
    padding: 8px;
    padding-left: 0px;
}

@media screen and (max-width: 780px) {
    .paiement-form div {
        width: 100%;
    }
}

@media screen and (max-width: 780px) {
    .paiement-form label {
        padding-left: 0px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.paiement-form .message {
    font-family: Barlow-SemiBold;
}

.profile-label-wrapper {
    width: 300px;
    float: left;
}

#paiement-page span {
    font-family: Barlow-Bold;
    font-size: 26px;
    width: 480px;
}

.paiement-form span {
    font-family: Barlow-Bold;
    font-size: 26px;
    width: 480px;
}

.paiement-form input[type=text], .paiement-form input[type=number], .paiement-form input[type=email], .paiement-form select {
    width: calc(100% - 500px - 40px);
}

@media screen and (max-width: 780px) {
    .paiement-form input[type=text], .paiement-form input[type=number], .paiement-form input[type=email], .paiement-form select {
        width: calc(100% - 0px); /* Remplacer -0px par -64px s'il y a ajout d'icône à un input dans la page (e.g. icône de calendrier) */
    }
    
    .paiement-form input[type=number].info-client-left-demi-field-input {
        margin-left: 0px;
        margin-right: 15px;
        position: center;
        width: calc(45% - 0px);
    }
    
    .paiement-form input[type=number].info-client-right-demi-field-input {
        margin-top: 10px; 
        margin-left: 0px;
        position:center;
        width: calc(45% - 0px);
    }
}

.paiement-form img {
    height: 40px;
    margin-left: 16px;
    vertical-align: top;
}

.paiement-form img:hover {
    cursor: pointer;
}

.paiement-page-separator {
    border-bottom: 2px solid black;
    width: calc(100% - 540px + 480px + 40px); /* 100% - 540px: width du input; 480px: width du span; 40px: width du calendrier; */
    clear: both;
    margin-left: 8px;
}

.paiement-page-label-wrapper-title {
    width: 100%;
}

#profile-page .paiement-page-label-wrapper-title span {
    font-family: Barlow-Black;
}

/*#profile-page .tirage-formulaire-choix-secteur {
    float: left;
    width: 25%;
}

@media screen and (max-width: 1480px) {
    #profile-page .tirage-formulaire-choix-secteur {
        width: 50%;
    }
}

@media screen and (max-width: 1050px) {
    #profile-page .tirage-formulaire-choix-secteur {
        width: 100%;
    }
}*/

#paiement-form {
    margin: auto;
    max-width: 100%;
    width: 1000px
}

#paiement-page .paiement-submit-button {
    background-color: rgb(255, 103, 76, 1);
    clear: both;
    cursor: pointer;
    margin-left: 55%;
    margin-right: 2%;
    margin-top: 32px;
    padding: 8px;
    text-align: center;
}

@media screen and (max-width: 1065px) {
    #paiement-page .paiement-submit-button {
        text-align: center;
        margin-left: 0%;
        margin-right: 0%;
        width: 95%;
    }
}

#paiement-page .paiement-submit-button span {
    color: white;
    font-family: Barlow-Black;
    padding-right: 8px;
}

#paiement-page .paiement-submit-button:hover {
    background-color: rgb(255, 103, 76, 1);
    cursor: pointer;
}


.paiement-form {
    position: relative
}

/*#paiement-success {
    text-align: center;
    margin: auto;
}*/



/*.paiement-form-title {
    text-align: center
}*/

/*.paiement-success-titre {
    text-align: center;
    margin-left: 0%
}*/