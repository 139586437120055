#choix-unite-carte {
    margin-top: 32px;
    overflow: hidden;
    position: relative;
}

#choix-unite-carte:hover {
    cursor: grab;
}

#choix-unite-carte img {
    width: 100%;
    color: white;
}


#choix-unite-carte g[id^="U_"]:not(.disponible,.indisponible,.partiellement-disponible,.selected ) > *:not([id^="T_"],.disponible,.indisponible,.partiellement-disponible,.selected), g[id^="U_"]:not(.disponible,.indisponible,.partiellement-disponible,.selected ) > g:not([id^="T_"]) > * {
    stroke-width: inherit;
    z-index: 1;
    fill: #1e1e1e !important;
}

#choix-unite-carte g[id^="U_"]:not(.disponible,.indisponible,.partiellement-disponible,.selected ) > g[id^="T_"] > *, #choix-unite-carte g[id^="U_"]:not(.disponible,.indisponible,.partiellement-disponible,.selected ) g > g[id^="T_"] > *:not(.disponible,.indisponible,.partiellement-disponible,.selected ), #choix-unite-carte g[id^="U_"]:not(.disponible,.indisponible,.partiellement-disponible,.selected ) > g[id^="T_"] > g > * {
    stroke-width: initial;
    stroke: initial;
    fill: white !important;
}

#choix-unite-carte g[id^="T_"] .disponible:not(.selected) {
    stroke: black !important;
    fill: black !important;
}

.legende {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.legende .disponible {
    background-color: rgba(0, 255, 52, 1);
    height: 2rem;
    width: 2rem;
    border: 1px solid black;
}

.legende .partiellement-disponible {
    background-color: rgba(255, 255, 52, 1);
    height: 2rem;
    width: 2rem;
    border: 1px solid black;
}

.legende .indisponible {
    background-color: rgba(255, 0, 0, 1);
    height: 2rem;
    width: 2rem;
    border: 1px solid black;
}
.legende .reserve {
    background-color: black;
    height: 2rem;
    width: 2rem;
    border: 1px solid black;
}

#choix-unite-carte g[id*="U_"] .disponible:not(.selected) {
    stroke-width: inherit;
    z-index: 1;
    fill: rgba(0, 255, 52, 1) !important;
}

#choix-unite-carte g[id*="U_"] .disponible:not(.selected):hover {
    cursor: pointer;
    font-weight: bold;
    fill: rgb(0, 218, 44) !important;
}

#choix-unite-carte g[id*="U_"] .partiellement-disponible:not(.selected) {
    stroke-width: 0.5px;
    z-index: 1;
    fill: rgba(255, 255, 52, 1) !important;
}

#choix-unite-carte g[id*="U_"] .partiellement-disponible:not(.selected):hover {
    cursor: pointer;
    font-weight: bold;
    fill: rgb(255, 200, 44) !important;
}

#choix-unite-carte g[id*="U_"] .indisponible:not(.selected) {
    stroke-width: 0.5px;
    z-index: 1;
    fill: rgba(255, 0, 0, 1) !important;
}

#choix-unite-carte .selected {
    fill: rgba(26, 26, 255, 1) !important;
}

#choix-unite-carte .selected:hover {
    cursor: pointer;
    font-weight: bold;
    fill: rgb(35, 35, 253) !important;
    stroke: white !important;
}

#choix-unite-carte g[id*="T_"] .selected {
    fill: white !important;
}