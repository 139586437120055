#paiement-comptes-page {
    padding: 32px;
}

.paiement-comptes-page-comptes-dues-table {
    max-width: 950px;
    margin: auto;
    font-size: 1.2em;
    display: grid;
}

.paiement-comptes-page-comptes-dues-table-row {

}

.paiement-comptes-page-comptes-dues-table-row-small-screen {
    border-bottom: 2px solid rgb(153, 153, 153);
    padding-top: 8px;
    padding-bottom: 8px;
}

.paiement-comptes-page-comptes-dues-table-cell {
    float: left;
    width: 25%;
}

.paiement-comptes-page-montant-row {
    padding-top: 8px;
}

.paiement-comptes-page-montant {
    font-family: Barlow-Bold;
}

@media screen and (max-width: 1023px) {
    .min-width-1024 {
        display: none;
    }
    .paiement-comptes-page-input {
        width: 100%;
    }
}

@media screen and (min-width: 1024px) {
    .max-width-1023 {
        display: none;
    }
}

.paiement-comptes-page-comptes-dues-table-cell-small-screen {

}