.sommaire-div {
    flex: 1;
    max-width: 550px;
    margin: 20px;
}

.sticky {
    position: sticky;
    top: 20px;
}

.sommaire-flex {
    display: flex;
    flex-direction: column;
}

.containerItems {
    padding: 16px;
    padding-bottom: 10px;
    flex-direction: column;
    background-color: #fdfdfd
}

.sommaire-taxes-div {
    padding: 20px 0;
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
    flex-direction: column;
}

.sommaire-flex-row {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row
}

.font-category {
    font-size: 17px;
    font-style: normal;
    font-weight: bold
}

.font-price {
    font-size: 17px;
    font-style: normal;
    font-weight: bold
}

.jss826 {
    float: right;
    /* width: 20px; */
    margin: 5px 0 0 5px;
    height: 30px;
}

.facture-top-second {
    left: 0;
    right: 0;
    height: 16px;
    bottom: -8px;
    position: absolute;
    background: radial-gradient(closest-side, transparent, transparent 50%, #fdfdfd 50%);
    background-size: 16px 16px;
    background-repeat: repeat-x;
    background-position: 8px -8px;
}

.empty-commande {
    padding: 20px;
    padding-bottom: 50px;
    flex-direction: column;
    background-color: #fdfdfd
}

.empty-commande-font {
    color: #a5a5a5;
    padding: 0 0 0 0;
    font-size: 17px;
    font-style: normal;
    text-align: center;
    font-weight: normal;
    padding-bottom: 0;
}

.display-text {
    margin: 0;
    display: block;
    text-transform: none;
}

.facture-bottom-first {
    top: 0;
    left: 0;
    right: 0;
    height: 16px;
    z-index: 400;
    position: absolute;
    background: radial-gradient(farthest-side, #fdfdfd, #fdfdfd 50%, transparent 50%);
    background-size: 16px 16px;
    background-repeat: repeat-x;
    background-position: 0 0px;
    flex: 1;
}

.header-sommaire p:nth-child(1) {
    font-size: 17px;
    font-style: normal;
    font-weight: normal;
}

.header-sommaire p:nth-child(2) {
    color: #e41b23;
    font-size: 38px;
    font-style: normal;
    font-weight: bold;
}

.header-sommaire p:nth-child(3) {
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
}

/* .jss557 {
    font-size: 17px;
    font-style: normal;
    font-weight: normal;
} */

.divConfirm {
    position: relative;
    width: 100%;
    bottom: 0px;
    height: 56px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    flex: 1;
    display: flex;
}

.facture-top-first {
    left: 0;
    right: 0;
    bottom: 8px;
    height: 16px;
    z-index: 400;
    position: absolute;
    background: radial-gradient(closest-side, #fdfdfd, #fdfdfd 50%, transparent 50%);
    background-size: 16px 16px;
    background-repeat: repeat-x;
    background-position: 0 8px;
}

.jss567 {
    padding: 20px;
    padding-top: 30px;
    background-color: #efefef;
}

.facture-bottom-second {
    top: -8px;
    left: 0;
    right: 0;
    height: 16px;
    z-index: 400;
    position: absolute;
    background: radial-gradient(farthest-side, transparent, transparent 50%, #fdfdfd 50%);
    background-size: 16px 16px;
    background-repeat: repeat-x;
    background-position: 8px 8px;
}

.jss569 {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.jss572 {
    flex: 1;
    font-size: 17px;
    font-style: normal;
    font-weight: bold;
}

.jss573 {
    color: #e41b23;
    font-size: 17px;
    font-style: normal;
    font-weight: bold;
}

.divConfirm .allowed {
    background-color: #ccecc3;
}

.divConfirm .p-allowed {
    color: white;
}

.divConfirm .not-allowed {
    background-color: #F1F1F1;
    cursor: not-allowed !important;
    pointer-events: auto !important;
}

.payButton {
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    background: none;
    background-color: #F1F1F1;
}

.payButton p {
    flex: 1;
    display: flex;
    font-size: 17px;
    font-style: normal;
    font-weight: normal;
    align-items: center;
    justify-content: center;
    color: black;
}

.sommaire-error-msg {
    display: none;
    text-align: center;
    width: 100%;
    bottom: 0;
    height: 0;
    position: absolute;
    color: red;
    transition: all 0.3s;
}

.payButton:hover+.sommaire-error-msg {
    display: block;
}

.position-relative {
    position: relative;
}

@media (max-width: 1000px) {
    .sommaire-div {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 60px;
    }
}

@media (max-width:959.95px) {
    .sommaire-flex {
        padding-bottom: 55px;
    }
}

.hide {
    display: none;
}

.payButton-trigger:hover+.hide {
    display: block;
    color: red;
}