.boutique-card-item {
    width: auto;
    height: auto;
    background-color: rgb(255, 103, 76, 1);
    text-align: center;
    padding: 17px 1px 13px 1px;
}

.boutique-card-item span {
    font-family: Barlow-Black;
    color: white;
    border: 4px solid white;
    padding: 4px 12px 4px 12px;
}

.boutique-card-item span:hover {
    color: rgb(250, 250, 224);
}

@media (max-width:200px) {
    .boutique-card-item span {
        font-size: 11px;
    }
}

.product-thumbnail__image-wrap img {
    max-width: 100%
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.ais-hits--item .product-link {
    margin-bottom: 1rem
}


.quantity-selector {
    min-width: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    text-align: center
}

.quantity-selector__bottom-margin {
    margin-bottom: 10px
}

.quantity-selector__input-wrapper {
    display: flex;
    justify-content: space-between
}

.quantity-selector__input {
    top: 0;
    left: 0;
    border: 1px solid #a9a9a9;
    width: 100%;
    min-width: 10px
}

.quantity-selector__add-remove-btns {
    padding: 0 5px;
    height: 2.125rem;
    min-width: 30px;
    background-color: #01768b;
    color: #fff;
    font-size: 18pt;
    text-align: center;
    cursor: pointer
}

.quantity-selector__pack-description {
    margin-top: .4375rem;
    min-width: 100px;
    text-align: center;
    font-size: medium;
    color: grey
}

.quantity-selector__item-wrapper {
    min-width: 30px;
    min-height: 30px;
    width: 30%
}

@media only screen and (max-width:767px) {
    .quantity-selector {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        min-width: 100%;
        border: 0
    }
    .quantity-selector__input-wrapper {
        justify-content: space-between;
        align-items: center;
        border: solid 1px #d9d9d6;
        min-width: 50%
    }
    .quantity-selector__input {
        top: 0;
        left: 0;
        border: 1px solid #a9a9a9;
        min-width: 60%
    }
    .quantity-selector__add-remove-btns {
        height: 2.125rem;
        min-width: 20%;
        max-width: 45px;
        background-color: #01768b;
        color: #fff;
        font-size: 20pt;
        text-align: center;
        cursor: pointer
    }
    .quantity-selector__pack-description {
        margin-left: 0;
        margin-right: 0;
        margin-top: 15px;
        text-align: center;
        font-size: medium;
        color: grey;
        min-width: 50%;
        position: static
    }
    .quantity-selector__pack-description:after {
        content: '';
        position: absolute;
        height: 1px;
        width: calc(100% + 24px);
        background: #d3d3d3;
        bottom: 50px;
        left: -13px
    }
    .quantity-selector__item-wrapper {
        width: 45%
    }
    .quantity-selector__item-wrapper:nth-child(3n) {
        width: 100%
    }
}

@media all and (-ms-high-contrast:none), (-ms-high-contrast:active) {
    .quantity-selector__item-wrapper {
        flex-basis: 0;
        flex-grow: 1
    }
}