.left-panel-div {
    /* border: 4px solid tomato; */
    display: none;
    padding: 0 0 0 1.25rem;
    /* padding: .625rem 1.25rem; */
    margin-top: 20px;
    float: left;
    max-width: 25%;
    width: 21%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

@media (min-width:768px) {
    .left-panel-div {
        display: block;
    }
}

.button-filter-left-panel {
    width: 90%;
    height: auto;
    background-color: rgb(255, 103, 76, 1);
    padding: 12px 4px 12px 4px;
}

.button-filter-left-panel span {
    font-family: Barlow-Black;
    color: white;
    border: 3px solid white;
    padding: 4px 8px 4px 8px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.ais-facet--checkbox {
    display: inline-block;
    height: 20px;
    width: 20px;
    color: #fff;
    overflow: hidden;
    padding-left: 0!important;
    margin-right: 10px;
    vertical-align: middle;
    background-color: rgb(255, 221, 215);
}

.ais-facet--checkbox:checked::after {
    background-color: rgb(255, 103, 76, 1);
    display: block;
    position: absolute;
    top: 8px;
    left: 8px;
    height: 12px;
    width: 6px;
    /* border: 1px solid #01768b;
    border-radius: 2px;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg) */
}

.ais-facet--checkbox [type="checkbox"]:checked+label:before {
    content: "✔";
    background: rgb(255, 103, 76, 1);
}

.ais-page .ais-facet--count {
    color: #747679
}

.ais-facet--label:hover {
    color: rgb(255, 103, 76, 1);
}