.div-sommaire-item {
    margin: 6px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.div-sommaire-item:last-child {
    background-color: red;
}

.sommaire-items-font {
    color: #333333;
    font-family: Lato, sans-serif;
    font-weight: 400;
    line-height: 1.5
}

.sommaire-items-name {
    font-size: 14px;
    font-style: normal;
    font-weight: bold
}

.sommaire-items-price {
    color: #e41b23;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    padding-left: 3px;
    padding-right: 5px;
}

.remove {
    cursor: pointer;
    color: rgb(206, 103, 123);
}

.remove:hover {
    color: crimson;
}

.sommaire-options-div {
    margin: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.sommaire-options-font {
    margin: 0;
    display: block;
    text-transform: none;
    color: #333333;
    font-family: Lato, sans-serif;
    font-weight: 400;
    line-height: 1.5
}

.sommaire-options-item {
    overflow: hidden;
    color: #a5a5a5;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    /*white-space: nowrap;*/
    text-overflow: ellipsis;
}

.sommaire-options-flex {
    display: flex;
    align-items: flex-end;
    flex-direction: column
}


.sommaire-options-price-font {
    color: #e41b23;
    font-size: 14px;
    font-style: normal;
    font-weight: bold
}

.sommaire-options-modify-div {
    margin: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.sommaire-options-modify-font {
    color: #e41b23;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    text-decoration-color: #e41b23
}