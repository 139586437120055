.item-popup-header {
  flex: 0 0 auto;
  margin: 0;
  height: 62px;
  padding: 0px;
  background-color: rgb(255, 103, 76, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Barlow-Bold;
}

.btn-close {
  flex: 0 0 auto;
  padding: 12px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  right: 10px;
  color: #ffffff;
  position: absolute;
}

.btn-close:hover {
  background-color: rgba(0, 0, 0, 0.08)
}

@media (hover:none) {
  .btn-close:hover {
    background-color: transparent
  }
}