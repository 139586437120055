.cardContainer {
    box-sizing: border-box;
    flex-shrink: 0;
    width: "45%";
    /* height: "auto";
    padding: "10px"; */
}

.divContainer {
    height: 100%;
    display: block;
    position: relative;
    /* overflow: hidden; */
    overflow: visible;
    height: 100%;
    cursor: pointer;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 8px 5px 27px -5px rgba(0, 0, 0, 0.2);
    align-items: center;
    justify-content: center;
}

.divNotInStock {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
    display: flex;
    position: absolute;
    justify-content: center;
    background-color: #FFFFFF
}


.notInStockText {
    margin: 0;
    display: block;
    text-transform: none;
    color: #333333;
    font-family: Lato, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-weight: bold;
    padding-top: 10px
}

@media (max-width:599.95px) {
    .notInStockText {
        font-size: 16px
    }
}

@media (max-width:1279.95px) {
    .price-font {
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
    }
}

@media (min-width:960px) {
    .price-font {
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
    }
}

.divPrice {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.price-font {
    color: #e41b23;
    line-height: 1;
}

.div-name {
    display: flex;
}

.containerWidth{
    width: 25%;
}

@media (max-width:1279.95px) {
    .containerWidth{
        width: 33.33%;
    }
}

@media (max-width:1100px) {
    .containerWidth{
        width: 50%;
    }
}

@media (max-width:1000px) {
    .containerWidth{
        width: 33.33%;
    }
}

@media (max-width:660px) {
    .containerWidth{
        width: 50%;
    }
}

@media (max-width:351px) {
    .containerWidth{
        width: 100%;
    }
}