#choix-unite-liste {
    margin-top: 22px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#choix-unite-liste.no-recherche {
    margin-top: 32px;
}

.body-pop-activite {
    overflow: visible !important;
}

.content-section-pop-activite {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    justify-content: flex-start;
    /* font-size: 1.95em;
    font-family:'Barlow-Bold';
    color:#000000  */
}

.content-calendar-activite {
    border: 4px solid rgb(255, 103, 76) !important;
    margin-right: 16px;
}

.content-calendar-pop-activite {
    left: 0;
    right: 0;
    color: black;
    z-index: 99999;
    position: absolute;
    background-color: white;
    border: 4px solid rgb(255, 103, 76) !important;
}

.content-btn-pop-active {
    padding: 0px !important;
    background-color: white !important;
}

.btn-pop-active {
    text-align: center;
    padding: 6px;
    flex: 1;
    background-color: rgb(255, 103, 76, 1);
}


.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.popup-content {
    text-align: center;
}

button {
    margin: 0 10px;
    padding: 8px 16px;
    cursor: pointer;
}


/* Pop */

/* General */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1) !important;
    display: flex;
    align-items: center;
    justify-content: center
}

.modal-pop-form {
    /* max-width: 500px; */
    width: 27%;
    position: relative;
    padding: 10px 8px 7px;
    background-color: #13322B;
}

.content-pop-activite {
    width: auto !important;
}

/* header */
.header-pop-active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px !important;
}

.title-pop-active {
    color: white;
    text-align: left;
    font-family: 'BARLOW-BOLD';
    font-size: 26px;
    text-transform: uppercase;
}

.closeButton-activite {
    border: none;
    cursor: pointer;
    background: rgb(255, 103, 76, 1) !important;
}

.body-pop-activte {
    margin: 10px 0px;
    background-color: white;
}

/* footer */
.footer-pop-active {
    /* padding-top: 6px;  */
    /* background-color: #13322B */
}

.content-btn-pop {
    display: flex;
    text-align: center;
    padding: 0px !important;
    margin-top: 0px !important;
    justify-content: space-between;
    background: none !important;
    gap: 0.5rem
}

/* FORM */
.contain-form-pop-active {
    margin: 0px;
    padding: 20px;
    width: 100%;
}

.content-section-form-left {
    width: 45%;
    text-align: right;
}

.content-section-form-right {
    display: flex;
    width: 50%;
    /* justify-content: space-between; */
    margin-left: 12px;
    align-items: center;
}

.heure-disponible {
    background-color: rgba(0, 255, 52, 1);
}

.heure-partiellement-disponible {
    background-color: rgba(255, 255, 52, 1);
}

.heure-non-disponible {
    background-color: rgba(255, 0, 0, 1);
}

.heure-defaut {
    background-color: white;
}

/* Classe de base pour les activites*/
.nbre-activite {
    width: 100px;
    padding: 6px 0px;
    text-align: center;
    border-radius: 4%;
}

/* Classe pour les activités disponibles */
.nbre-activite-disponible {
    background: rgba(0, 255, 52, 1);
}

/* Classe pour les activités non disponibles */
.nbre-activite-non-disponible {
    background: rgba(255, 0, 0, 1);
}

/* Classe pour  les activités disponibles dans le pop up*/
.nbre-activite-non-disponible-in-pop {
    background: rgb(43 153 65);
}


@media (max-width: 768px) {
    .content-calendar-activite {
        margin-right: 0px !important;
        margin-bottom: 16px;
    }
}