.details-container {
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.size {
    width: 100%;
    position: relative;
    border-radius: 2px;
}


.div-second-area {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
    padding-left: 6px;
}

.div-second-area .box {
    width: calc(100% - 32px);
    height: 1.1875em;
    cursor: pointer;
    min-width: 16px;
    user-select: none;
    padding-right: 32px;
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.div-second-area .box:focus {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.05);
}

.div-second-area .box::-ms-expand {
    display: none;
}

.div-second-area .box {
    width: auto;
    height: auto;
    overflow: hidden;
    min-height: 1.1875em;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.div-second-area .box {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 10px 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    border-radius: 2px;
    background-color: #FFFFFF;
}

.display-box {
    flex: 2;
    margin: 0px 10px;
    display: flex;
}


.div-boxes {
    flex: 2;
    display: flex;
    max-height: 100px;
    flex-direction: row;
}

@media (min-width:960px) and (max-width:1279.95px) {
    .div-boxes {
        flex: 3;
    }
}

.type-selector {
    /* flex: 2; */
    display: flex;
    padding: 20px 0px 20px 40px;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: auto;
}

.details-container p {
    /* flex: 1; */
    color: #333333;
    font-size: 17px;
    font-style: normal;
    font-weight: bold;
    margin-right: 10px;
}

.type-selector p {
    color: #333333;
}

@media (max-width:1290.95px) {
    .type-selector p {
        font-size: 16px;
        font-style: normal;
        font-weight: bold;
    }
}

@media (max-width:1290px) {
    .type-selector {
        /* display: inline; */
        padding: 20px 10px 20px 10px;
    }
}

@media (max-width:360px) {
    .type-selector {
        display: inline-flex;
    }
}

.select-type {
    /* display: flex; */
    color: inherit;
    border: 0;
    margin: 0;
    cursor: pointer;
    /* display: inline-flex; */
    /* outline: none; */
    margin-right: 6px;
    /* position: relative; */
    align-items: center;
    user-select: none;
    border-radius: 2px;
    vertical-align: middle;
    justify-content: center;
    -moz-appearance: none;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    /* flex: 1; */
    /* width: 100%; */
    border: 1px solid #e41b23;
    justify-content: space-between;
    width: 170px;
    /* background-image: url(/../../images/icons/drop-down.png); */
    background-repeat: no-repeat;
    background-position: right;
    background-position-x: calc(100% - 2px);
    background-size: 22px;
}

@media (max-width:1279.95px) {
    .select-type {
        padding: 10px 5px;
    }
}

@media (max-width:959.95px) {
    .select-type {
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
    }
}

@media (min-width:960px) {
    .select-type {
        padding: 10px;
    }
}

@media (min-width:600px) {
    .select-type {
        font-size: 17px;
        font-style: normal;
        font-weight: bold;
    }
}

@media (max-width:505px) {
    .details-container {
        display: inline;
    }
    .type-selector {
        border-right: none;
    }
}