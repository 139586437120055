.unite-recherche-popup-span {
    height: 0;
}

.unite-recherche-popup-close {
    position: relative;
    width: auto;
    float: right;
    right: 30px;
    top: 10px;
    background-color: black;
}