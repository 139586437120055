.billeterie-div-filter {
  margin-left: 10%;
  border: 0;
}

.button-reservation-liste,
.button-achat-billet {
  /* margin-left: 10px; */
  background-color: rgb(255, 248, 247);
  color: rgb(255, 103, 76, 1);
  font-family: Barlow-Black;
  border: 4px solid rgb(255, 103, 76, 1);
  padding: 4px 12px 4px 12px;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 24px;
  cursor: pointer !important;
}

.button-achat-billet {
  /* margin-left: 10px; */
  margin: auto;
}

#billetterie-page-container .billetterie-header,
#tirage-page-container .billetterie-header {
  color: rgb(255, 103, 76, 1);
  font-family: Barlow-Bold;
  text-transform: uppercase;
  font-size: 2.4em;
}

.button-achat-billet:hover:not(.disabled),
.button-reservation-liste:hover:not(.disabled) {
  background-color: rgb(247, 234, 231);
}

#billetterie-page-container .achat-forfait-card-container table,
#tirage-page-container .achat-forfait-card-container table {
  border-collapse: collapse;
}

#billetterie-page-container .achat-forfait-card-container tr,
#tirage-page-container .achat-forfait-card-container tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  border-bottom: 1px solid rgb(255, 103, 76, 1);
}

#billetterie-page-container .achat-forfait-card-container table td.col1,
#tirage-page-container .achat-forfait-card-container table td.col1 {
  /* width: 110px; */
  padding-left: 0;
  padding-right: 0;
}

#billetterie-page-container .achat-forfait-card-container table.donnees td,
#tirage-page-container .achat-forfait-card-container table.donnees td {
  margin: 0;
  padding: 5px 8px;
  border-bottom: 1px solid #CCC;
  vertical-align: top;
}

#billetterie-page-container .achat-forfait-card-container td,
#tirage-page-container .achat-forfait-card-container td {
  text-align: left;
  display: table-cell;
  vertical-align: inherit;
}

#billetterie-page-container .achat-forfait-card-container a,
#tirage-page-container .achat-forfait-card-container a {
  font-weight: normal;
  text-decoration: underline;
  color: #5A0000;
  font-family: 'FiraSans-SemiBold', Verdana, Geneva, sans-serif;
}

#billetterie-page-container .achat-forfait-card-container a:-webkit-any-link,
#tirage-page-container .achat-forfait-card-container a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

#billetterie-page-container .achat-forfait-card-container element.style,
#tirage-page-container .achat-forfait-card-container element.style {
  background-image: url(https://www.ticketacces.net/organisations/cinemaamos/evenements/grandes/sos-fantomes.jpg);
}

#billetterie-page-container .achat-forfait-card-container img.image-evenement,
#tirage-page-container .achat-forfait-card-container img.image-evenement {
  margin-top: 20px;
  width: 250px;
  max-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  vertical-align: middle;
  background-color: #eee;
}

#billetterie-page-container .achat-forfait-card-container table td.col2,
#tirage-page-container .achat-forfait-card-container table td.col2 {
  padding-left: 20px;
  padding-top: 20px;
}

#billetterie-page-container .achat-forfait-card-container table td,
#tirage-page-container .achat-forfait-card-container table td {
  padding-top: 20px;
  padding-bottom: 20px;
}

#billetterie-page-container .achat-forfait-card-container table.donnees td,
#tirage-page-container .achat-forfait-card-container table.donnees td {
  margin: 0;
  padding: 5px 8px;
  border-bottom: 1px solid #CCC;
  vertical-align: top;
}


#billetterie-page-container .achat-forfait-card-container table td,
#tirage-page-container .achat-forfait-card-container table td {
  border: 0;
  border-collapse: collapse;
  padding: 0;
  margin: 0;
  vertical-align: top;
}

#billetterie-page-container .achat-forfait-card-container a,
#tirage-page-container .achat-forfait-card-container a {
  font-weight: normal;
  text-decoration: underline;
  color: #5A0000;
  font-family: 'FiraSans-SemiBold', Verdana, Geneva, sans-serif;
}

#billetterie-page-container .achat-forfait-card-container a:-webkit-any-link,
#tirage-page-container .achat-forfait-card-container a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

#billetterie-page-container .achat-forfait-card-container p.date,
#tirage-page-container .achat-forfait-card-container p.date {
  color: #222;
  font-family: 'FiraSans-SemiBold', Verdana, Geneva, sans-serif;
  font-size: 1.125rem;
  margin-bottom: 5px;
}

#billetterie-page-container .achat-forfait-card-container table td p,
#tirage-page-container .achat-forfait-card-container table td p {
  text-align: left;
}

#billetterie-page-container .achat-forfait-card-container p,
#tirage-page-container .achat-forfait-card-container p {
  text-align: left;
  margin: 0 0 1em 0;
  padding: 0;
  line-height: 1.4em;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

/* #billetterie-page-container .achat-forfait-card-container div.clear, hr.clear,
#tirage-page-container .achat-forfait-card-container div.clear, hr.clear {
    clear: both;
    height: 0;
    line-height: 0;
    border: 0;
    font-size: 0;
    margin: 0;
    padding: 0;
} */

#billetterie-page-container .achat-forfait-card-container div,
#tirage-page-container .achat-forfait-card-container div {
  display: block;
}

@media print, screen and (min-width: 64em) {

  #billetterie-page-container .achat-forfait-card-container .large-text-justify,
  #tirage-page-container .achat-forfait-card-container .large-text-justify {
    text-align: justify;
  }
}

#billetterie-page-container .achat-forfait-card-container .text-left,
#tirage-page-container .achat-forfait-card-container .text-left {
  text-align: left;
}

#billetterie-page-container .achat-forfait-card-container div.bulles,
#tirage-page-container .achat-forfait-card-container div.bulles {
  display: flex;
  flex-wrap: wrap;
}

#billetterie-page-container .achat-forfait-card-container div,
#tirage-page-container .achat-forfait-card-container div {
  display: block;
}

#billetterie-page-container .achat-forfait-card-container div.bulle-indicateur,
#tirage-page-container .achat-forfait-card-container div.bulle-indicateur {
  float: left;
  margin: 0 0 5px 0;
  z-index: 10;
}


#billetterie-page-container .achat-forfait-card-container div.bulle,
#tirage-page-container .achat-forfait-card-container div.bulle {
  float: left;
  margin: 5px 10px 10px -2px;
  padding: 0 0 0 10px;
  color: #555;
  min-width: 65px;
  border-left: 1px dotted #CCC;
}

#billetterie-page-container .achat-forfait-card-container strong,
#tirage-page-container .achat-forfait-card-container strong {
  font-weight: normal;
  font-family: 'FiraSans-SemiBold', Verdana, Geneva, sans-serif;
}

@media print, screen and (max-width: 1020px) {

  #billetterie-page-container .achat-forfait-card-container .col1,
  #tirage-page-container .achat-forfait-card-container .col1 {
    display: inline-block;
  }

  #billetterie-page-container .achat-forfait-card-container .col2,
  #tirage-page-container .achat-forfait-card-container .col2 {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }

  #billetterie-page-container .achat-forfait-card-container img.image-evenement,
  #tirage-page-container .achat-forfait-card-container img.image-evenement {
    margin-top: 2px;
    width: 265px;
    height: 210px;
  }

  .billeterie-div-filter {
    margin-left: 4%;
  }
}