.achat-forfait-item-main-div {
    width: 100%;
}

.achat-forfait-item, .tirage-item {
    padding: 8px 20px 10px 20px;
    border-bottom: 1px solid rgb(255, 103, 76, 1);
    width: 100%;
    /* display: flex; */
    display: inline-block;
    height: 90px;
    list-style-type: none;
    position: relative;
    display: inline-table
}

.achat-forfait-tab-ul {
    margin-top: 0;
    padding-top: 0;
    padding-left: 0;
    width: 100%;
    display: flex;
}

.achat-forfait-item-hover:hover {
    background-color: rgb(255, 237, 232);
}

.achat-forfait-item div {
    display: inline-block;
}

.achat-forfait-card-buyable-list-item-name-wrapper {
    float: left;
    text-align: left;
    margin-left: 4px;
}

.achat-forfait-card-buyable-list-item-description-wrapper {
    float: left;
    margin-left: 4px;
}

.achat-forfait-card-buyable-list-item-name {
    text-align: left;
    float: left;
    font-size: 20px;
    font-family: Barlow-Bold;
    text-align: center;
}

.achat-forfait-card-right-side {
    float: right;
    /* float: right;
    text-align: right;
    margin-right: 50px; */
    margin-right: 0;
}

.achat-forfait-card-buyable-list-item-montant {
    margin-left: 50px;
    font-size: 18px;
    font-family: Barlow-Bold;
}

.achat-forfait-card-buyable-list-item-image-wrapper {
    /* margin-left: 50px;
    float: left; */
}

.button-achat-forfait-card{
    margin-left: 10px;
    background-color: rgb(255, 235, 232);
    color: white;
    font-family: Barlow-Black;
    /* padding-right: 8px; */
    /* width: 200px; */
    border: 3px solid rgb(255, 103, 76, 1);
    cursor: pointer;
}

.button-achat-forfait-card:hover{
    background-color: rgb(250, 211, 205);
}

.achat-forfait-card-right-side img {
    margin-left: 10px;
}

@media (max-width: 1000px) {
    .achat-forfait-card-right-side {
        position: relative;
        margin-top: 2px;
        margin-left: auto;
        margin-right: auto;
        float: left;
    }
}


@media screen and (max-width: 1200px) {
    .achat-forfait-item, .tirage-item {
        height: 110px;
    }
}

@media screen and (max-width: 1000px) {
    /* .achat-forfait-card-buyable-list-item-image-wrapper {
        margin-left: 5px;
    } */
    /* .achat-forfait-item div {
        display: block;
    } */
    .achat-forfait-card-buyable-list-item-montant {
        /* margin-left: 0px; */
    }
    .achat-forfait-card-right-side {
        /* position: relative;
        overflow: auto;
        height: max-content;*/
        float: none;
        width: 100%;
        margin-right: 0;
        text-align: right;
    }
}

@media screen and (max-width: 800px) {
    .achat-forfait-item, .tirage-item {
        height: 120px;
    }
    .achat-forfait-card-buyable-list-item-name {
        font-size: 18px;
    }
    .achat-forfait-card-buyable-list-item-image-wrapper {
        float: none;
    }
    .achat-forfait-card-right-side {
        float: none;
    }
}

@media screen and (max-width: 640px) {
    .achat-forfait-item {
        border-bottom: 1px solid rgb(255, 103, 76, 1);
    }
    .achat-forfait-card-buyable-list-item-montant {
        font-size: 16px;
    }
}

@media screen and (max-width: 440px) {
    .achat-forfait-card-buyable-list-item-name {
        font-size: 16px;
    }
    .achat-forfait-item, .tirage-item {
        padding: 2px 6px 2px 6px;
    }
    .achat-forfait-card-right-side {
        text-align: center;
    }
}