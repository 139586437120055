.unite-card {
  border: 4px solid rgb(255, 103, 76);
  height: 260px;
  margin-top: 0px;
  margin-block-end: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.unite-card-availability-level {
  color: white;
  font-size: 16px;
  margin-top: 16px;
  text-align: left;
  width: 100%;
  padding: 5px;
}

.ucal-disponible {
  /* ucal = UniteCardAvailabilityLevel*/
  background-color: green;
  font-family: Barlow-SemiBold;
}

.ucal-partiellement-disponible {
  background-color: rgb(240, 230, 0);
  color: black;
  font-family: Barlow-SemiBold;
}

.ucal-indisponible {
  background-color: red;
  font-family: Barlow-SemiBold;
}
.ucal-reserve {
  background-color: black;
  font-family: Barlow-SemiBold;
}
.unite-card-thumbnail {
  height: 100%;
  background-color: gainsboro;
}
.unite-card-thumbnail img.loading{
  background-color: inherit;
}
.unite-card-thumbnail img {
  background-color: black;
  height: 100%;
  width: 348px;
  object-fit: contain;
  object-position: center;
}

.unite-card-info {
  width: 100%;
  padding: 20px;
  padding-top: 20px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
}

.unite-card-info-nom span {
  font-family: Barlow-Black;
  font-size: 24px;
}

.unite-card-info-description {
  overflow-y: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 18px;
  height: 65%;
}

.unite-card-info-description.no-calendrier {
  height: 240px;
}

.unite-card-info-description.no-icons {
  height: 174px;
}

.unite-card-info-description.no-calendrier.no-icons {
  height: 274px;
}

.unite-card-info-description span {
  font-family: Barlow-SemiBold;
}

.unite-card-info-icons {
  /* margin-top: 8px;*/
}
.unite-card-disponibilites {
  min-width: 270px;
  padding: 8px;
  border-left: 4px solid rgb(255, 103, 76);
}
.unite-card-disponibilites-left-filler,
.unite-card-disponibilites-right-filler {
  display: none;
  float: left;
  border-top: 4px solid rgb(255, 103, 76);
}

@media screen and (max-width: 1500px) {
  .unite-card.has-calendrier,
  .unite-card.no-calendrier {
    height: 100%;
    flex-direction: column;
    max-width: 410px;
  }
  .unite-card-availability-level {
    max-width: 410px;
  }
  .unite-card-thumbnail img {
    width: 100%;
  }

  .unite-card-thumbnail.has-calendrier,
  .unite-card-thumbnail.no-calendrier {
    width: 100%;
    height: 71%;
  }

  .unite-card-info-description.no-calendrier {
    height: 200px;
  }

  .unite-card-info-description.no-icons {
    height: 134px;
  }

  .unite-card-info-description.no-calendrier.no-icons {
    height: 234px;
  }

  .unite-card-disponibilites {
    border-left: 0px;
    border-top: 4px solid rgb(255, 103, 76);
  }
}

@media screen and (max-width: 1200px) {
  .unite-card-disponibilites-left-filler,
  .unite-card-disponibilites-right-filler {
    display: initial;
    width: calc((100% - 270px - 162px) / 2); /* 270px pour le calendrier, 162px pour la section consulter */
  }
}

@media screen and (max-width: 940px) {
  .unite-card-disponibilites-left-filler,
  .unite-card-disponibilites-right-filler {
    display: initial;
    width: calc((100% - 270px) / 2);
  }
}
@media screen and (max-width: 425px) {
  .unite-card-disponibilites {
    width: 100%;
    min-width: 0;
  }
}

.unite-card-consulter-wrapper {
  min-width: 162px;
  background-color: rgb(255, 103, 76);
  height: 100%;
  text-align: center;
}

@media screen and (max-width: 1500px) {
  .unite-card-consulter-wrapper.has-calendrier {
    height: 29%;
  }
}

@media screen and (max-width: 1280px) {
  .unite-card-consulter-wrapper.no-calendrier {
    height: 29%;
  }
}

@media screen and (max-width: 1200px) {
  .unite-card-consulter-wrapper.has-calendrier {
    height: 24%;
  }
}

@media screen and (max-width: 1100px) {
  .unite-card-consulter-wrapper.has-calendrier {
    height: calc(29% + 2px);
  }
  .unite-card-consulter-wrapper.no-calendrier {
    height: calc(33% + 2px);
  }
}

@media screen and (max-width: 940px) {
  .unite-card-consulter-wrapper.has-calendrier {
    height: 20%;
    width: 100%;
  }
}

@media screen and (max-width: 880px) {
  .unite-card-consulter-wrapper.no-calendrier {
    height: 20%;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .unite-card-consulter-wrapper.no-calendrier {
    height: 25%;
    width: 100%;
  }
}

.unite-card-consulter-wrapper span {
  color: white;
  font-family: Barlow-Bold;
}

.unite-card-a-partir-de {
  margin-top: 68px;
}

.unite-card-a-partir-de.no-calendrier {
  margin-top: 113px;
}

@media screen and (max-width: 1660px) {
  .unite-card-a-partir-de.no-calendrier {
    margin-top: 98px;
  }
}

@media screen and (max-width: 940px) {
  .unite-card-a-partir-de.has-calendrier {
    margin-top: 44px;
  }
}

@media screen and (max-width: 880px) {
  .unite-card-a-partir-de.no-calendrier {
    margin-top: 44px;
  }
}

@media screen and (max-width: 720px) {
  .unite-card-a-partir-de.no-calendrier {
    margin-top: 32px;
  }
}

@media screen and (max-width: 620px) {
  .unite-card-a-partir-de.no-calendrier {
    margin-top: 22px;
  }
}

@media screen and (max-width: 425px) {
  .unite-card-a-partir-de.no-calendrier {
    margin-top: 36px;
  }
}

.unite-card-a-partir-de span {
  font-size: 16px;
}

.unite-card-montant {
  margin-top: 8px;
}

.unite-card-montant span {
  font-size: 20px;
}

.unite-card-consulter {
  border: 4px solid white;
  padding: 4px 12px 4px 12px;
  width: fit-content;
  margin: auto;
  margin-top: 24px;
}

.unite-card-consulter:hover {
  cursor: pointer;
}

.unite-card-consulter span {
  font-size: 16px;
}
