.jss2612 {
    display: flex;
    padding: 30px;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center
}

@media (max-width:959.95px) {
    .jss2612 {
        padding: 20px
    }
}

.jss2619 {
    display: flex;
    max-width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: center;
}

.jss2620 {
    display: grid;
    grid-gap: 10px 10px;
    max-width: 100%;
    grid-template-columns: 130px 130px 130px 130px 130px;
}

.aSeparator {
    border-top:1px solid #5f656d;
    height:1px;
    margin:16px 0;
   }
   
.intersections {
    margin: 0 10%;
    border-top: dashed 1px rgba(226, 139, 89, 0.596)
}