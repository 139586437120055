#reservation-camps-page .container {
    display: flex;
    justify-content: space-between;
}

#reservation-camps-page .box {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
}

#reservation-camps-page .small-box {
    background-color: #f0f0f0;
    margin: 10px;
}

#reservation-camps-page input[type=radio] {
    position: relative;
    top: 12px;
    margin-top: -14px;
}

#reservation-camps-page .form-group label {
    float: left;
}

#reservation-camps-page .camp-form select {
    height: auto;
}

#reservation-camps-page .input-group {
    margin: 10px;
    text-align: center;
}

#reservation-camps-page label {
    display: block;
    /* margin-bottom: 5px; */
}

#reservation-camps-page .camp-form inpu[type=checkbox] {

}

#reservation-camps-page .camp-form input, #reservation-camps-page .small-input {
    width: 200px;
    /* Adjust the width as needed */
    padding: 8px;
    box-sizing: border-box;
}

#reservation-camps-page .info-camp {
    display: inline-block;
    margin-top: 25px;
}

#reservation-camps-page select:not(:first-child), #reservation-camps-page .camp-form input,
#reservation-camps-page .small-input {
    font-size: 1em;
}


#reservation-camps-page .camp-form input[type=checkbox] {
    height: 44px;
    width: 44px;
}

#reservation-camps-page .camp-form input {
    height: 25px;
}

#reservation-camps-page .right {
    margin-left: auto;
    margin-right: 17%;
}

#reservation-camps-page .camp-form {
    margin: auto;
    margin-top: 25px;
    width: 94%;
    border: 2px solid black;
    text-align: left;
    padding: 5px;
}

#reservation-camps-page .small-input {
    width: 27px;
    background-color: rgb(238, 233, 233);
    border: none;
    margin-right: 2px;
    height: 10px;
    cursor: pointer;
    line-height: 0;
}

#reservation-camps-page > table {
    width: auto;
    margin: auto;
    max-width: 50%;
}

#reservation-camps-page .camp-form .camp-input {
    display: flex;
    flex-direction: row;
    margin-bottom: 3px;
}

#reservation-camps-page .camp-form input {
    border: none;
    border-bottom: 2px solid black;
    background-color: #F1F3FF;
}

#reservation-camps-page .camp-form .small-error {
    position: relative;
    bottom: 5px;
    color: red;
    font-size: 10px
}

#reservation-camps-page .camp-age-input {
    width: 200px;
    margin-right: 10px
}

#reservation-camps-page table.greenTable {
    font-family: Georgia, serif;
    border: 6px solid #24943A;
    background-color: #D4EED1;
    width: 100%;
    text-align: center;
}

#reservation-camps-page table.greenTable td, #reservation-camps-page table.greenTable th {
    border: 1px solid #24943A;
    padding: 3px 2px;
}

#reservation-camps-page table.greenTable tbody td {
    font-size: 13px;
}

#reservation-camps-page table.greenTable thead {
    background: #24943A;
    background: -moz-linear-gradient(to top, #5baf6b 0%, #3a9e4d 66%, #24943A 100%);
    background: -webkit-linear-gradient(to top, #5baf6b 0%, #3a9e4d 66%, #24943A 100%);
    background: linear-gradient(to bottom, #5baf6b 0%, #3a9e4d 66%, #24943A 100%);
    border-bottom: 0px solid #444444;
}

#reservation-camps-page table.greenTable thead th {
    font-size: 19px;
    font-weight: bold;
    color: #F0F0F0;
    text-align: left;
    border-left: 2px solid #24943A;
}

#reservation-camps-page table.greenTable thead th:first-child {
    border-left: none;
}

#reservation-camps-page table.greenTable tfoot {
    font-size: 13px;
    font-weight: bold;
    color: #F0F0F0;
    background: #24943A;
    background: -moz-linear-gradient(to top, #5baf6b 0%, #3a9e4d 66%, #24943A 100%);
    background: -webkit-linear-gradient(to top, #5baf6b 0%, #3a9e4d 66%, #24943A 100%);
    background: linear-gradient(to bottom, #5baf6b 0%, #3a9e4d 66%, #24943A 100%);
    border-top: 1px solid #24943A;
}

#reservation-camps-page table.greenTable tfoot td {
    font-size: 13px;
}

#reservation-camps-page .verticalTextStyle {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
    width: 10px
}

#reservation-camps-page .table-small {
    width: 50px
}

#reservation-camps-page .table-input {
    width: 200px;
    height: 70px;
}

#reservation-camps-page .table-input input {
    /* height: 100px !important; */
}

#reservation-camps-page .destination-small {
    display: none;
}

#reservation-camps-page #small-nas {
    display: none;
}

#reservation-camps-page .camp-municipalite-input {
    width: 200px;
    font-size: 1.5em !important;
    position: relative;
    top: -7px;
}

@media (max-width: 988px) {
    #reservation-camps-page .camp-municipalite-input {
        top: 0px;
    }
}

@media (max-width: 1060px) {
    #reservation-camps-page .destination-small {
        display: block;
    }

    #reservation-camps-page .input-group {
        display: none;
    }

    #reservation-camps-page #small-nas {
        display: block;
    }

    #reservation-camps-page #large-nas {
        display: none;
    }

    #reservation-camps-page .achat-forfait-card-container {
        width: 99%;
    }

    #reservation-camps-page .small-box {
        margin: 5px 0 0 0;
    }

    #reservation-camps-page {
        padding-left: 0;
    }
}

#reservation-camps-page .clickable.off {
    color: rgb(153, 148, 148);
    border: 4px solid rgb(121, 121, 121);
    cursor: not-allowed;
}


#produits {
    width: 50%;
    margin: 0 auto;

    .list {
        display: flex;
        flex-direction: row;

        @media (width <= 1250px) {
            flex-direction: column;
        }
        gap: 2rem;

        > div {
            border: 1px solid #000;
        }

        .enfant {
            label {
                background-color: #eea236;
            }

            * {
                background-color: rgb(255, 231, 195);
            }
        }

        .adulte {
            label {
                background-color: #89d787;
            }

            * {
                background-color: rgb(215, 255, 195);
            }
        }

        .enfant, .adulte {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;

            label {
                padding: 0.5rem;
                font-weight: bold;
            }

            > div {
                padding: 0.5rem;
                font-weight: bold;
            }
        }

        .grandeur {
            display: grid;
            grid-template-columns: 1fr auto;
            justify-items: start;
            flex: 1;
            gap: 0.5rem;
        }
    }

    .prix {
        display: flex;
        justify-content: center;
        background-color: #13322b;
        color: white;

        > div {
            border: 1px solid #000;
        }

        label {
            font-weight: bold;
        }

        > div:first-child {
            border-right: 1px solid white;
        }

        > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 0.4rem;
        }

        div {
        }
    }
}
