footer {
    border-top: 2px solid rgb(153, 153, 153, 1);
    text-align: center;
    padding-top: 16px;
    margin-bottom: 16px;
    clear: both;
}

footer span {
    vertical-align: middle;
    font-size: 12px;
    font-weight: bold;
    padding: 4px;
}

footer img {
    width: 40px;
    vertical-align: middle;
}