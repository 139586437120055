.activityReservationContainer {
   position: relative;
   box-shadow: 0 4px 22px 0 rgba(65, 64, 66, 0.15);
   max-width: 88%;
   margin-left: auto;
   margin-right: auto;
}

.float-child {
   width: 50%;
   float: left;
}

.activity-left-div {
   width: 50%;
   padding-right: 8px;
}

.activity-left-div p {
   margin: auto;
   font-size: 19px;
   padding-bottom: 15px;
   width: 95%;
}

.calendar-container {
   /* flex: 1; */
   /* max-width: 550px; */
   width: 50%;
   margin-top: 30px;
   padding-right: 40px;
}

.activity-img {
   margin-top: 25px;
   margin: auto;
   width: 96%;
}

.activity-img img {
   width: 97%;
}

.activity-prices {
   column-gap: 45px;
}

.activity-prices div {
   width: 160px;
   display: inline-block;
}

.activity-prices:first-child {
   margin-left: 5%;
}

.fl-builder-content *, .fl-builder-content *:before, .fl-builder-content *:after {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box
}

.wc-bookings-booking-form {
   /* border: 1px solid #ddd; */
   /* padding: 1em 1em 0; */
   margin-bottom: 6px;
   color: #000;
}

.wc-bookings-booking-form .form-field {
   margin: 0 0 1em;
}

.wc-bookings-booking-form .block-picker {
   list-style: none;
   margin: 0;
   margin-bottom: 10px;
   overflow: hidden;
   zoom: 1;
}

.wc-bookings-booking-form .block-picker li {
   float: left;
   margin: 0;
   padding: 0;
   min-width: 33%;
}

.wc-bookings-booking-form fieldset {
   margin: 0 0 1em;
   line-height: 2.25em;
}

.unite-div {
   border: 1px solid rgb(255, 103, 76, 1);
   display: inline-block;
   width: 43px;
   padding: +9px 5px 9px 5px;
   margin-right: 6px;
   margin-bottom: 6px;
}

.activity-time {
   display: inline;
   width: auto;
   height: 100px;
   font-family: "Hurme Geometric Sans 3", "Helvetica Neue", Arial, sans-serif;
   font-weight: 400;
   font-size: 18px;
   line-height: 1.2;
   color: #000;
}

.select-unite {
   display: inline-block;
   position: relative;
   width: 50%;
}

.calendar-title {
   margin: auto;
   color: red;
   margin-top: 16px;
}

.activity-select {
   font-size: 17px;
}

.legend {
   text-align: center;
   margin-top: 10px;
}

.legend div {
   display: inline-block;
}

.legend .square {
   content: "";
   display: inline-block;
   margin-left: 17px;
   width: 20px;
   height: 20px;
}

.ended {
   background-color: rgb(231, 231, 231);
   border: 1px solid black;
   opacity: 0.5;
}

.partially {
   background-image: repeating-linear-gradient(45deg, yellow, yellow 5px, rgba(134, 233, 95, 0.712) 5px, rgba(134, 233, 95, 0.712) 10px);
}

.available {
   background-color: rgba(134, 233, 95, 0.712);
}

.unavailable {
   background-color: rgb(248, 182, 182);
}

.select-div {
   position: relative;
   display: inline-block;
   margin-bottom: 15px;
   width: 90%;
}

.select-div select {
   font-family: 'Arial';
   display: inline-block;
   width: 100%;
   cursor: pointer;
   padding: 10px 15px;
   outline: 0;
   border: 0px solid #000000;
   border-radius: 0px;
   background: #e6e6e6;
   color: #000000;
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
}

.select-div select:hover, .select-div select:focus {
   color: #000000;
   background: #cccccc;
}

.select-div select:disabled {
   opacity: 0.5;
   pointer-events: none;
}

.select_arrow {
   position: absolute;
   top: 16px;
   right: 15px;
   pointer-events: none;
   border-style: solid;
   border-width: 8px 5px 0px 5px;
   border-color: #7b7b7b transparent transparent transparent;
}

.select-div select:hover~.select_arrow, .select-div select:focus~.select_arrow {
   border-top-color: #000000;
}

.select-div select:disabled~.select_arrow {
   border-top-color: #cccccc;
}

.activity-selectors {
   position: relative;
   left: 2%;
   margin: auto;
   width: 90%;
}

.btn-confirm-reservation {
   display: inline-block;
   margin-bottom: 10px;
   font-weight: 400;
   line-height: 1.42857143;
   text-align: center;
   white-space: nowrap;
   vertical-align: middle;
   -ms-touch-action: manipulation;
   touch-action: manipulation;
   cursor: pointer;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   background-image: none;
   border: 1px solid rgba(0, 0, 0, 0.205);
   font-size: 16px;
   width: 100%;
   min-height: 45px;
   text-transform: uppercase;
   color: #fff;
   background: #8CB878;
   border: 1px solid #8CB878
}

.btn-confirm-reservation:hover {
   background: #a3c493;
}

.btn-confirm-reservation[disabled] {
   cursor: not-allowed;
   border: 1px solid #999999;
   background-color: #cccccc;
   color: #666666;
}

.member-pageContainer {
   z-index: 400;
   position: relative;
   box-shadow: 0 4px 22px 0 rgba(65, 64, 66, 0.15);
   padding-left: 40px;
   max-width: 88%;
   margin-left: auto;
   margin-right: auto;
}

@media (max-width:1279.95px) {
   .member-pageContainer {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      padding-left: 10px;
   }

   .activity-prices {
      display: relative;
      column-gap: 6px;
   }
}

@media (max-width: 1000px) {
   .member-pageContainer {
      padding-left: 0;
      max-width: 100%;
   }

   .activity-left-div {
      width: 100%;
   }

   .calendar-container {
      width: 97%;
      padding-right: 0;
      margin: auto;
   }
}

@media (max-width: 385px) {
   .calendar-container {
      width: 100%;
   }

   .activity-selectors div {
      display: inline;
   }
}