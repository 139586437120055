.popup-main-panel {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    margin-bottom: 2px;
    border: 2px solid brown;
    height: 100%;
    border-color: rgb(248, 139, 76);
    max-width: 55em;
    margin: auto;
    background: #ffffff;
    width: 550px;
}
  
.popup-main-panel button {
    overflow: visible
}

.popup-main-panel select {
    text-transform: none
}

.popup-main-panel button {
    -webkit-appearance: button
}

.popup-main-panel input[type=radio] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

.popup-main-panel input, .popup-main-panel select {
    font-family: inherit;
    line-height: inherit
}

.popup-main-panel ul {
    margin-top: 0;
    margin-bottom: 10px
}

.popup-main-panel .row {
    margin-right: -15px;
    margin-left: -15px;
}

.popup-main-panel label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px
}

.popup-main-panel input[type=radio] {
    margin: 4px 0 0;
    line-height: normal
}

.popup-main-panel input[type=file]:focus, .popup-main-panel input[type=checkbox]:focus, .popup-main-panel input[type=radio]:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.popup-main-panel .details-form-control {
    width: 100%;
    height: 34px;
    line-height: 1.42857143;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    border-bottom-width: 1px;
    padding: 5px 0px 0px 20px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: black;
    background-color: #e7e7e7;
    border-color: #5e5e5f;
}

.popup-main-panel .details-form-control:focus {
    border-color: rgba(255, 136, 0, 0.705);
    outline: 0;
}

.popup-main-panel .focus {
    border-color: rgba(255, 0, 0, 0.705);
    outline: 0;
    outline: none !important;
    box-shadow: none !important;
    border-bottom: Solid 2px #d82131 !important
}

/* .popup-main-panel .details-form-control::-webkit-input-placeholder {
    color: #999
} */

.popup-main-panel .details-form-control>option {
    color: #5e5e5f;
}

.btn-popup {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid rgba(0, 0, 0, 0.205);
    font-size: 16px;
    width: 100%;
    min-height: 45px;
    text-transform: uppercase;
    background-color: #e7e7e7
}

.btn-popup:hover {
    background-color: rgb(255, 166, 151);
    color: #ffffff
}

.popup-main-panel .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.popup-main-panel .btn.focus, .btn:focus, .btn:hover {
    color: #333;
    text-decoration: none
}

.popup-main-panel .btn.active, .btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.popup-main-panel .btn-primary {
    background-color: rgb(255, 133, 111);
    border-color: rgb(255, 103, 76, 1);
}

.popup-main-panel .btn-primary:hover {
    background-color: rgb(248, 141, 122);
}

.btn-group {
    position: relative;
    vertical-align: middle
}

.btn-group>.btn-popup {
    position: relative;
    float: left
}

.btn-group>.btn.active, .btn-group>.btn:active, .btn-group>.btn:focus, .btn-group>.btn:hover {
    z-index: 2
}

.btn-group .btn-popup+.btn-popup {
    margin-left: -1px
}

.btn-group>.btn:first-child {
    margin-left: 0
}

[data-toggle=buttons]>.btn-popup input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.panel-body {
    padding: 15px
}

.has-float-label {
    font-size: 14px;
    display: block;
    position: relative;
    background-color: #e7e7e7;
}

.has-float-label label {
    position: absolute;
    cursor: text;
    font-size: 85%;
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
    font-weight: normal;
    top: -.92em;
    /* top: -.5em; */
    left: 0.75rem;
    z-index: 10;
    line-height: 1;
    color: #777777;
}

.has-float-label select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.has-float-label input, .has-float-label select {
    font-size: 16px;
    display: inline;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid rgba(0, 0, 0, .1);
    color: #636363;
}

.has-float-label input::-webkit-input-placeholder, .has-float-label select::-webkit-input-placeholder, .has-float-label textarea::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.has-float-label input::placeholder, .has-float-label select::placeholder, .has-float-label textarea::placeholder {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s
}

.has-float-label input:placeholder-shown:not(:focus)+*, .has-float-label select:placeholder-shown:not(:focus)+*, .has-float-label textarea:placeholder-shown:not(:focus)+* {
    font-size: 100%;
    opacity: .8;
    top: .25em;
    color: #000000;
}

.has-float-label input:focus, .has-float-label select:focus, .has-float-label textarea:focus {
    outline: 0;
    border-color: rgba(0, 0, 0, .5);
}

.has-float-label select {
    padding-right: 1em;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .5em bottom .25em;
    background-size: 8px 10px;
}

.has-float-label input:placeholder-shown:not(:focus)+*, .has-float-label select:placeholder-shown:not(:focus)+*, .has-float-label textarea:placeholder-shown:not(:focus)+* {
    color: #636363;
}

.popup-main-panel input::-webkit-input-placeholder {
    color: #cccccc;
    position: relative;
    padding-left: 0;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-transition: padding 0.5s;
    transition: padding 0.5s;
    display: block
}

.popup-main-panel input:hover::-webkit-input-placeholder, .popup-main-panel input:focus::-webkit-input-placeholder {
    padding-left: 10px
}

.popup-main-panel input {
    background-color: transparent !important
}

.popup-main-panel select:focus, .popup-main-panel input:not([type=checkbox]):not([type=radio]):focus {
    outline: none !important;
    box-shadow: none !important;
    border-bottom: Solid 2px rgba(255, 136, 0, 0.705) !important
}

.popup-main-panel a {
    color: #FDB415
}

.popup-main-panel a:focus, .popup-main-panel a:hover {
    color: rgb(253, 111, 86);;
    font-weight: bold;
    cursor: pointer;
}

.config .heading {
    font-family: TabernaSans-Black;
    padding: 9px;
    font-size: 20px;
    padding-top: 7px;
    margin-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #ffffff;
}

.config .heading .btn-circle {
    height: 32px;
    width: 32px;
    outline: none;
    font-weight: bold;
    margin: 0px 5px;
    font-size: 15px;
    cursor: default;
    color: #ffffff;
    background: rgb(255, 103, 76, 1);
    border-color: black;
}

.form-group {
    font-weight: bold;
    margin-bottom: 15px
}

.btn-group .active {
    background: rgb(255, 103, 76, 1) !important;
    color: #ffffff
}

.btn-circle {
    min-height: 0px;
    text-align: center;
    padding: 3px 0;
    line-height: 1.428571429;
    border-radius: 15px
}

div:focus {
    outline: thin dotted #999
}

.btn-group {
    padding: 0px
}

.autocomplete-search {
    display: table;
    width: 100%;
    border: solid 2px #B9A876;
    padding: 0px;
    background: #000000;
    margin-bottom: 15px
}

.autocomplete-icon {
    text-align: center;
    color: #ffffff;
    width: 36px;
    display: table-cell;
    font-size: 26px
}

.autocomplete input {
    border-bottom: 2px solid #313131
}

.btn:focus {
    border: solid black 1px
}

select:focus, input:focus {
    outline: none !important
}

/* input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    border-bottom: solid 1px #9B937D;
    -webkit-text-fill-color: #ffffff;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s
} */

.autocomplete {
    position: relative;
    display: inline-block;
    width: 100%
}

.popup-main-panel input {
    border: 1px solid transparent;
    background-color: #f1f1f1;
    padding: 10px;
    font-size: 16px
}

.popup-main-panel .btn-confirm {
    color: black;
    background-color: rgb(106, 211, 36);
    border-color: #63ac27;
    font-size: 20px;
    margin-bottom: 5px;
}

.popup-main-panel .btn-confirm:hover {
    background-color: rgb(123, 219, 59);
}

.btn-group .not-allowed {
    cursor: not-allowed !important;
    pointer-events: auto !important;
    background-color: rgb(80, 79, 79);
    border-color: #525252
}

.optionnal {
    color: #999;
    font-size: 12px;
    font-style: italic;
    cursor: default;
    position: relative;
    top: -20px;
}

.popup-main-panel {
    overflow-y: scroll;
    height: 75%;
}

/* 
.popup-main-panel::-webkit-scrollbar {
    width: 20px;
}

.popup-main-panel::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.popup-main-panel::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}

.popup-main-panel::-webkit-scrollbar-thumb:hover {
    background: #b30000;
} */

@media (max-width:572px) {
    .popup-main-panel {
        /* width: auto; */
        max-width: 100%;
        /* min-width: none; */
    }
}