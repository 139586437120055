.mainButton {
    color: inherit;
    border: 0;
    margin: 0;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 2px;
    vertical-align: middle;
    justify-content: center;
    -moz-appearance: none;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.mainButton::-moz-focus-inner {
    border-style: none;
}

.normal-font {
    color: #333333;
    font-size: NaNrem;
    font-family: Lato, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    display: block;
    text-transform: none;
}

.flex {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.item-name {
    font-size: 17px;
    font-style: normal;
    text-align: left;
    word-break: break-word;
    font-weight: bold;
    line-height: 1;
    margin-right: 5px;
    vertical-align: middle;
}



.subDiv {
    padding: 10px;
    /* display: "flex"; justify-content: "space-between"; */
}

.header-sommaire {
    padding: 20px;
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    flex-direction: column;
    justify-content: center;
    background-color: #efefef;
}

.pageContainer {
    z-index: 400;
    position: relative;
    box-shadow: 0 4px 22px 0 rgba(65, 64, 66, 0.15);
    padding-left: 10px;
    max-width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1px;
}

.div-restaurant-page {
    flex: 2;
    max-width: 100%;
}

.flex-details {
    display: flex;
}

@media (max-width:1279.95px) {
    .item-name {
        font-size: 12px;
        font-style: normal;
        font-weight: bold;
    }

    .pageContainer {
        max-width: 97%;
    }
}

@media (min-width:960px) {
    .item-name {
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
    }
}

@media (max-width: 1000px) {
    .flex-details {
        display: inline;
    }
    .pageContainer {
        padding-left: 0;
        max-width: 100%;
    }
}