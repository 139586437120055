:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

.calendar-body {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
  position: relative;
}

.calendar-row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

.calendar-div {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
  padding: 0 50px 0 50px;
}

.calendar-div .calendar-icon {
  position: absolute;
  top: 10px;
  border-radius: 0;
  font-weight: normal;
  border: none;
  height: 2.5em;
  width: 2em;
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  cursor: pointer;
  transition: .15s ease-out;
  background-color: inherit;
}

.calendar-div .calendar-header .calendar-icon:first-of-type {
  left: 2px;
  margin-left: 1em;
}

.calendar-div .calendar-header .calendar-icon:last-of-type {
  margin-right: 1em;
  right: 2px;
}

.calendar-div .calendar-header .calendar-icon-transition:hover {
  transform: scale(1.25);
  transition: .25s ease-out;
  color: var(--main-color);
}

.calendar-div .calendar-header .calendar-icon-state-disabled {
  opacity: .35;
  background-image: none;
}

.calendar-div .calendar-header {
  position: relative;
  /* padding: 1.75em 0; */
  border-bottom: 1px solid var(--border-color);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  /* padding: 10px 0 0 10px; */
  background-color: rgb(255, 103, 76, 1);
  border-bottom: 1px solid var(--border-color);
  height: 60px;
  padding: 0;
  border-bottom: none;
  overflow: hidden;
  text-transform: uppercase;
  border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border: 0;
  border-top: 1px solid #999999;
  color: #fff
}

.calendar-div .days {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar-div .calendar-body .cell {
  position: relative;
  height: 4.5em;
  border-right: 1px solid var(--border-color);
  outline: 1.4px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  /* background: var(--neutral-color); */
  transition: 0.25s ease-out;
}

.calendar-div .calendar-body .cell-selectable {
  background-color: rgba(134, 233, 95, 0.712)
}

.calendar-div .calendar-body .cell-selectable:hover, .cell-partially:hover {
  background-color: rgb(92, 208, 26);
}

.calendar-div .calendar-body .cell-partially {
  background-image: repeating-linear-gradient( 45deg, yellow, yellow 5px, rgba(134, 233, 95, 0.712) 5px, rgba(134, 233, 95, 0.712) 10px);
}

.calendar-div .calendar-body .calendar-row {
  border-bottom: 1px solid var(--border-color);
}

.calendar-div .calendar-body .calendar-row:last-child {
  border-bottom: none;
}

.calendar-div .ui-datepicker-title {
  position: absolute;
  text-align: center;
  line-height: 1em;
  font-weight: normal;
  padding: .99em 0;
  left: 42%;
}

.calendar-div .calendar-body .cell:last-child {
  border-right: none;
}

.calendar-div .calendar-body .cell .number {
  position: absolute;
  color: rgb(0, 0, 0);
  font-size: 19px;
  line-height: 1;
  top: .35em;
  right: .35em;
  font-weight: 700;
}

.calendar-div .calendar-body .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}

.cell-selected {
  background-color: rgb(92, 208, 26);
}

.calendar-div .calendar-body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar-div .calendar-body .cell-unavailable {
  background-color: rgb(248, 182, 182);
}

@media (min-width:1001px) and (max-width:1300px) {
  .calendar-div {
    padding: 0;
  }
}

@media (max-width: 550px) {
  .calendar-div {
    padding: 0;
  }
}