/*.produits-popup-checkbox[type=checkbox]:before {
    width: initial !important;
    height: initial !important;
    opacity: 0;
}

.produits-popup-checkbox[type=checkbox]:checked:after {
    content: normal !important;
}*/

.produits-popup-checkbox[type=number] {
    width: 75px !important;
}