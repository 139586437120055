.achat-forfait-card-container {
    /* border: 4px solid rgb(255, 103, 76, 1); */
    /* width: 65%; */
    width: 90%;
    margin: auto;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 32px;
    /* display: inline-block; */
}

.achat-forfait-tab {
    height: auto !important;
    padding-bottom: 8.5px;
}

.col-container {
    display: table;
    width: 100%;
}

.col {
    display: table-cell;
    padding: 16px;
}

@media screen and (max-width: 1550px) {
    .achat-forfait-card-container {
        margin-bottom: 24px;
        /* margin-top: 0px; */
    }
}

@media screen and (max-width: 1420px) {
    .achat-forfait-card-container {
        margin-bottom: 16px;
    }
}

@media screen and (max-width: 550px) {
    .achat-forfait-card-container {
        width: 99%;
    }
}

