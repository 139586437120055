:root {
    --primary-color: rgb(255, 103, 76);
    /* Couleur primaire définie dans les normes graphiques du site. Principale couleur pour accent */
    --secondary-color: #13322B;
    /* Couleur secondaire définie dans les normes graphiques du site */
    --ternary-color: rgb(208, 222, 187);
    /* Couleur ternaire définie dans les normes graphiques du site. Couleur secondaire pour accent */
    --light-gray: rgb(247, 247, 247);
    /* Teinte de gris léger définie dans les normes graphiques du site */
    --medium-gray: rgb(153, 153, 153);
    /* Teinte de gris moyen définie dans les normes graphiques du site et utilisée dans les contours et placeholders */
  }

  @font-face {
    font-family: Barlow-Regular;
    src: url(fonts/Barlow-Regular.ttf) format("truetype");
  }

  @font-face {
    font-family: Barlow-SemiBold;
    src: url(fonts/Barlow-SemiBold.ttf) format("truetype");
  }

  @font-face {
    font-family: Barlow-Bold;
    src: url(fonts/Barlow-Bold.ttf) format("truetype");
  }

  @font-face {
    font-family: Barlow-Black;
    src: url(fonts/Barlow-Black.ttf) format("truetype");
  }

  @font-face {
    font-family: Barlow-BoldItalic;
    src: url(fonts/Barlow-BoldItalic.ttf) format("truetype");
  }

  @font-face {
    font-family: MinionPro-Regular;
    src: url(fonts/MinionPro-Regular.otf);
  }

  * {
    font-family: Barlow-Regular;
  }

  body {
    margin: 0px;
    font-size: 14px;
  }

  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    -webkit-text-fill-color: rgb(153, 153, 153, 1);
  }

  .stepper-wrapper div div div div a, .stepper-wrapper div div div div span {
    line-height: 32px !important;
  }

  html .rc-time-picker {
    display: initial;
    position: relative;
    box-sizing: border-box;
  }

  html .rc-time-picker-input {
    border-radius: initial;
    color: initial;
    /*width: 100%;
      position: initial;
      display: initial;
      padding: initial;
      height: initial;
      cursor: initial;
      font-size: initial;
      line-height: initial;
      background-color: initial;
      background-image: initial;
      border: initial;
      transition: initial;*/
  }

  html .rc-time-picker-input:hover {
    cursor: pointer;
  }

  .rc-time-picker-panel-select li {
    font-family: 'Barlow-Regular';
    font-size: 1.2em;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1;
    /* Firefox */
  }

  span.label, div.label {
    font-family: Barlow-Bold;
    font-size: 1.2em;
    text-transform: uppercase;
  }

  span.text, div.text {
    font-family: Barlow-Regular;
    font-size: 1.2em;
  }

  .message {
    font-family: Barlow-SemiBold;
    width: 100%;
  }

  span.label.primary, div.label.primary {
    color: var(--primary-color);
  }

  .btn-span-wrapper {
    background-color: rgb(255, 103, 76, 1);
    clear: both;
    text-align: right;
    margin-top: 32px;
    padding: 8px;
  }

  .btn-span-wrapper:hover {
    cursor: pointer;
  }

  .primary-color-on-hover:hover {
    fill: var(--primary-color);
  }

  .btn-span-wrapper span {
    color: white;
    font-family: Barlow-Black;
    padding-right: 8px;
    font-size: 1.6em;
  }

  .primary-color {
    color: var(--primary-color);
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: black;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: black;
  }

  input, html .rc-time-picker-input {
    height: 40px;
    font-size: 2em;
    font-family: Barlow-Bold;
    border: 2px solid rgb(153, 153, 153, 1);
    vertical-align: bottom;
  }

  input::placeholder {
    color: rgb(153, 153, 153);
  }

  @supports not (-moz-appearance:none) {
    input[type=checkbox]:before {
        content: "";
        display: block;
        position: absolute;
        border: 2px solid rgb(153, 153, 153, 1);
        border-radius: 3px;
        background-color: white;
        width: 100%;
        height: 100%;
    }
  }

  input[type=checkbox]:checked:after {
    content: "X";
    display: block;
    position: absolute;
    font-family: Barlow-Bold;
    font-size: 2.1rem;
    /*top: 6px;*/
    left: 12px;
  }

  @supports not (-moz-appearance:none) {
    input[type=checkbox] {
        position: relative;
        cursor: pointer;
        width: 40px !important;
        height: 40px !important;
        margin: 0px !important;
        /* margin-bottom: 40px; */
    }
  }

  @supports (-moz-appearance:none) {
    input[type=checkbox] {
        position: relative;
        cursor: pointer;
        width: 40px;
        margin: 0px;
    }
  }

  .color-error {
    color: red;
  }

  select {
    height: 40px;
    border: 2px solid rgba(153, 153, 153, 1);
    appearance: none;
    /* background-image: url(/../../images/icons/drop-down.png); */
    background-repeat: no-repeat;
    background-position: right;
    background-position-x: calc(100% - 2px);
    background-size: 26px;
    font-family: Barlow-Bold;
    font-size: 2em;
    padding-left: 4px;
  }

  .unselectable {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .nopadding {
    padding: 0 !important
  }

  .height-100 {
    height: 100% !important
  }

  .marginbottom-1rem {
    margin-bottom: 1rem !important
  }

  .marginbottom-0 {
    margin-bottom: 0 !important
  }

  .marginright-1 {
    margin-right: 1rem !important
  }

  .position-relative {
    position: relative !important
  }

  select:hover {
    cursor: pointer;
  }

  option {
    font-family: Barlow-Bold;
  }

  .margin-left-auto {
    margin-left: auto;
  }

  .clickable:hover {
    cursor: pointer;
  }

  .text-center {
    text-align: center
  }

  .display-none {
    display: none !important;
  }

  .display-inline {
    display: inline;
  }

  .display-inline-block {
    display: inline-block;
  }

  .position-relative {
    position: relative;
  }

  .justify-content-between {
    justify-content: space-between !important
  }

  .flex-1 {
    flex: 1;
  }

  .display-flex {
    display: flex;
  }

  .d-flex {
    display: flex !important
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .text-white {
    color: white;
  }

  .text-red {
    color: red;
  }

  .border-none {
    border-right: none;
  }

  .cursor-default {
    cursor: default;
  }