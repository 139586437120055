.billet-unite-ul {
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    width: 100%;
    background: #F2F2F2;
    border-bottom: 3px solid #B11015;
}

/* .billet-unite-ul div#corps {
    margin: 0 10px;
    width: 940px;
    padding-top: 10px;
    padding-bottom: 20px;
    min-height: 600px;
} */

.billet-unite-ul div {
    display: block;
}

@media print, screen and (min-width: 40em) {
    .billet-unite-ul .medium-3 {
        width: 25%;
    }
}

.billetterie-page-retour-btn {
    display: flex;
    flex-direction: row;
    margin-left: 16%;
}

.billet-unite-ul .billet-unite-quantity {
    height: 35px;
    font-size: 1.4em;
    /* font-family: Barlow-Bold;
    border: 2px solid rgb(153, 153, 153, 1);
    vertical-align: bottom;
    position: relative;
    cursor: pointer; */
    width: 65px;
    /* margin: 0px; */
}

#billetterie-page-container .achat-forfait-card-container div.billet-quantite-wrapper,
#tirage-page-container .achat-forfait-card-container div.billet-quantite-wrapper {
    display: flex;

}
.billet-quantite{
    padding-right: 10px;
}

.billet-unite-ul p {
    text-align: left;
    margin: 0 0 1em 0;
    padding: 0;
    line-height: 1.4em;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.billet-unite-ul div.colonne-auto {
    /* float: left; */
    padding-top: 4px;
    padding-bottom: 8px;
    border-bottom: dashed 1px rgb(209, 209, 209);
    text-align: center;
    font-size: 18px;
}

.billet-unite-ul div.colonne-auto-right {
    float: right;
    margin: 0 20px 0 0;
}

.billet-unite-ul img {
    display: inline-block;
    vertical-align: middle;
    margin-top: 15px;
    max-width: 100%;
    width: 100%;
    height: auto;
}

/* .billet-unite-ul .billet-column:last-child:not(:first-child) {
    float: right;
} */

@media print, screen and (min-width: 40em) {
    .billet-unite-ul .medium-9 {
        width: 75%;
    }
}

.billet-unite-ul .billet-column {
    width: 100%;
    /* float: left; */
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    box-sizing: border-box;
}

.billet-unite-ul div.clear, .billet-unite-ul hr.clear {
    clear: both;
    height: 0;
    line-height: 0;
    border: 0;
    font-size: 0;
    margin: 0;
    padding: 0;
}

.billet-unite-ul hr {
    border: 0;
    height: 1px;
    background-color: #CCC;
    margin: 10px 0;
    padding: 0;
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
}

.billet-unite-ul .content-description {
    margin: 0 0 10px 0;
}

@media print, screen and (min-width: 64em) {
    .billet-unite-ul .large-text-justify {
        text-align: justify;
    }
}

.billet-unite-ul .text-left {
    text-align: left;
}

.billet-unite-ul p {
    text-align: left;
    margin: 0 0 1em 0;
    padding: 0;
    line-height: 1.4em;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

@media print, screen and (min-width: 40em) {
    .billet-unite-ul .medium-text-right {
        text-align: right;
    }
}

.billet-unite-ul .text-center {
    text-align: center;
}

.billet-unite-ul .col1 {
    width: 30%;
    float: left;
}

.billet-unite-ul .col2 {
    width: 70%;
    float: right;
}


.billet-unite-achat-btn {
    width: auto;
    height: auto;
    text-align: center;
    padding: 17px 1px 13px 1px;
}

.billet-unite-achat-btn span {
    font-family: Barlow-Black;
    font-size: 18px;
    color: rgb(255, 103, 76, 1);
    border: 4px solid rgb(255, 103, 76, 1);
    padding: 4px 12px 4px 12px;
}

.billet-unite-achat-btn span:hover {
    color: rgb(253, 136, 115);
}

@media (max-width: 600px) {
    .billet-unite-ul .billet-column {
        padding-right: 0.2375rem;
        padding-left: 0.2375rem;
    }
}

@media (max-width: 420px) {
    .billetterie-page-retour-btn {
        margin-left: 1%;
    }

    .billet-unite-ul .col1, .billet-unite-ul .col2 {
        width: 100%;
    }
}

@media (max-width: 200px) {
    .billet-unite-achat-btn {
        padding: 0;

    }

    .billet-unite-achat-btn span {
        font-size: 12px;
    }
}