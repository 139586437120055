.annulation-popup {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    flex-basis: auto;
    background-color: rgb(252, 245, 245);
    padding-bottom: 10px;
  }