.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c
}

.btn-success:hover {
    background-color: #449d44;
    border-color: #398439
}

.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
}

.btn-danger:hover {
    background-color: rgb(248, 118, 113);
    border-color: #ac2925;
}

@media screen and (max-width: 600px) {
    .actions .btn-danger {
        /*float: center;*/
    }
}

.div-image {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/******************************************************************/

.unite-card-inclus {
    /* overflow: hidden; */
    font-size: 18px;
}

.panier-item-card-thumbnail {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.panier-item-card-info {
    width: 75%;
    background-color: coral;
    margin: auto;
}

div.maindiv {
    position: relative;
    height: auto;
    border: solid;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 4px solid rgb(255, 103, 76);
    width: 100%;
    height: 300px;
    overflow: hidden;
    /* overflow-y:auto; */
}

div.maindiv > div.panier-item-card-section {
    height: 100%;
    margin-bottom: -4.5px;
    float: left;
}

div.maindiv > div:nth-of-type(1) {
  /*  width: 30%;*/
}

div.maindiv > div:nth-of-type(2) {
    /*width: 56%;*/
    flex-grow: 1;
    max-width: 56%;
    padding: 18px 10px 0 30px;
    word-wrap: break-word;
    display: inline-block;
    margin: auto;
}

div.maindiv > div:nth-of-type(2) span {
    float: left;
    clear: left;
    text-align: left;
}

div.maindiv > div:nth-of-type(3) {
    width: 14%;
    background-color: rgb(255, 103, 76);
    text-align: center;
}

div.maindiv > div:nth-of-type(3) span {
    display: block;
    width: 100%;
    /*border-top: 1px dashed rgba(0, 0, 0, 0.2);*/
}

.panier-item-card-info-nom {
    font-family: Barlow-Black;
    font-size: 24px;
}

.panier-item-card-info-description {
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 7px;
    font-style: oblique;
    color: rgb(240, 140, 59);
}

.panier-item-btn {
    border: 2px solid rgb(255, 255, 255);
    width: fit-content;
    margin: auto;
    margin-top: 20px;
    width: 90%;
    font-size: 17px;
    font-family: Barlow-Bold;
}

.panier-item-btn:hover {
    text-decoration: none;
    cursor: pointer;
}

.panier-item-btn:nth-of-type(1) {
    background-color: red;
}

.panier-item-btn:nth-of-type(2) {
    background-color: rgb(235, 215, 68);
}

.panier-item-card-info-icons {
    display: inline;
    width: 30%;
    background-color: gainsboro;
}

.panier-item-card-right-section {
    margin-left: 2px;
    font-size: 1.5rem;
    font-family: Barlow-Bold;
}

@media screen and (max-width: 700px) {
    div.maindiv {
        height: 600px;
    }

    div.maindiv div:nth-of-type(1) {
        clear: both;
        width: inherit;
        height: 250px;
        position: static;
        padding: 0px 10px 5px 10px;
    }

    div.maindiv div:nth-of-type(2) {
        padding: 10px 10px 0 16px;
        width: 75%;
        max-width: 75%;
    }

    div.maindiv div:nth-of-type(3) {
        width: 25%;
    }
}

@media screen and (max-width: 463px) {
    .panier-item-btn {
        font-size: 12px;
    }

    div.maindiv div:nth-of-type(1) {
        width: 100%;
        height: max-content;
        padding: 0;
    }
}