.recherche-component {
    /* display: inline-block; */
    /* border: 3px solid rgb(255, 103, 76, 1); */
    position: sticky;
    top: 5rem;
    margin-top: 6px;
    margin-left: 30px;
    margin-right: 15px;
}

.div-filtrer {
    display: none;
    text-align: center;
    position: sticky;
    top: 5rem;
    margin-top: 18px;
    margin-left: 6px;
    margin-right: 6px;
    cursor: pointer;
}

.recherche-div {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
}

.recherche-div select {
    width: 120px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
}

.recherche-small-icon-font {
    background-color: brown;
}

#recherche-btn {
    width: auto;
    background: rgb(255, 103, 76, 1);
    background-image: -webkit-linear-gradient(top, rgb(255, 103, 76, 1), rgb(241, 119, 98));
    background-image: -moz-linear-gradient(top, rgb(255, 103, 76, 1), rgb(241, 119, 98));
    background-image: -ms-linear-gradient(top, rgb(255, 103, 76, 1), rgb(241, 119, 98));
    background-image: -o-linear-gradient(top, rgb(255, 103, 76, 1), rgb(241, 119, 98));
    background-image: linear-gradient(to bottom, rgb(255, 103, 76, 1), rgb(241, 119, 98));
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    border-radius: 10px;
    text-shadow: 1px 1px 3px #9e859e;
    font-family: Georgia;
    color: #ffffff;
    font-size: 18px;
    text-decoration: none;
    margin-bottom: 3px;
    cursor: pointer;
}

#recherche-btn:hover {
    color: #faf4f4;
    background: rgb(255, 118, 94);
    background-image: -webkit-linear-gradient(top, rgb(241, 119, 98), rgb(255, 103, 76, 1));
    background-image: -moz-linear-gradient(top, rgb(241, 119, 98), rgb(255, 103, 76, 1));
    background-image: -ms-linear-gradient(top, rgb(241, 119, 98), rgb(255, 103, 76, 1));
    background-image: -o-linear-gradient(top, rgb(241, 119, 98), rgb(255, 103, 76, 1));
    background-image: linear-gradient(to bottom, rgb(241, 119, 98), rgb(255, 103, 76, 1));
    text-decoration: none;
}

.custom-achat-forfait-checkbox {
    margin-right: 0 !important;
    margin-top: 8px;
    margin-bottom: 5px;
}

.recherche-checkboxes {
    padding-bottom: 20px;
    display: block;
    padding: 5px;
}

.recherche-checkboxes .ais-facet--item {
    margin-top: 10px;
    white-space: nowrap;
}

.recherche-checkboxes .checkbox-filter {
    margin: 0;
    margin-right: 2px;
}

.recherche-checkboxes .ais-facet--label {
    position: relative;
    top: 2.5px;
    cursor: pointer;
}

#recherche-appliquer {
    background-color: rgb(255, 103, 76, 1);
    clear: both;
    text-align: center;
    /* margin-top: 20px; */
    height: auto;
    padding: 4px;
}

#recherche-appliquer span {
    border: 3px solid white;
    padding: 6px 10px 6px 10px;
    color: white;
    font-family: Barlow-Black;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center
}

@media screen and (max-width: 1210px) {
    #recherche-appliquer {
        text-align: center;
    }
}

@media (max-width:780px) {
    .recherche-component, .sommaire-large-screen {
        display: none;
    }

    .div-filtrer {
        display: block;
    }
}