header {
    background-color: black;
    width: 100%;
    height: 270px;
  }
  
  @media screen and (max-width: 375px) {
    header {
        height: 240px;
    }
  }
  
  #header-content {
    color: white;
    width: 100%;
    height: 270px;
    max-width: 1650px;
    margin: auto;
  }
  
  @media screen and (max-width: 375px) {
    #header-content {
        height: 240px;
    }
  }
  
  #header-left {
    height: 100%;
    padding: 8px;
    float: left;
    width: 540px;
    justify-content: center;
}

@media screen and (max-width: 760px) {
    #header-left {
        width: 100%;
    }
  }
  
  @media screen and (max-width: 375px) {
    #header-left {
        line-height: 224px;
    }
}

#header-left div div img {
    height: 5rem;
}

#header-left img:nth-child(2) {
    padding-left: 15px;
    width: calc(50% - 15px);
  }
  
  #header-right {
    height: 100%;
    float: right;
    padding-top: 96px;
    font-size: 26px;
    width: 680px;
  }
  
  @media screen and (max-width: 1240px) {
    #header-right {
        padding-top: 48px;
        width: calc(100% - 540px);
    }
  }
  
  @media screen and (max-width: 760px) {
    #header-right {
        width: 100%;
        background-color: black;
        padding-top: 0px;
        height: initial;
        padding-bottom: 32px;
    }
  }
  
  #header-right-sub-container {
    display: flex;
  }
  
  @media screen and (max-width: 1240px) {
    #header-right-sub-container {
        display: grid;
    }
  }
  
  @media screen and (max-width: 760px) {
    #header-right-sub-container {
        display: flex;
    }
  }
  
  @media screen and (max-width: 640px) {
    #header-right-sub-container {
        display: grid;
    }
}

#header-right-sub-container div {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    font-weight: bold;
    margin: auto;
  }
  
  @media screen and (max-width: 1240px) {
    #header-right-sub-container div {
        padding: 8px;
        width: 100%;
        text-align: center;
    }
}

#header-right-sub-container span:hover {
    cursor: pointer;
  }
  
  .add-margin-after-login {
    width: 711px !important;
  }