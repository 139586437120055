.unite-icon {
    width: 28px;
}

.unite-icon-wrapper {
    float: left;
}

.unite-icon-wrapper:not(:last-child) {
    margin-right: 20px;
}

.unite-person-count-wrapper {
    float: left;
    padding-top: 12px;
}

.unite-person-count-wrapper span {
    font-family: Barlow-Bold;
    font-size: 18px;
}

.unite-icon-circle {
    margin-left: -32px;
    margin-bottom: 5px;
    width: 37px;
}