#info-client {
    position: relative;
    text-align: center
}

.info-client-field {
    margin-top: 20px;
    text-align: center;
    width: 100%
}

.info-client-field span {
    text-align: left;
}

.info-client-field-label {
    display: inline-block;
    width: 30%;
    text-align: left;
    position:center
}

.info-client-field-input {
    margin-right: 0px;
    padding-right: 0px;
    width: 40%
}

.info-client-field-input::placeholder {
    color:darkgrey;
    font-size: 20px;
}

.info-client-field-select {
    font-size: 100%; 
    margin-right: 0px;
    min-height: 35px;
    padding-right: 0px;
    width: 40%
}
.info-client-left-demi-field-input {
    margin-right: 50px;
    padding-right: 0px;
    width: 15%
}
    
.info-client-left-demi-field-input::placeholder {
    color: darkgrey;
    font-size: 20px;
}
.info-client-right-demi-field-input {
    margin-left: 50px;
    margin-right: 0px;
    padding-right: 0px;
    width: 15%
}

.info-client-right-demi-field-input::placeholder {
    color: darkgrey;
    font-size: 20px;
}

.info-client-field-error {
    display: block;
    color: brown;
    font-size: 15px;
}

.hide-increment-input-number::-webkit-inner-spin-button,
.hide-increment-input-number::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
}

.hide-increment-input-number {
    -moz-appearance: textfield !important; 
    appearance: textfield !important; 
}


@media screen and (max-width: 940px) {
    #info-client {
        width: 320px;
    }
}

@media screen and (max-width: 720px) {
    #info-client {
        width: 100%;
        position: initial;
        height: initial;
        float: left;
        top: initial;
        min-height: initial;
    }
}