.rechercheContainer {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
}

@media screen and (max-width: 520px) {
    .rechercheContainer {
        width: 96%;
    }
}

.recherche-filtre {
    display: inline-block;
}

.recherche-filtre select {
    margin-right: 10px;
    margin-bottom: 5px;
    width: 160px;
}

#btn-recherche {
    background: rgb(255, 103, 76, 1);
    background-image: -webkit-linear-gradient(top, rgb(255, 103, 76, 1), rgb(241, 119, 98));
    background-image: -moz-linear-gradient(top, rgb(255, 103, 76, 1), rgb(241, 119, 98));
    background-image: -ms-linear-gradient(top, rgb(255, 103, 76, 1), rgb(241, 119, 98));
    background-image: -o-linear-gradient(top, rgb(255, 103, 76, 1), rgb(241, 119, 98));
    background-image: linear-gradient(to bottom, rgb(255, 103, 76, 1), rgb(241, 119, 98));
    -webkit-border-radius: 28;
    -moz-border-radius: 28;
    border-radius: 28px;
    text-shadow: 1px 1px 3px #9e859e;
    font-family: Georgia;
    color: #ffffff;
    font-size: 20px;
    padding: 0 20px 10px 20px;
    text-decoration: none;
}

#btn-recherche:hover {
    background: rgb(255, 103, 76, 1);
    background-image: -webkit-linear-gradient(top, rgb(241, 119, 98), rgb(255, 103, 76, 1));
    background-image: -moz-linear-gradient(top, rgb(241, 119, 98), rgb(255, 103, 76, 1));
    background-image: -ms-linear-gradient(top, rgb(241, 119, 98), rgb(255, 103, 76, 1));
    background-image: -o-linear-gradient(top, rgb(241, 119, 98), rgb(255, 103, 76, 1));
    background-image: linear-gradient(to bottom, rgb(241, 119, 98), rgb(255, 103, 76, 1));
    text-decoration: none;
}

@media screen and (min-width: 1550px) {
    #achat-forfait-page {
        width: 1560px;
        margin: auto;
    }
}

#btn-recherche #search-icon {
    position: relative;
    top: 12px;
}