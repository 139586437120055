.member-confirm-div {
  text-align: center;
  background-color: #ebebeb;
}

.member-confirm-btn {
  position: relative;
  color: white;
  border: none;
  cursor: pointer;
  width: 90%;
  margin-bottom: 2px;
  font-size: 16px;
  opacity: 0.85;
  height: 40px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.member-confirm-btn:hover {
  opacity: 1;
}

.member-confirm-btn {
  background-color: #4CAF50;
}

.member-confirm-btn:disabled, .member-confirm-btn[disabled] {
  cursor: not-allowed;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.member-error {
  font-size: 17px;
  background-color: #ebebeb;
  padding-bottom: 10px;
  color: crimson;
  width: auto;
  text-align: center;
}

.testbox div, .testbox form, .testbox fieldset, .testbox input, .testbox p {
  padding: 0;
  margin: 0;
}

.testbox input {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #4c4c4c;
}

.testbox p {
  font-size: 13px;
  width: auto;
  text-align: center;
}

.testbox {
  width: 400px;
  background-color: #ebebeb;
  /* -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, .31);
  -moz-box-shadow: 1px 2px 5px rgba(0, 0, 0, .31);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, .31); */
  /* border: solid 1px #cbc9c9; */
}

.testbox input[type=radio] {
  visibility: hidden;
}

.member-infos {
  height: 250px;
}

.testbox label.radio {
  cursor: pointer;
  text-indent: 18px;
  overflow: visible;
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  margin-left: 13%;
}

.testbox label.radio:before {
  background: #3a57af;
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.testbox label.radio:after {
  opacity: 0;
  content: '';
  position: absolute;
  width: 0.5em;
  height: 0.25em;
  background: transparent;
  top: 7.5px;
  left: 4.5px;
  border: 3px solid #ffffff;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.testbox input[type=radio]:checked+label:after {
  opacity: 1;
}

.testbox hr {
  color: #a9a9a9;
  opacity: 0.3;
}

.testbox input[type=text], .testbox input[type=password] {
  position: relative;
  width: 75%;
  height: 39px;
  -webkit-border-radius: 0px 4px 4px 0px/5px 5px 4px 4px;
  -moz-border-radius: 0px 4px 4px 0px/0px 0px 4px 4px;
  border-radius: 0px 4px 4px 0px/5px 5px 4px 4px;
  background-color: #fff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, .09);
  -moz-box-shadow: 1px 2px 5px rgba(0, 0, 0, .09);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, .09);
  border: solid 1px #cbc9c9;
  margin-top: 13px;
  padding-left: 10px;
  margin-left: 12.5%
}

.testbox input[type=password] {
  margin-bottom: 25px;
}

.testbox #icon {
  display: inline-block;
  width: 30px;
  background-color: #3a57af;
  padding: 8px 0px 8px 15px;
  margin-left: 15px;
  -webkit-border-radius: 4px 0px 0px 4px;
  -moz-border-radius: 4px 0px 0px 4px;
  border-radius: 4px 0px 0px 4px;
  color: white;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, .09);
  -moz-box-shadow: 1px 2px 5px rgba(0, 0, 0, .09);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, .09);
  border: solid 0px #cbc9c9;
}

.testbox .accounttype {
  margin-left: 8px;
  margin-top: 20px;
}

/* .testbox a.button {
  font-size: 14px;
  font-weight: 600;
  color: white;
  padding: 6px 25px 0px 20px;
  margin: 10px 8px 20px 0px;
  display: inline-block;
  float: right;
  text-decoration: none;
  width: 50px;
  height: 27px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #3a57af;
  -webkit-box-shadow: 0 3px rgba(58, 87, 175, .75);
  -moz-box-shadow: 0 3px rgba(58, 87, 175, .75);
  box-shadow: 0 3px rgba(58, 87, 175, .75);
  transition: all 0.1s linear 0s;
  top: 0px;
  position: relative;
}

.testbox a.button:hover {
  top: 3px;
  background-color: #2e458b;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
} */