#main-banner {
    width: 100%;
    overflow: hidden;
    height: 422px;
    position: relative;
}

#main-banner img {
    width: 100%;
    position: absolute;
    top: 0px;
    object-fit: cover;
}

@keyframes fade {
    0% {opacity: 1;}
    15% {opacity: 1;}
    25% {opacity: 0;}
    75% {opacity: 0;}
    90% {opacity: 0;}
    100% {opacity: 1;}
}

#main-banner img:nth-of-type(1).transition {
    margin-top: -57px;
    min-height: 670px;
}

#main-banner img:nth-of-type(2).transition {
    animation-delay: -6s;
    margin-top: -15%;
    min-height: 670px;
}

#main-banner img:nth-of-type(3).transition {
    animation-delay: -12s;
    margin-top: -42%;
    min-height: 1140px;
}

@media screen and (max-width: 1740px) {
    #main-banner img:nth-of-type(3).transition {
        margin-top: -717px;
    }
}

#main-banner img:nth-of-type(4).transition {
    animation-delay: -18s;
    margin-top: -2%;
    min-height: 670px;
}

#main-banner img.transition {
    animation-name: fade;
    animation-iteration-count: infinite;
    animation-duration: 24s;
}