#choix-unite-page {
    position: relative;
    background-color: black;
}

#choix-unite-recherche {
    background-color: black;
    width: 400px;
    padding: 32px;
    /*position: absolute;
      top: 0px;*/
    float: left;
    height: 100vh;
    position: sticky;
    top: 0;
    overflow: auto;
}

@media screen and (max-width: 760px) {
    .choix-unite-affichage {
        /*display: flex;*/
        justify-content: space-around;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .choix-unite-affichage.type-affichage-unite {
        width: 90%;
    }
}

@media screen and (max-height: 850px) {
    #choix-unite-recherche {
        overflow: auto;
    }
}

@media screen and (max-width: 1580px) {
    #choix-unite-recherche {
        width: 400px;
    }
}

@media screen and (max-width: 1280px) {
    #choix-unite-recherche.type-affichage-carte {
        width: 100%;
        position: initial;
        float: initial;
        min-height: initial;
    }
}

@media screen and (max-width: 1240px) {
    #choix-unite-recherche.type-affichage-unite {
        width: 100%;
        position: initial;
        float: initial;
        min-height: initial;
    }
}

@media screen and (max-width: 760px) {
    #choix-unite-recherche.type-affichage-liste {
        width: 100%;
        position: initial;
        float: initial;
        min-height: initial;
    }
}

#choix-unite-recherche input,
#choix-unite-recherche select {
    padding-left: 16px;
    font-size: 1.2em;
    border-color: black;
    background-color: #ffffff;
}

#choix-unite-recherche .calendar-icon {
    margin-left: -38px;
    height: 35px;
    padding: 2px;
}

.choix-unite-recherche-title-wrapper span,
.choix-unite-recherche-button-wrapper span {
    font-size: 1.4em;
    font-family: Barlow-Bold;
    color: rgba(255, 103, 76, 1);
}

.choix-unite-recherche-title-wrapper .choix-unite-recherche-title-wrapper-error {
    color: red;
    display: block;
    font-family: Barlow-Bold;
    font-size: 1.1em;
}

.choix-unite-recherche-section:not(:last-child) {
    margin-bottom: 8px;
}

.choix-unite-recherche-input-wrapper {
    padding: 5px 0;
    display: block;
}

.choix-unite-recherche-section date > .choix-unite-recherche-input-wrapper {
    display: block;
}

.mandatory-input-error {
    border-color: red !important;
    border-style: solid;
    border-width: 4px;
}

.choix-unite-recherche-demi-input-wrapper:nth-of-type(1) {
    padding-right: 0px;
}

.choix-unite-recherche-demi-input-wrapper {
    width: 100%;
}
.choix-unite-recherche-demi-input-wrapper:nth-of-type(2) {
    margin-left: 0px;
    margin-top: 16px;
}
@media screen and (max-width: 1580px) {
    .choix-unite-recherche-input-wrapper {
        display: block;
    }
    .choix-unite-recherche-demi-input-wrapper {
        width: 100%;
    }
}


.choix-unite-recherche-input-wrapper input {
    width: 100%;
}

.choix-unite-recherche-input-wrapper select {
    width: 100%;
}

.choix-unite-recherche-button-wrapper {
    background-color: rgba(255, 103, 76, 1);
    text-align: center;
    padding: 8px;
    clear: both;
    margin-top: 30px;
}

.choix-unite-recherche-button-wrapper:hover {
    cursor: pointer;
}

#choix-unite-recherche .choix-unite-recherche-button-wrapper span {
    color: white;
    font-family: Barlow-Bold;
}

#choix-unite-main-content {
    margin-left: 400px;
    width: 100%;
    padding: 32px;
    max-width: calc(100% - 400px);
    /* 480%: la section des règlements; (100% - 1550px) / 2: la largeur de la bande du menu principale excluant la marge de gauche; 16px: la marge à gauche des icônes de calendrier; */
    background-color: white;
}

#choix-unite-main-content.no-recherche {
    margin-left: 0px;
    max-width: 100%;
    padding-top: 0px;
}

@media screen and (max-width: 1580px) {
    #choix-unite-main-content {
        margin-left: 400px;
        max-width: calc(100% - 400px);
    }
}

@media screen and (max-width: 1580px) {
    #choix-unite-main-content.no-recherche {
        margin-left: 0px;
        max-width: 100%;

    }
}

@media screen and (max-width: 1280px) {
    #choix-unite-main-content.type-affichage-carte {
        margin-left: 0px;
        max-width: 100%;
        padding: 16px;
    }
}

@media screen and (max-width: 1240px) {
    #choix-unite-main-content.type-affichage-unite {
        margin-left: 0px;
        max-width: 100%;
        padding: 16px;
        display: flex;
    }
}

@media screen and (max-width: 760px) {

    #choix-unite-main-content.type-affichage-liste {
        margin-left: 0px;
        max-width: 100%;
        padding: 16px;
    }
}

#choix-unite-choix-type-affichage {
    border-bottom: 1px solid black;
    display: flow-root;
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 25;
}

.choix-type-affichage-wrapper {
    float: left;
    margin-bottom: 16px;
    /*margin-left: 16px;*/
    margin-right: 16px;
    display: table;
}

.choix-type-affichage-wrapper:not(:last-child) {
    margin-right: 48px;
}

.choix-type-affichage-wrapper span {
    font-family: Barlow-Black;
    display: table-cell;
    vertical-align: middle;
    font-size: 1.9em;
    padding-left: 8px;
}

.choix-type-affichage-search-icon {
    position: sticky;
    text-align: left;
    margin-top: 18px;
    top: 5rem;
}

.choix-type-affichage-search-icon:hover {
    cursor: pointer;
}

@media screen and (min-width: 761px) {
    .choix-type-affichage-search-icon {
        display: none;
    }

    .choix-type-recherche-wrapper {
        display: none;
    }
}

.type-affichage-icon:hover,
.type-affichage-label:hover {
    cursor: pointer;
}

.main-color-hover:hover {
    fill: rgba(255, 103, 76, 1);
    color: rgba(255, 103, 76, 1);
}

@media screen and (max-width: 408px) {
    .choix-type-affichage-search-icon {
        top: 10rem;
    }
}

.choix-unite-ajouter-au-panier-wrapper {
    background-color: rgba(255, 103, 76, 1);
    clear: both;
    text-align: center;
    margin-top: 32px;
    padding: 8px;
    width: 400px;
    float: right;
}

@media screen and (max-width: 1210px) {
    .choix-unite-ajouter-au-panier-wrapper {
        text-align: center;
        width: 100%;
    }
}

.choix-unite-ajouter-au-panier-wrapper:hover {
    cursor: pointer;
}

.choix-unite-ajouter-au-panier-wrapper span {
    color: white;
    font-family: Barlow-Black;
    padding-right: 8px;
    font-size: 2.1em;
    text-align: center;
}

#aucune-unite-wrapper {
    margin-top: 16px;
}

#aucune-unite-wrapper span {
    font-family: Barlow-Bold;
    font-size: 1.9em;
}
