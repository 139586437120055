.buttonAddToCart {
    right: 7px;
    bottom: 7px;
    display: flex;
    position: absolute;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    padding: 0;
    vertical-align: middle;
    justify-content: center;
    -moz-appearance: none;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    line-height: 1.75;
    font-family: Lato, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    width: auto;
    min-width: 32px;
    border-radius: 24px;
    color: #fff;
    background-color: rgb(255, 103, 76, 1);
    box-shadow: none;
}

.buttonAddToCart:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.buttonAddToCart:hover {
    text-decoration: none;
    background-color: rgb(228, 98, 76)
}

.small-svg {
    fill: currentColor;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
    color: #ffffff;
}