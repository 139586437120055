#calendrier-page-container nav {
    display: block
}

#calendrier-page-container a {
    background-color: transparent
}

#calendrier-page-container a:active, #calendrier-page-container a:hover {
    outline: 0
}

#calendrier-page-container strong {
    font-weight: bold
}

#calendrier-page-container h1 {
    margin: .67em 0
}

#calendrier-page-container button, #calendrier-page-container input,
#calendrier-page-container select {
    font: inherit;
    margin: 0
}

#calendrier-page-container button {
    overflow: visible
}

#calendrier-page-container button {
    text-transform: none
}

#calendrier-page-container button {
    -webkit-appearance: button
}

#calendrier-page-container button, #calendrier-page-container select {
    font-family: inherit;
    line-height: inherit
}

#calendrier-page-container a:hover, #calendrier-page-container a:focus {
    color: #23527c;
    text-decoration: underline
}

#calendrier-page-container a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

#calendrier-page-container img {
    vertical-align: middle
}

#calendrier-page-container .img-responsive {
    max-width: 100%;
    height: auto
}

#calendrier-page-container .img-rounded {
    border-radius: 6px
}

#calendrier-page-container h1, #calendrier-page-container h2 {
    font-family: inherit;
    line-height: 1.1;
    margin-top: 20px;
    margin-bottom: 10px
}

#calendrier-page-container p {
    margin: 0 0 10px
}

#calendrier-page-container ul {
    margin-top: 0
}

#calendrier-page-container .row {
    margin-right: -15px;
    margin-left: -15px
}

#calendrier-page-container .col-xs-4, #calendrier-page-container .col-xs-5, 
#calendrier-page-container .col-md-9, #calendrier-page-container .col-xs-12, 
#calendrier-page-container .col-md-12, #calendrier-page-container .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

#calendrier-page-container .col-xs-6, #calendrier-page-container .col-xs-12 {
    float: left
}

#calendrier-page-container .col-xs-12 {
    width: 100%
}

#calendrier-page-container .col-xs-8 {
    width: 66.66666667%
}

#calendrier-page-container .col-xs-6 {
    width: 50%
}

#calendrier-page-container .col-xs-5 {
    width: 41.66666667%
}

#calendrier-page-container .col-xs-4 {
    width: 33.33333333%
}

@media (min-width:768px) {
    #calendrier-page-container .col-sm-3, #calendrier-page-container #calendrier-page-container .col-sm-4, #calendrier-page-container #calendrier-page-container .col-sm-6, #calendrier-page-container .col-sm-9 {
        float: left
    }

    #calendrier-page-container .col-sm-9 {
        width: 75%
    }

    #calendrier-page-container .col-sm-6 {
        width: 50%
    }

    #calendrier-page-container .col-sm-4 {
        width: 33.33333333%
    }

    #calendrier-page-container .col-sm-3 {
        width: 25%
    }
}

@media (min-width:992px) {
    #calendrier-page-container .col-md-3, #calendrier-page-container .col-md-4, #calendrier-page-container .col-md-9, #calendrier-page-container .col-md-12 {
        float: left
    }

    #calendrier-page-container .col-md-12 {
        width: 100%
    }

    #calendrier-page-container .col-md-9 {
        width: 75%
    }

    #calendrier-page-container .col-md-4 {
        width: 33.33333333%
    }

    #calendrier-page-container .col-md-3 {
        width: 25%
    }
}

@media (min-width:1200px) {
    #calendrier-page-container .col-lg-9, #calendrier-page-container .col-lg-12 {
        float: left
    }

    #calendrier-page-container .col-lg-12 {
        width: 100%
    }

    #calendrier-page-container .col-lg-9 {
        width: 75%
    }
}

#calendrier-page-container .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

#calendrier-page-container .btn:focus, #calendrier-page-container .btn:active:focus, #calendrier-page-container .btn.active:focus, #calendrier-page-container .btn.focus, #calendrier-page-container .btn:active.focus, #calendrier-page-container .btn.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

#calendrier-page-container .btn:hover, #calendrier-page-container .btn:focus, #calendrier-page-container .btn.focus {
    color: #333;
    text-decoration: none
}

#calendrier-page-container .btn:active, #calendrier-page-container .btn.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}

#calendrier-page-container .btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc
}

#calendrier-page-container .btn-default:focus, #calendrier-page-container .btn-default.focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c
}

#calendrier-page-container .btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad
}

#calendrier-page-container .btn-default:active, #calendrier-page-container .btn-default.active {
    color: #333;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #adadad
}

#calendrier-page-container .btn-default:active:hover, #calendrier-page-container .btn-default.active:hover, #calendrier-page-container .btn-default:active:focus, #calendrier-page-container .btn-default.active:focus, #calendrier-page-container .btn-default:active.focus, #calendrier-page-container .btn-default.active.focus {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c
}

#calendrier-page-container .nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

#calendrier-page-container .nav>li {
    position: relative;
    display: block
}

#calendrier-page-container .nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px
}

#calendrier-page-container .nav>li>a:hover, #calendrier-page-container .nav>li>a:focus {
    text-decoration: none;
    background-color: #eee
}

#calendrier-page-container .nav-pills>li {
    float: left
}

#calendrier-page-container .nav-pills>li>a {
    border-radius: 4px
}

#calendrier-page-container .nav-pills>li+li {
    margin-left: 2px
}

#calendrier-page-container .nav-pills>li.active>a, #calendrier-page-container .nav-pills>li.active>a:hover, #calendrier-page-container .nav-pills>li.active>a:focus {
    color: #fff;
    background-color: #337ab7
}

#calendrier-page-container .nav-stacked>li {
    float: none
}

#calendrier-page-container .nav-stacked>li+li {
    margin-top: 2px;
    margin-left: 0
}

#calendrier-page-container .pull-right {
    float: right !important
}

#calendrier-page-container .pull-left {
    float: left !important
}

#calendrier-page-container h1 {
    font-weight: 500;
    font-size: 1.5em;
    color: #48535E
}

@media screen and (max-width:767px) {
    #calendrier-page-container h1 {
        font-weight: 700;
        font-size: 1em;
        margin-bottom: 10px
    }
}

#calendrier-page-container h2 {
    font-weight: 500;
    color: #333;
    margin: 0 !important
}

#calendrier-page-container img, #calendrier-page-container td, #calendrier-page-container a img {
    border: 0
}

#calendrier-page-container a, #calendrier-page-container a:hover {
    color: #2c97de;
    text-decoration: none
}

#calendrier-page-container ul {
    margin: 0;
    padding: 0
}

#calendrier-page-container input, #calendrier-page-container select {
    clear: none;
    border-style: solid;
    border-width: 1px;
    margin: 0;
    background: #fff;
    padding: 6px 7px;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", Helvetica, Roboto, Arial, sans-serif;
    -webkit-font-smoothing: subpixel-antialiased
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    #calendrier-page-container select {
        padding-right: 0
    }
}

#calendrier-page-container select, #calendrier-page-container input[type=text] {
    height: 34px
}

#calendrier-page-container input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    position: relative;
    right: -7px
}

#calendrier-page-container input, #calendrier-page-container select {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box
}

#calendrier-page-container input:focus, #calendrier-page-container textarea:focus {
    color: #000
}

#calendrier-page-container .nav-pills>li.active>a {
    background-color: slategrey !important;
    border-radius: 5px
}

#calendrier-page-container .fa {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1
}

#calendrier-page-container .fa-calendar:before {
    content: ""
}

#calendrier-page-container .fa-caret-down:before {
    content: ""
}

#calendrier-page-container .fa-check-square:before {
    content: ""
}

#calendrier-page-container .fa-chevron-left:before {
    content: ""
}

#calendrier-page-container .fa-chevron-right:before {
    content: ""
}

#calendrier-page-container .fa-lock:before {
    content: ""
}

#calendrier-page-container .fa-search:before {
    content: ""
}

#calendrier-page-container .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

#calendrier-page-container .fa {
    font-family: "Font Awesome 5 Free";
    font-weight: 900
}

#calendrier-page-container .fa.fa-file-o {
    font-family: "Font Awesome 5 Free";
    font-weight: 400
}

#calendrier-page-container .fa.fa-file-o:before {
    content: ""
}

#calendrier-page-container .fa.fa-calendar:before {
    content: ""
}

#calendrier-page-container .fa.fa-calendar-o {
    font-family: "Font Awesome 5 Free";
    font-weight: 400
}

#calendrier-page-container .fa.fa-calendar-o:before {
    content: ""
}

#calendrier-page-container .fa.fa-calendar {
    font-weight: 400
}

#calendrier-page-container h1 {
    padding-left: 0
}

#calendrier-page-container .date {
    cursor: pointer !important
}

@media screen and (max-width:767px) {
    #calendrier-page-container h1 {
        margin-top: 10px !important;
        margin-bottom: 10px !important
    }
}

#calendrier-page-container .cf-item-info {
    min-width: 140px;
    max-width: 200px
}

#calendrier-page-container .cf-item-list h2 {
    font-size: 1.5em;
    padding: 0
}

#calendrier-page-container .cf-item-list .cf-item-data {
    border-bottom: solid 1px #eee;
    padding: 1.5em 0
}

#calendrier-page-container .cf-item-list .cf-item-data .cf-item-title-summary {
    overflow: hidden
}

#calendrier-page-container .cf-item-list .cf-item-data .cf-item-thumb {
    text-align: right;
    padding-right: 0
}

#calendrier-page-container .cf-item-list .cf-item-data img {
    display: inline-block
}

#calendrier-page-container .mobile-title {
    display: none;
}

@media screen and (min-width:700px) {
    #calendrier-page-container .cf-item-list .cf-item-data img {
        max-height: 150px
    }
}

@media screen and (min-width:1400px) {
    #calendrier-page-container .cf-item-list .cf-item-data img {
        max-height: 180px;
        margin: -0.75em 0
    }
}

#calendrier-page-container .cf-item-status {
    text-align: center;
    color: black;
    background-color: #d4f296;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold
}

#calendrier-page-container .cf-item-status:hover {
    color: black;
    background-color: #c1ec69 !important
}

#calendrier-page-container .cf-item-status.CLOSED {
    color: firebrick;
    background-color: #f2bd96
}

#calendrier-page-container .cf-item-status.SOLDOUT:hover, #calendrier-page-container .cf-item-status.CLOSED:hover, #calendrier-page-container .cf-item-status.ERROR:hover {
    color: #871a1a;
    background-color: #eca069 !important
}

#calendrier-page-container .cf-item-summary {
    padding-top: 10px
}

#calendrier-page-container .res-btn {
    color: #555
}

#calendrier-page-container .btn i {
    font-size: 1em;
    width: 1em;
    text-align: center
}

#calendrier-page-container .cf-price {
    opacity: 0.8;
    font-size: 1em;
    padding: 0 0 0 0 !important;
    display: inline-block
}

#calendrier-page-container .cf-price strong {
    font-size: 1.1em
}

#calendrier-page-container .cf-price em {
    padding: 0 !important;
    display: inline;
    margin: 0 !important;
    font-size: 12px;
    font-style: normal;
    font-weight: normal !important;
    white-space: nowrap
}

#calendrier-page-container .cf-tab {
    margin: 0;
    max-width: 100%
}

#calendrier-page-container .date-btn-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

#calendrier-page-container .date-btn-group .date_btn {
    width: initial
}

@media screen and (max-width:667px) {
    #calendrier-page-container .mobile-title {
        display: block;
        margin-bottom: 10px;
        width: 100%;
    }

    #calendrier-page-container .cf-title {
        display: none
    }

    #calendrier-page-container .cf-item-summary {
        padding-top: 0
    }

    #calendrier-page-container .cf-item-info {
        width: 40%
    }

    #calendrier-page-container .cf-mobile {
        width: 60%
    }
}

@media screen and (max-width:550px) {
    .cf-item-thumb+.cf-item-title-summary {
        display: none
    }

    .cf-item-thumb {
        width: 80%
    }
}

@media screen and (max-width:420px) {
    .cf-item-title-summary {
        display: none
    }

    .cf-item-thumb {
        width: 100%
    }

    .cf-item-info, .cf-mobile {
        width: 50%
    }
}

@media screen and (max-width:340px) {
    #calendrier-page-container .res-btn {
        font-size: 11px
    }

    #calendrier-page-container .cf-item-thumb {
        padding-left: 0
    }

    #calendrier-page-container .cf-item-info {
        width: 66.6666666667%
    }

    #calendrier-page-container .cf-mobile {
        padding: 0;
        width: 33.3333333333%
    }
}

@media screen and (min-width:667px) {
    #calendrier-page-container .cf-title {
        display: block
    }
}

#calendrier-page-container .head a {
    border: solid 1px #fff !important;
    color: #777 !important;
    text-shadow: 1px 1px 1px #fff;
    background: #fff !important
}

#calendrier-page-container .date {
    text-align: center !important
}