.sommaire-item-options-div {
    margin-left: 12px
}


.options-inclus {
    margin: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}