.card {
    border: 4px solid rgb(255, 103, 76, 1);
    width: 416px;
    margin-top: 32px;
    margin-left: 48px;
    margin-right: 48px;
    display: inline-grid;
}

@media screen and (max-width: 1550px) {
    .card {
        margin-top: 32px;
        margin-left: 24px;
        margin-right: 24px;
    }
}

@media screen and (max-width: 1420px) {
    .card {
        margin-top: 16px;
        margin-left: 12px;
        margin-right: 12px;
    }
}

.card-thumbnail {
    height: 288px;
    overflow: hidden;
    background-color: gainsboro;
}

.card-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;    
}

.card-title {
    color: white;
    background-color: rgb(255, 103, 76, 1);
    font-size: 34px;
    height: 64px;
    padding-top: 9px;
}

.card-title span {
    font-family: Barlow-Black;
}

.card-section {
    color: black;
    background-color: rgb(255, 103, 76, 1);
    font-size: 22px;
    height: 40px;
    padding-top: 7px;
    padding-left: 12px;
    text-align: left;
    width: 100%;
    display: grid;
}

.card-section span {
    font-family: Barlow-Black;
}

.card-contents ul {
    margin-left: 4px;
}

.card-bullet-list-item {
    padding: 2%;
    font-size: 22px;
    text-align: left;
}

.card-bullet-list-item span {
    font-family: Barlow-Bold;
}

.card-bullet-list-item span:hover {
    cursor: pointer;
}

.card-buyable-list-item span {
    padding: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 18px;
    font-family: Barlow-Bold;
    display: inline-block;
}

.card-buyable-list-item-name-wrapper {
    width: 70%;
    float: left;
    margin-left: 4px;
}

@media screen and (max-width: 440px) {
    .card-buyable-list-item-name-wrapper {
        width: 100%;
    }
}

.card-buyable-list-item-name {
    text-align: left;
    float: left;
}

.card-buyable-list-item-montant-wrapper {
    width: 20%;
    float: left;
}

@media screen and (max-width: 440px) {
    .card-buyable-list-item-montant-wrapper {
        margin-left: 4px;
        width: initial;
    }
}

.card-buyable-list-item-montant {
    float: right;
}

.card-buyable-list-item-image-wrapper {
    width: 6%;
    margin-left: 2px;
    float: left;
}

@media screen and (max-width: 440px) {
    .card-buyable-list-item-image-wrapper {
        width: initial;
    }
}

.card-buyable-list-item img {
    padding-top: 11px;
    float: left;
}

.card-buyable-list-item img:hover {
    cursor: pointer;
}

span.card-buyable-list-item-action:hover {
    cursor: pointer;
}

.card-buyable-list-item-separator {
    border: 1px solid black;
    margin-left: 3%;
    margin-right: 3%;
    clear: both;
}