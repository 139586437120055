.centered {
    height: 100%;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center
}

.options-popup-div {
    background-color: #fff;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 0 1 auto;
    width: inherit;
    max-width: 100%;
    min-height: '256px';
    max-height: 90%;
}

.options-popup-div .btn-confirm {
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    background: none;
    background-color: #F1F1F1
}

.options-popup-div .btn-confirm .allowed {
    background-color: rgb(255, 103, 76, 1);
}

.div-title {
    flex: 0 0 auto;
    margin: 0;
    height: 62px;
    padding: 0px;
    background-color: rgb(255, 103, 76, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.options-popup-font {
    margin: 0;
    display: block;
    text-transform: none;
    color: #333333;
    font-family: Lato, sans-serif;
    font-weight: 400;
    line-height: 1.5
}

.options-popup-div .title {
    flex: 1;
    font-size: 17px;
    font-style: normal;
    text-align: center;
    font-weight: normal
}

.options-popup-btn {
    color: inherit;
    border: 0;
    margin: 0;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 2px;
    vertical-align: middle;
    justify-content: center;
    -moz-appearance: none;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.jss508 {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0
}

.jss649 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 0;
    position: absolute;
    overflow: hidden;
    border-radius: inherit;
    pointer-events: none
}

@-webkit-keyframes mui-ripple-enter {
    0% {
        opacity: 0.1;
        transform: scale(0)
    }
    100% {
        opacity: 0.3;
        transform: scale(1)
    }
}

@-webkit-keyframes mui-ripple-exit {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@-webkit-keyframes mui-ripple-pulsate {
    0% {
        transform: scale(1)
    }
    50% {
        transform: scale(0.92)
    }
    100% {
        transform: scale(1)
    }
}

.jss613 {
    color: #e41b23
}

.jss658 {
    color: #333333;
    cursor: text;
    display: inline-flex;
    font-family: Lato, sans-serif;
    line-height: 1.1875em;
    align-items: center
}

.jss667 {
    width: 100%;
    color: white;
}

.jss668 {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 6px 0 7px;
    display: block;
    min-width: 0;
    box-sizing: content-box;
    background: none;
    -webkit-tap-highlight-color: transparent
}

.jss668::-webkit-input-placeholder {
    color: currentColor;
    opacity: 0.42;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
}

.jss668:focus {
    outline: 0
}

.jss668:invalid {
    box-shadow: none
}

.jss668::-webkit-search-decoration {
    -webkit-appearance: none
}

.jss675 {
    position: relative
}

.jss679:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #e41b23;
    pointer-events: none
}

.jss679:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid #e41b23;
    pointer-events: none
}

.jss679:hover:not(.jss678):not(.jss677):not(.jss680):before {
    border-bottom: 1px solid #e41b23 !important
}

@media (hover:none) {
    .jss679:hover:not(.jss678):not(.jss677):not(.jss680):before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.42)
    }
}

.jss510 {
    color: #ffffff
}

.jss513 {
    color: rgba(0, 0, 0, 0.26)
}

.jss688 {
    width: 100%;
    position: relative;
    border-radius: 2px
}

.jss689 {
    cursor: pointer;
    min-width: 16px;
    user-select: none;
    padding-right: 32px;
    -moz-appearance: none;
    -webkit-appearance: none
}

.jss689:focus {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.05)
}

.jss692 {
    width: auto;
    height: auto;
    overflow: hidden;
    min-height: 1.1875em;
    white-space: nowrap;
    text-overflow: ellipsis
}

.jss694 {
    top: calc(50% - 12px);
    right: 0;
    color: rgba(0, 0, 0, 0.54);
    position: absolute;
    pointer-events: none
}

.jss582 {
    width: 1px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1)
}

.jss782 {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.12)
}

.jss895 {
    height: 94px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center
}

.jss896 {
    width: 70px;
    height: 30px
}

.jss897 {
    margin: 0px 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: normal
}

.jss696 {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 10px 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    border-radius: 2px;
    background-color: #FFFFFF
}

.jss700 {
    padding-right: 5px
}

@media (min-width:1280px) {
    .jss700 {
        padding-right: 10px
    }
}

.jss741 {
    height: 0;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
}

.jss742 {
    height: auto;
    overflow: visible
}

.jss743 {
    display: flex
}

.jss744 {
    width: 100%
}

.jss772 {
    position: relative
}

.jss772:before {
    top: -1px;
    left: 0;
    right: 0;
    height: 1px;
    content: "";
    opacity: 1;
    position: absolute;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 0, 0, 0.12)
}

.jss772.jss774+.jss772:before {
    display: none
}

.jss772:first-child:before {
    display: none
}

.jss773 {
    border-radius: 0
}

.jss773:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.jss773:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

@supports (-ms-ime-align:auto) {
    .jss773:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0
    }
}

.jss774:first-child {
    margin-top: 0
}

.jss774:last-child {
    margin-bottom: 0
}

.jss774:before {
    opacity: 0
}

.jss716 {
    height: 60px;
    padding: 0 24px 0 24px;
    min-height: 48px;
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
}

.jss716:hover:not(.jss719) {
    cursor: pointer
}

.jss716.jss717 {
    min-height: 64px
}

.jss717 {
    min-height: 60px !important
}

.jss720 {
    margin: 12px 0;
    display: flex;
    flex-grow: 1;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
}

.jss720>:last-child {
    padding-right: 32px
}

.jss720.jss717 {
    margin: 20px 0
}

.jss740 {
    display: flex;
    padding: 8px 24px 24px;
    padding-top: 0
}

.jss705 {
    color: #a5a5a5;
    font-size: 20px;
    font-style: normal;
    font-weight: normal
}

@media (max-width:959.95px) {
    .jss705 {
        font-size: 17px;
        font-style: normal;
        font-weight: normal
    }
}

.jss706 {
    color: #333333
}

.jss708 {
    display: flex;
    align-items: center;
    border-radius: 50px;
    justify-content: center;
    background-color: #FFFFFF
}

@media (max-width:1279.95px) {
    .jss708 {
        width: 30px;
        height: 30px
    }
}

@media (min-width:960px) {
    .jss708 {
        width: 42px;
        height: 42px
    }
}

.jss709 {
    background-color: #F1F1F1
}

.jss711 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.jss712 {
    display: flex;
    align-items: flex-end
}

@media (max-width:959.95px) {
    .jss713 {
        font-size: 17px;
        font-style: normal;
        font-weight: bold
    }
}

@media (min-width:600px) {
    .jss713 {
        font-size: 24px;
        font-style: normal;
        font-weight: bold
    }
}

.jss714 {
    color: #e41b23
}

.jss715 {
    margin: 0px 5px
}

.jss776 {
    margin: 0;
    transition: all 0.4s linear;
    box-shadow: none;
    background-color: #F1F1F1
}

.jss777 {
    flex: 1;
    display: flex;
    vertical-align: middle;
    justify-content: center
}

.jss778 {
    background-color: #FFFFFF
}

.jss779 {
    color: #6a6c6e;
    margin-bottom: 10px
}

@media (max-width:959.95px) {
    .jss779 {
        font-size: 14px;
        font-style: normal;
        font-weight: normal
    }
}

@media (min-width:600px) {
    .jss779 {
        font-size: 17px;
        font-style: normal;
        font-weight: normal
    }
}

.jss781 {
    margin-bottom: 20px
}

.jss783 {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    overflow-y: auto;
    list-style: none;
    -webkit-overflow-scrolling: touch
}

.jss911 {
    box-sizing: border-box;
    flex-shrink: 0
}

.jss912 {
    height: 100%;
    display: block;
    position: relative
}

.jss922 {
    flex-shrink: 0;
    margin-right: 0px;
    margin-bottom: 5px
}

@media (max-width:599.95px) {
    .jss922 {
        margin-bottom: 5px
    }
}

.jss918 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)
}

.jss919 {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center
}

.jss920 {
    flex-shrink: 0;
    margin-bottom: 10px
}

@media (max-width:599.95px) {
    .jss920 {
        margin-bottom: 5px
    }
}

.jss921 {
    right: 10px;
    bottom: 10px;
    display: flex;
    position: absolute;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end
}

@media (max-width:959.95px) {
    .jss921 {
        top: 0
    }
}

@media (max-width:1279.95px) {
    .jss945 {
        font-size: 12px;
        font-style: normal;
        font-weight: normal
    }
}

@media (min-width:960px) {
    .jss945 {
        font-size: 14px;
        font-style: normal;
        font-weight: normal
    }
}

.jss946 {
    font-size: 24px;
    transition: color 0.5s;
    vertical-align: middle
}

.jss943 {
    display: flex;
    align-items: flex-end;
    flex-direction: column
}

.jss944 {
    color: #e41b23;
    line-height: 1
}

.jss937 {
    display: flex
}

.jss938 {
    font-size: 17px;
    font-style: normal;
    text-align: left;
    word-break: break-word;
    font-weight: bold;
    line-height: 1;
    margin-right: 5px;
    vertical-align: middle
}

@media (max-width:1279.95px) {
    .jss938 {
        font-size: 12px;
        font-style: normal;
        font-weight: bold
    }
}

@media (min-width:960px) {
    .jss938 {
        font-size: 14px;
        font-style: normal;
        font-weight: bold
    }
}

.jss939 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.jss1624 {
    display: flex;
    margin-top: 5px;
    align-items: center;
    justify-content: space-between
}

.jss1625 {
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    line-height: 1
}

.jss1626 {
    padding-right: 5px
}

@media (max-width:959.95px) {
    .jss1626 {
        font-size: 12px;
        font-style: italic;
        font-weight: 400
    }
}

@media (min-width:600px) {
    .jss1626 {
        font-size: 12px;
        font-style: italic;
        font-weight: 400
    }
}

.jss1627 {
    color: #e41b23;
    line-height: 1
}

.jss1628 {
    flex: 1;
    display: flex;
    align-items: flex-end;
    padding-left: 5px;
    flex-direction: column
}

.jss1629 {
    overflow: hidden
}

.jss915 {
    height: 100%;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.jss916 {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 8px 5px 27px -5px rgba(0, 0, 0, 0.2)
}

.jss917 {
    padding: 10px
}

.jss784 {
    overflow: visible
}

.jss785 {
    overflow: visible
}

.jss2627 {
    flex: 1
}

.jss2628 {
    font-size: 12px;
    font-style: normal;
    align-self: stretch;
    text-align: left;
    font-weight: bold
}

.jss2629 {
    flex: 1;
    padding: 10px;
    display: flex;
    align-self: flex-start;
    flex-direction: column
}

.jss2630 {
    align-self: center
}

.jss2631 {
    color: #e41b23;
    font-size: 12px;
    font-style: normal;
    align-self: flex-start;
    font-weight: bold
}

.jss2632 {
    font-size: 14px;
    font-style: normal;
    text-align: left;
    font-weight: bold
}

.jss2633 {
    text-align: center
}

.jss2634 {
    color: #a5a5a5;
    font-size: 12px;
    font-style: italic;
    text-align: left
}

.jss2635 {
    top: 5px;
    /* color: #e41b23; */
    right: 5px;
    display: flex;
    position: absolute;
    align-items: center;
    border-radius: 999px;
    justify-content: center;
    background-color: rgb(255, 103, 76, 1);
}

.jss2637 {
    padding: 1px;
    font-size: 20px
}

.jss2730 {
    flex: 1;
    border: 1px solid #dddddd;
    margin: 1px;
    display: flex;
    position: relative;
    align-items: stretch;
    border-radius: 2px;
    pointer-events: auto
}

.jss2731 {
    flex: 1;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start
}

.jss2732 {
    margin: 0;
    border: 2px solid #e41b23;
    box-shadow: 0 4px 22px 0 rgba(65, 64, 66, 0.15)
}

.jss2733 {
    padding: 10px;
    align-self: stretch;
    margin-top: 5px
}

.jss2624 {
    flex: 1;
    border: 1px solid #dddddd;
    margin: 1px;
    display: flex;
    position: relative;
    align-items: stretch;
    border-radius: 2px;
    flex-direction: column;
    pointer-events: auto
}

.jss2625 {
    margin: 0;
    border: 2px solid #e41b23;
    box-shadow: 0 4px 22px 0 rgba(65, 64, 66, 0.15)
}

.jss2626 {
    flex: 1;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start
}

.jss2613 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center
}

.jss2614 {
    width: 40px;
    height: 40px;
    border: 1px solid #a5a5a5;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    border-radius: 999px;
    justify-content: center
}

.jss2615 {
    color: #e41b23;
    font-size: 17px;
    font-style: normal;
    font-weight: bold
}

.jss2616 {
    display: flex;
    margin-left: 15px;
    flex-direction: column
}

.jss2617 {
    font-size: 20px;
    font-style: normal;
    font-weight: bold
}

.jss2618 {
    color: #a5a5a5;
    font-size: 17px;
    font-style: normal;
    font-weight: bold
}

.jss2623 {
    flex: 1;
    display: flex
}

.div-price {
    height: 55px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: center;
    background-color: #F1F1F1 !important
}

.price-font {
    font-size: 17px;
    font-style: normal;
    font-weight: bold;
    color: red;
}

.jss819 {
    width: 20px;
    margin: 0px 0px 0px 5px;
    height: 20px
}

@media (max-width:1279.95px) {
    .jss819 {
        width: 30px;
        height: 30px;
        padding-bottom: 10px
    }
}

@media (min-width:1280px) {
    .jss819 {
        padding-top: 5px
    }
}

.jss820 {
    width: 20px;
    border: 2px solid #a5a5a5;
    height: 20px;
    padding: 0;
    border-radius: 999px
}

@media (max-width:1279.95px) {
    .jss820 {
        width: 30px;
        height: 30px
    }
}

.jss821 {
    flex: 1;
    width: 15px;
    color: #333333;
    height: 15px;
    text-align: center
}

@media (max-width:1279.95px) {
    .jss821 {
        width: 20px;
        height: 20px
    }
}

.jss807 {
    color: #a5a5a5;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis
}

.jss808 {
    margin: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.jss810 {
    display: flex;
    align-items: flex-end;
    flex-direction: column
}

.jss811 {
    color: #e41b23;
    font-size: 14px;
    font-style: normal;
    font-weight: bold
}

.jss822 {
    display: flex
}

.jss823 {
    flex: 1;
    width: calc(100% - 40px)
}

.jss824 {
    margin: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.jss825 {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.jss826 {
    font-size: 14px;
    font-style: normal;
    font-weight: bold
}

.jss827 {
    color: #a5a5a5;
    font-size: 14px;
    font-style: italic
}

.jss828 {
    display: flex;
    align-items: flex-end;
    flex-direction: column
}

.jss829 {
    color: #e41b23;
    font-size: 14px;
    font-style: normal;
    font-weight: bold
}

.jss831 {
    color: #e41b23;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    text-decoration-color: #e41b23
}

.jss832 {
    margin-left: 15px
}

.jss833 {
    padding: 20px;
    padding-bottom: 10px;
    flex-direction: column;
    background-color: #FFFFFF
}

.jss625 {
    flex: 2;
    margin: 0px 10px;
    display: flex
}

.jss623 {
    flex: 1;
    color: #333333;
    font-size: 17px;
    font-style: normal;
    font-weight: bold
}

.jss627 {
    flex: 2;
    margin: 0px 10px;
    display: flex
}

.jss629 {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center
}

.jss632 {
    flex: 2;
    display: flex;
    max-height: 100px;
    flex-direction: row
}

@media (min-width:960px) and (max-width:1279.95px) {
    .jss632 {
        flex: 3
    }
}

.options-popup-footer {
    position: relative;
    width: 100%;
    bottom: 0px;
    height: 56px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.confirm-font {
    flex: 1;
    display: flex;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    align-items: center;
    justify-content: center;
    color: #a5a5a5
}

.jss883 {
    height: 100%
}

.div-flex {
    flex: 1;
    display: flex;
    transition: all 0.3s;
    align-items: center;
    flex-direction: row;
    justify-content: center
}

.jss899 {
    display: flex;
    align-items: center;
    background-color: #FFFFFF
}

.jss900 {
    flex: 1;
    display: flex;
    padding: 10px;
    align-items: center
}

.jss901 {
    font-size: 12px;
    font-style: normal;
    font-weight: bold
}

.jss902 {
    font-size: 14px;
    font-style: normal;
    font-weight: bold
}

.jss903 {
    color: #e41b23;
    font-size: 14px;
    font-style: normal;
    font-weight: bold
}

.jss904 {
    flex: 1;
    display: flex;
    flex-direction: column
}

.jss905 {
    width: 30px;
    height: 30px;
    box-shadow: none;
    border-radius: 999px;
    background-color: #e41b23
}

.jss907 {
    padding: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #efefef
}

.jss908 {
    font-size: 17px;
    font-style: normal;
    font-weight: normal
}

.jss909 {
    margin-top: 1px
}

.jss910 {
    margin-top: 15px
}

.jss889 {
    height: 56px;
    display: flex;
    border-top: none
}

.jss891 {
    flex: 1;
    display: flex
}

.jss796 {
    position: relative
}

.jss797 {
    padding: 20px;
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    flex-direction: column;
    justify-content: center;
    background-color: #efefef
}

.jss798 {
    left: 0;
    right: 0;
    height: 16px;
    bottom: -8px;
    position: absolute;
    background: radial-gradient(closest-side, transparent, transparent 50%, #fff 50%);
    background-size: 16px 16px;
    background-repeat: repeat-x;
    background-position: 8px -8px
}

.jss799 {
    left: 0;
    right: 0;
    bottom: 8px;
    height: 16px;
    z-index: 400;
    position: absolute;
    background: radial-gradient(closest-side, #fff, #fff 50%, transparent 50%);
    background-size: 16px 16px;
    background-repeat: repeat-x;
    background-position: 0 8px
}

.jss801 {
    font-size: 17px;
    font-style: normal;
    font-weight: normal
}

.jss802 {
    font-size: 38px;
    font-style: normal;
    font-weight: bold
}

.jss803 {
    font-size: 20px;
    font-style: normal;
    font-weight: bold
}

.jss845 {
    font-size: 17px;
    font-style: normal;
    font-weight: bold
}

.jss846 {
    font-size: 17px;
    font-style: normal;
    font-weight: bold
}

.jss848 {
    padding: 20px;
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
    flex-direction: column
}

.jss849 {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row
}

.jss836 {
    padding: 20px;
    padding-top: 0;
    padding-bottom: 30px
}

.jss850 {
    position: relative
}

.jss851 {
    top: 0;
    left: 0;
    right: 0;
    height: 16px;
    z-index: 400;
    position: absolute;
    background: radial-gradient(farthest-side, #fff, #fff 50%, transparent 50%);
    background-size: 16px 16px;
    background-repeat: repeat-x;
    background-position: 0 0px
}

.jss852 {
    padding: 20px;
    padding-top: 30px;
    background-color: #efefef
}

.jss853 {
    top: -8px;
    left: 0;
    right: 0;
    height: 16px;
    z-index: 400;
    position: absolute;
    background: radial-gradient(farthest-side, transparent, transparent 50%, #fff 50%);
    background-size: 16px 16px;
    background-repeat: repeat-x;
    background-position: 8px 8px
}

.jss854 {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row
}

.jss857 {
    flex: 1;
    font-size: 17px;
    font-style: normal;
    font-weight: bold
}

.jss858 {
    color: #e41b23;
    font-size: 17px;
    font-style: normal;
    font-weight: bold
}

.jss838 {
    color: #e41b23;
    margin: 10px 0px;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    border-bottom: 1px solid #e41b23
}

.jss839 {
    margin: 0px 20px;
    border-top: 2px dashed rgba(0, 0, 0, 0.1)
}

.jss840 {
    margin: 20px 0px
}

.jss841 {
    padding: 5px 0px;
    font-size: 17px;
    font-style: normal;
    font-weight: bold
}

.jss866 {
    display: flex;
    flex-direction: column
}

@media (max-width:959.95px) {
    .jss866 {
        padding-bottom: 55px
    }
}

.jss867 {
    bottom: 0;
    z-index: 1000;
    position: fixed
}

@media (min-width:600px) {
    .jss868 {
        height: 56px
    }
}

.jss2734 {
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 999px;
    flex-direction: row;
    justify-content: center;
    background-color: #F1F1F1
}

@media (max-width:959.95px) {
    .jss2734 {
        padding: 3px
    }
}

.jss2735 {
    flex: 1;
    margin: 0px 10px;
    font-size: 12px;
    max-width: 30px;
    min-width: 20px;
    font-style: normal;
    text-align: center;
    font-weight: bold
}

.jss2736 {
    background-color: #e41b23
}

.jss2737 {
    background-color: #dddddd
}

.jss2738 {
    width: 24px;
    height: 24px;
    border-radius: 999px
}

.jss2739 {
    text-align: center
}

.jss960 {
    padding: 8px
}

@media (max-width:959.95px) {
    .jss960 {
        padding: 3px
    }
}

.jss962 {
    font-size: 0px;
    font-style: normal;
    transition: all 0.3s;
    font-weight: normal
}

.jss963 {
    transition: all 0.3s;
    box-sizing: border-box;
    border-radius: 999px;
    background-color: #e41b23
}

.jss964 {
    display: flex;
    justify-content: flex-end
}

.option-container-flex {
    flex: 1;
    overflow-y: auto;
    flex-basis: auto
}

.jss805 {
    display: flex;
    align-items: flex-end;
    flex-direction: column
}

.jss898 {
    color: #a5a5a5
}

@media (max-width:1279.95px) {
    .jss898 {
        font-size: 12px;
        font-style: normal;
        font-weight: normal
    }
}

@media (min-width:960px) {
    .jss898 {
        font-size: 14px;
        font-style: normal;
        font-weight: normal
    }
}

.jss701 {
    width: 50px;
    border: solid 1px #ffffff;
    height: 50px;
    display: flex;
    border-radius: 999px
}

@media (max-width:599.95px) {
    .jss701 {
        width: 35px;
        height: 35px;
        margin-right: 5px
    }
}

.jss702 {
    flex: 1;
    color: #ffffff;
    font-size: 17px;
    font-style: normal;
    text-align: center;
    font-weight: normal
}

@media (max-width:959.95px) {
    .jss702 {
        font-size: 14px;
        font-style: normal;
        font-weight: normal
    }
}

.jss520 {
    display: flex;
    align-items: center
}

.jss521 {
    max-width: 150px
}

@media (max-width:959.95px) {
    .jss521 {
        padding: 0 10px;
        max-height: 50px
    }
}

@media (min-width:600px) {
    .jss521 {
        padding: 0 40px;
        max-height: 80px
    }
}

.jss523 {
    color: #ffffff
}

.jss619 {
    border: 1px solid #ffffff;
    background-color: #e41b23
}

@media (max-width:1279.95px) {
    .jss619 {
        padding: 5px
    }
}

@media (min-width:960px) {
    .jss619 {
        padding: 15px
    }
}

.jss620 {
    color: #ffffff
}

@media (max-width:1279.95px) {
    .jss620 {
        font-size: 15px
    }
}

@media (min-width:960px) {
    .jss620 {
        font-size: 24px
    }
}

.jss621 {
    color: #ffffff
}

@media (max-width:1279.95px) {
    .jss621 {
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        padding-left: 5px
    }
}

@media (min-width:960px) {
    .jss621 {
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        padding-left: 15px
    }
}

.jss524 {
    display: flex;
    z-index: 400;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #e41b23
}

@media (max-width:959.95px) {
    .jss524 {
        height: 60px;
        padding: 0px 10px
    }
}

@media (min-width:600px) {
    .jss524 {
        height: 90px;
        padding: 0px 30px
    }
}

@media (max-width:599.95px) {
    .jss524 {
        padding: 0
    }
}

.jss525 {
    flex: 1;
    display: flex
}

.jss526 {
    color: #ffffff;
    padding: 20px;
    text-decoration: none
}

@media (max-width:959.95px) {
    .jss526 {
        font-size: 14px;
        font-style: normal;
        font-weight: normal
    }
}

@media (min-width:600px) {
    .jss526 {
        font-size: 24px;
        font-style: normal;
        font-weight: normal
    }
}

.jss527 {
    margin-left: 30px;
    margin-right: 30px
}

.jss529 {
    display: flex;
    z-index: 400;
    overflow: hidden;
    position: relative;
    max-width: 100%;
    flex-shrink: 0;
    background-color: #e41b23
}

.jss530 {
    flex: 1;
    display: flex;
    position: relative;
    max-width: 100%;
    flex-direction: column
}

@media (min-width:1280px) {
    .jss530 {
        height: 90px;
        flex-direction: row
    }
}

@media (max-width:959.95px) {
    .jss530 {
        min-height: 60px
    }
}

@media (min-width:600px) {
    .jss530 {
        min-height: 90px
    }
}

.jss531 {
    flex: 2 0 0
}

@media (max-width:959.95px) {
    .jss531 {
        flex: 2 0 auto
    }
}

.jss544 {
    display: flex;
    padding: 20px 10% 20px 10%;
    align-items: center;
    flex-direction: column;
    justify-content: center
}

@media (max-width:959.95px) {
    .jss544 {
        padding-bottom: 70px
    }
}

.jss786 {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between
}

@media (max-width:1279.95px) {
    .jss786 {
        padding: 10px 5px
    }
}

@media (min-width:960px) {
    .jss786 {
        padding: 10px
    }
}

.jss787 {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 0
}

.jss788 {
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: row
}

.jss790 {
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column
}

.jss791 {
    color: #e41b23;
    overflow: hidden;
    max-width: 90%;
    min-width: 0;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis
}

@media (max-width:959.95px) {
    .jss791 {
        font-size: 14px;
        font-style: normal;
        font-weight: bold
    }
}

@media (min-width:600px) {
    .jss791 {
        font-size: 17px;
        font-style: normal;
        font-weight: bold
    }
}

.jss792 {
    color: #6a6c6e;
    overflow: hidden;
    max-width: 90%;
    min-width: 0;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis
}

@media (max-width:959.95px) {
    .jss792 {
        font-size: 14px;
        font-style: normal;
        font-weight: normal
    }
}

@media (min-width:600px) {
    .jss792 {
        font-size: 17px;
        font-style: normal;
        font-weight: normal
    }
}

.jss793 {
    fill: #e41b23;
    margin: 0px 20px;
    display: flex;
    align-items: center
}

.jss794 {
    width: 20px;
    color: #e41b23;
    height: 29px
}

.jss533 {
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center
}

.jss534 {
    flex: 4;
    display: flex;
    padding: 20px 0px 20px 40px;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1)
}

@media (max-width:1279.95px) {
    .jss534 {
        padding: 20px 0px 20px 20px
    }
}

.jss537 {
    padding: 15px 0px
}

@media (min-width:960px) {
    .jss537 {
        flex: 1;
        height: 70px;
        display: flex;
        padding: 0px 40px;
        align-items: center;
        justify-content: center
    }
    @media (min-width:960px) and (max-width:1279.95px) {
        .jss537 {
            padding: 0px 20px
        }
    }
}

.jss538 {
    margin: 0px -10px
}

@media (min-width:960px) {
    .jss538 {
        flex: 3;
        margin: 0;
        display: flex;
        padding: 20px 40px;
        align-items: center
    }
    @media (min-width:960px) and (max-width:1279.95px) {
        .jss538 {
            padding: 20px
        }
    }
}

.jss539 {
    color: #333333
}

@media (max-width:1279.95px) {
    .jss539 {
        font-size: 14px;
        font-style: normal;
        font-weight: bold
    }
}

@media (min-width:960px) {
    .jss539 {
        font-size: 17px;
        font-style: normal;
        font-weight: bold
    }
}

.jss540 {
    z-index: 400;
    position: relative;
    box-shadow: 0 4px 22px 0 rgba(65, 64, 66, 0.15)
}

.jss542 {
    box-shadow: none
}

.jss566 {
    display: flex
}

.jss567 {
    flex: 2;
    max-width: 100%
}

.jss568 {
    margin: 30px
}

.jss569 {
    flex: 1;
    max-width: 550px
}

.options-popup-btn.not-allowed {
    cursor: not-allowed !important;
    pointer-events: auto !important;
}

.options-popup-btn.allowed {
    background-color: rgb(255, 103, 76, 1);
}