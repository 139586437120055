.popup-header-div {
    display: flex;
    box-shadow: 0 10px 22px 0 #dddddd;
}

.popup-header-text {
    flex: 1;
    margin: 15px 0px 0px 40px;
}

.popup-header-text p {
  font-family: Barlow-Regular;
}

.popup-header-text .popup-sub-header-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #a5a5a5;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    font-family: Barlow-Bold;
    line-height: 1.2em;
}

.popup-header-text .option-sub-header-name {
    font-size: 19px;
    font-weight: normal;
    line-height: 1.2em;
}

.popup-header-img {
    float: right;
    height: 80px;
    width: 110px;
    background-image: url('https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    margin: 1px 40px 1px 1px
}

@media (max-width:550px) {
    .popup-header-text .option-sub-header-name {
        font-size: 15px;
    }
    
    .popup-header-text {
        margin: 15px 0px 0px 10px;
    }

    .popup-header-img {
        margin: 1px 10px 1px 1px
    }
    
}

@media (max-width:360px) {
    .popup-header-div {
        display: inline-block;
    }
    .popup-header-img {
        margin: auto;
        float: none;
    }
}

@media (max-width:300px) {
    .popup-header-text {
        position: relative;
        margin: auto;
        text-align: center;
    }
    .popup-header-text {
        display: inline;
        margin: auto;
    }
}