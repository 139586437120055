#lecteur-cartes-membres-page {
}

#lecteur-cartes-membres-scanner {
    width: 100%;
    max-width: 480px;
    margin: 48px auto 48px auto;
}

#lecteur-cartes-membres-info div {
    padding: 8px;
    padding-left: 0px;
    width: 900px;
    margin: auto;
    display: flex;
}

@media screen and (max-width: 960px) {
    #lecteur-cartes-membres-info div {
        width: calc(100% - 32px);
        margin-left: 16px;
        margin-right: 16px;
        display: flow-root;
    }
}

.lecteur-cartes-membres-info-label-wrapper span {
    font-family: Barlow-Bold;
    font-size: 26px;
    float: left;
}

@media screen and (max-width: 960px) {
    .lecteur-cartes-membres-info-label-wrapper span {
        width: calc(100% - 32px);
    }
}

.lecteur-cartes-membres-info-text-wrapper span {
    font-family: Barlow-Bold;
    font-size: 26px;
    float: right;
    text-align: end;
    width: calc(100% - 32px);
}

@media screen and (max-width: 960px) {
    .lecteur-cartes-membres-info-text-wrapper span {
        float: left;
        text-align: left;
    }
}