:root {
  --danger-color: #dc3545;
  --success-color: #28a745;
}

#notifications {
  position: fixed;
  top: 0;
  height: 100%;
  max-width: 540px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 5000;
  pointer-events: none;
}

.alert-font {
  /* width: auto; */
  /* display: inline; */
  position: relative;
  max-width: 200px;
}

.alert .bell {
  margin-right: 10px;
}

.alert-danger {
  background: var(--danger-color);
}

.alert-success {
  background: var(--success-color);
}

#notifications>* {
  pointer-events: all
}

#notifications>div {
  transition: top .5s ease, opacity .5s ease;
  box-shadow: 0 0 4px #000;
  text-align: justify;
  outline: 1px solid #000;
  padding: 0.5rem;
  opacity: 0.99;
  color: #333;
  border-radius: 20px 20px;
  border: 0.5px solid white;
  color: #fff;
  font-size: 20px;
  position: relative;
  width: auto;
  height: auto;
  margin: 2% auto;
  left: 0;
  right: 0;
  /* display: inline-block; */
  top: 0;
}

/* #notifications>div>span {
  background-color: red;
  display: inline-block;
} */

.closebtn {
  margin-left: 20px;
  margin-right: 7px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 23px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}