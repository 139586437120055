.pagination .hover:hover:not(.off) {
    background-color: rgb(255, 154, 136);
    color: white;
}

.pagination li {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
}

.pagination li span {
    color: black;
    padding: 8px 14px;
    text-decoration: none;
}

ul.pagination li span.active {
    background-color: rgb(255, 103, 76, 1);
    color: white;
}

.text-centered {
    display: block;
    text-align: center;
}

.pagination-total {
    margin-bottom: 12px;
}

.ais-controls button {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: .5rem .625rem;
    border: .0625rem solid #d9d9d6;
    background-color: #fff;
    color: #444
}

.ais-controls button:disabled {
    color: #d9d9d6;
    cursor: default
}

.ais-controls button:disabled .fa {
    color: #d9d9d6
}

.ais-controls {
    margin-top: .9375rem;
    margin-bottom: .625rem
}

.products-grid .ais-controls {
    display: inline-block;
    width: 100%;
    padding: 0
}

@media (min-width:768px) {
    .products-grid .ais-controls {
        width: auto
    }
}

@media (min-width:1200px) {
    .ais-controls {
        position: absolute;
        right: 1.25rem;
        bottom: 1.75rem;
        margin-top: 0;
        margin-bottom: 0
    }
}

.ais-controls .ais-Pagination {
    font-size: 1rem
}

.ais-controls .ais-Pagination ul {
    margin: 0 0 0 1.25rem
}

@media (min-width:1200px) {
    .ais-controls .ais-Pagination ul {
        margin-left: 0
    }
}

@media screen and (max-width:767px) {
    .ais-controls {
        margin-top: 0
    }
}

.ais-footer {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center
}

.ais-footer>div {
    padding: 0.5rem 0
}

@media (max-width:768px) {
    .ais-footer {
        justify-content: space-evenly
    }
}

.ais-footer .ais-pagination-container {
    margin-top: 0
}

.ais-footer .ais-pagination button {
    width: auto;
    border: none;
    background: 0 0
}

.ais-footer .ais-Pagination button {
    width: auto;
    border: none;
    background: 0 0
}

.ais-footer .btn-prev {
    display: inline-block;
    padding-right: 0
}

.ais-footer .btn-prev button::before {
    position: relative;
    top: 0;
    left: 0
}

.ais-footer .btn-next {
    display: inline-block;
    padding-right: 0
}

.ais-footer .btn-next button::after {
    position: relative;
    top: 0;
    right: 0
}

.ais-page .ais-stats {
    display: flex;
    align-items: center;
    color: #444
}

.ais-page .ais-stats--nb-results {
    font-weight: 700
}

.ais-results-size-xs .ais-page .ais-stats {
    float: none
}

.pagination .off{
    cursor: default;
    color: rgb(178, 178, 178);
}