.marginRight {
  margin-right: 20px !important;
}

#reservation-unite-checkbox-terme-et-condition {
  margin-left: 15px;
  font-size: 28px;
  cursor: pointer;
}

.button-reservation-liste.disabled {
  color: #fff;
  border: none;
  cursor: not-allowed !important;
  border-radius: 5px;
}

.button-reservation-liste {
  margin-left: 15px;
}

.centered-middle {
  margin: auto;
  text-align: center;
  margin: auto;
  justify-content: center;
}

.centered-left {
  margin: auto;
  text-align: left;
  margin: auto;
  justify-content: center;
}

.reservation-unite-table {
  width: 95%;
}

.reservation-unite-table tbody td {
  padding: 5px !important;
}

@media (min-width: 940px) {
  #reservation-liste-table th {
    padding: 10px 15px;
  }
}