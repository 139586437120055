.calendrier-custum-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Barlow-Black" !important;
  font-size: 1em;
}

.calendar-custum-content {
  font-family: "Barlow-Black" !important;
  font-size: 1em;
  top: 50px;
  margin: 0 auto !important;
  overflow: hidden;
  position: sticky;
  display: grid;
  border: 2px solid rgb(255, 103, 76);
}

.header {
  /* padding: 12px 0px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header button {
  background: none !important;
  border: none;
  /* font-size: 1.2em; */
  cursor: pointer;
  /* padding: 5px 10px; */
  /* color: #fff; */
  /* border-radius: 50% */
}
/* .header button:hover {
  border-radius: 50%;
  background-color: rgba(255, 242, 238, 0.9);
} */


.header span {
  width: 45%;
  cursor: pointer;
  padding: 6px 0px;
  text-align: center;
  /* background: #fff; */
  font-weight: bold;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background: #ddd;
}

.day {
  text-align: center;
  padding: 10px;
  background: #eee;
  border-right: 1px solid #ddd;
  cursor: pointer;
}

.day:last-child {
  border-right: none;
}

.dates {
  gap: 10px;
  user-select: none;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 1fr;
}

.date-calendrrier-evenement {
  display: flex;
  width: 2em;
  height: 2em;
  text-align: center;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease, color 0.3s ease;
}

.clicked {
  background-color: red;
}
.date-calendrrier-evenement.disabled {
  /* background: #f5f5f5; */
  color: #aaa;
  pointer-events: none;
}

.date-calendrrier-evenement.selected {
  margin: 0px !important;
  background: rgb(149, 220, 101);
  color: #000;
}

.dates.next {
  animation: slideNext 0.5s ease forwards;
}

.dates.prev {
  animation: slidePrev 0.5s ease forwards;
}

@keyframes slideNext {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slidePrev {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.disabled-calendar {
  opacity: 0.5;
  pointer-events: none;
}