.zoomer {
    position: absolute;
    left: 16px;
    top: 24px;
    z-index: 1;
}

.zoomer-button {
    width: 48px;
    height: 48px;
    font-size: 35px;
    background-color: rgb(236, 236, 236);
    text-align: center;
}

.zoomer-button:hover {
    cursor: pointer;
}

.zoomer-button.plus {
    border: 1px solid black;
    border-radius: 2px 2px 0px 0px;
}

.zoomer-button.minus {
    border: 1px solid black;
    border-top: none;
    border-radius: 0px 0px 2px 2px;
}