.item-popup-background {
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  position: relative;
  overflow-y: auto;
  flex-direction: column;
  flex: 0 1 auto;
  width: inherit;
  margin: 0px;
}

.item-popup-div {
  height: 100%;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-panel {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1300;
  position: fixed
}

.dark-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  touch-action: none;
  background-color: rgba(77, 92, 116, 0.6);
  -webkit-tap-highlight-color: transparent;
  transition: 'opacity 225ms cubic-bezier(0.4,0,0.2,1) 0ms';
}

.jss604 {
  height: 100%;
  outline: none
}

.jss602 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.jss785 {
  background-color: #fff;
}

.jss811 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.jss605 {
  display: flex;
  position: relative;
  overflow-y: auto;
  flex-direction: column
}

.jss606 {
  flex: 0 1 auto;
}

.jss613 {
  width: inherit;
  margin: 0px;
}

.item-popup-btn {
  color: inherit;
  border: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 2px;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent
}

.jss2250 {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit
}

.jss620 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0
}

.item-popup-details {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  overflow-y: auto;
  flex-basis: auto;
  background-color: rgb(252, 245, 245);
  padding-bottom: 10px;
}

.item-popup-name {
  margin: 0;
  display: block;
  text-transform: none;
  color: #333333;
  font-family: Lato, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  text-align: center;
  font-weight: bold
}

@media (max-width:1279.95px) {
  .item-popup-name {
    font-size: 16px;
  }
}

@media (min-width:960px) {
  .item-popup-name {
    font-size: 21px;
  }
}

.btn-add-to-cart {
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 1.75;
  font-family: Barlow-Black;
  font-weight: 500;
  text-transform: uppercase;
  width: auto;
  height: 42px;
  padding: 0 4px;
  min-width: 48px;
  border-radius: 24px;
  background-color: rgb(255, 103, 76, 1);
  margin: 10px 0px;
  box-shadow: none;
  color: #fff;
}

.btn-add-to-cart:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.btn-add-to-cart:hover {
  text-decoration: none;
  background-color: rgb(228, 98, 76)
}

.item-popup-font {
  display: flex;
  flex: 1;
  font-size: 26px;
  display: block;
  text-transform: none;
  line-height: 1.5;
  color: #ffffff;
  font-family: Barlow-Bold;
  font-weight: normal;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* padding-top: 10px;  Modifie d'autre CSS !*/
  /* margin-bottom: 10px; */
}

.font-size-15 {
  font-size: 15px;
}

.item-popup-description {
  margin: 15px;
  font-size: 17px;
  max-width: 300px;
  font-style: normal;
  text-align: center;
  font-weight: normal
}

.item-popup-svg {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
  color: #ffffff;
}