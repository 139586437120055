.panier-page-title {
    font-size: 30px;
}

.pageContainer .btn {
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 4px
}

.btn-modify {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236
}

.btn-modify:hover {
    background-color: #ec971f;
    border-color: #d58512
}

@media screen and (max-width: 600px) {
    .btn-danger {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    table#cart .btn-modify {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.panier-item-name {
    color: black;
    font-family: Barlow-Regular;
    font-size: 25px;
}

.sc-list-head {
    border-bottom: 1px solid #DDD
}

.panier-page-div {
    padding-top: 20px;
    width: 70%;
}

@media screen and (max-width: 1000px) {
    .panier-page-div {
        width: 100%;
        overflow: hidden;
        text-align: center;
    }
    /* .panier-page-div span {
        display: inline-block;
        margin: auto;
        background-color: black;
    } */
}

.shop_transaction_failed h4, h3.edd-empty-cart-title {
    font-size: 28px;
    font-weight: 700;
}

h3.edd-empty-cart-title {
    text-align: center;
    padding: 140px 0 0;
    position: relative
}

[data-visual-label]::before {
    content: attr(data-visual-label);
    /* content: attr(data-visual-label) ": "; */
}

h3.edd-empty-cart-title:before {
    /* content: url(image.jpg); */
    /* content: ""; */
    font-family: "ETmodules";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border: 1px solid #ddd;
    color: #232323;
    border-radius: 50%;
    font-size: 50px;
    width: 112px;
    height: 112px;
    line-height: 112px;
    font-weight: 400
}

.edd-empty-cart-content {
    text-align: center;
    font-size: 17px;
    padding-bottom: 30px;
}

.edd-empty-cart-content .et_pb_button {
    cursor: pointer;
    color: white;
    background: rgb(255, 103, 76, 1);
    border-color: rgb(255, 103, 76, 1);
    font-size: 18px;
    padding: 15px 20px!important;
}

.edd-empty-cart-content .et_pb_button:hover {
    color: black;
    background: rgb(255, 115, 91);
    border-color: rgb(255, 115, 91);
}