/* General */
.container-enregistrement {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1% 22%;
}

.content-enregistrement {
    width: 100%;
    padding: 3% 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    box-shadow: 0px 0px 5px rgb(255, 103, 76, 1);
}

.title-section {
    width: 100%;
    margin-bottom: 25px;
    /* margin: 16px 0px; */
    font-family: "Barlow-Black";
}

/* Fermer.js */
.title-section span {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-family: "Barlow-Black";
}

.sub-title-section span{
    font-size: 1.4rem;
    font-family: "Barlow-Bold";
}

.sub-title-section {
    padding:20px 0px;
    /* 0% 0% 20px */
}

.contenair-table {
    width: 100%;
    margin: 0 7%;

}

.table {
    width: 100%;
    border-collapse: collapse;
}


.table th {
    font-size: 1.2rem;
    font-family: "Barlow-Bold";
}

.table th,
.table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
}

.table tbody tr:hover {
    background-color: #f0f0f0;
}

.table .clickable {
    cursor: pointer;
}

.table .clickable:hover{
    text-decoration: underline;
}


/* Ferme capture.js */

.content-body {
    width: 100%;
    padding: 0% 3.5% 2%;
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgb(255, 103, 76, 1);
}

.fermer-enregistrement-capture-container {
    justify-content: initial !important;
}

.content-calendar {
    left: 0;
    right: 0;
    top: 40px;
    color: black;
    z-index: 99999;
    position: absolute;
    background-color: white;
    border: 4px solid rgb(255, 103, 76) !important;
}

.fermer-enregistrement-content {
    width: 100%;
}
.fermer-enregistrement-button {
    padding: 8px;
    font-size:1.2rem;
    color: #f0f0f0;
    border-radius: 2px;
    margin: 20px 0px 0px !important;
    font-family: 'Barlow-Bold';
    border: 0px !important;
    background-color: rgb(255, 103, 76, 1);
}

/* Time Input.js */
.content-input-heure, .content-input-minutes {
    width: 100%;
}
.content-input-heure  input,
.content-input-minutes input {
    width: 100%;
    text-align: center;
}

.content-increment , .content-decrement{
    justify-content: center;
    align-items: center;
    display: flex
}

.content-increment button , .content-decrement button {
    margin: 0px !important;
    border: 0px !important;
}

/* Recherche Enregistrement.js */ 
.content-recherche-enregistrement {
    padding: 0% !important;
    box-shadow: none !important;
}
.content-render-activite {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 12px;
}

@media (max-width: 768px) {
    .content-render-activite {
        display: block !important;
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 12px;
    }
    .unite-card-activite {
        max-width: 100% !important;
    }
}

/* @media(min-width:768px) {
    .unite-card-activite {
        max-width: 100% !important;
    }
} */