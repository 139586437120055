#tirage-page {
    position: relative;
  }

  #tirage-page .tirage-item {
    /* max-width: 75%; */
  }
  
  #tirage-regles {
    background-color: black;
    width: 480px;
    padding: 32px;
    padding-left: 0px;
    padding-right: 0px;
    position: absolute;
    top: 0px;
    min-height: 100%;
  }
  
  @media screen and (max-width: 940px) {
    #tirage-regles {
        width: 320px;
    }
  }
  
  @media screen and (max-width: 720px) {
    #tirage-regles {
        width: 100%;
        position: initial;
        height: initial;
        float: left;
        top: initial;
        min-height: initial;
    }
  }
  
  #tirage-regles-title-wrapper, #tirage-regles-dot-dot-dot-wrapper {
    text-align: center;
  }
  
  #tirage-regles-title {
    font-size: 36px;
    color: rgb(208, 222, 187, 1);
    font-family: Barlow-Bold;
    padding-left: 32px;
    padding-right: 32px;
    display: block;
  }
  
  #tirage-regles ul {
    padding-right: 32px;
  }
  
  #tirage-regles li {
    color: white;
    font-family: Barlow-Regular;
    font-weight: bold;
    font-size: 14px;
  }
  
  .tirage-regles-separator {
    border-bottom: 1px solid white;
    margin-left: -17px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  #tirage-regles-dot-dot-dot {
    color: rgb(208, 222, 187, 1);
    font-size: 76px;
    line-height: 0px;
    font-weight: bold;
  }
  
  #tirage-formulaire {
    margin-left: 480px;
    width: 100%;
    padding: 32px;
    padding-left: 64px;
    max-width: calc(100% - 480px - (100% - 1550px) / 2 - 16px); /* 480%: la section des règlements; (100% - 1550px) / 2: la largeur de la bande du menu principale excluant la marge de gauche; 16px: la marge à gauche des icônes de calendrier; */
  }
  
  @media screen and (max-width: 1550px) {
    #tirage-formulaire {
        max-width: calc(100% - 480px - 16px);
    }
  }
  
  @media screen and (max-width: 940px) {
    #tirage-formulaire {
        width: calc(100% - 320px);
        margin-left: 320px;
        max-width: initial;
    }
  }
  
  @media screen and (max-width: 720px) {
    #tirage-formulaire {
        width: 100%;
        padding-left: 32px;
        margin-left: initial;
        float: left;
    }
  }
  
  #tirage-formulaire div {
    padding: 8px;
    padding-left: 0px;
  }
  
  @media screen and (max-width: 1480px) {
    #tirage-formulaire div {
        width: 100%;
    }
  }
  
  .tirage-formulaire-label-wrapper {
    width: 458px;
    float: left;
  }
  
  #tirage-formulaire span {
    font-family: Barlow-Bold;
    font-size: 26px;
    width: 480px;
  }
  
  #tirage-formulaire input[type=text] {
    width: calc(100% - 480px - 40px);
  }
  
  @media screen and (max-width: 1480px) {
    #tirage-formulaire input[type=text] {
        width: calc(100% - 64px);
    }
  }
  
  #tirage-formulaire img {
    height: 40px;
    margin-left: 16px;
    vertical-align: top;
  }
  
  #tirage-formulaire img:hover {
    cursor: pointer;
  }
  
  .tirage-formulaire-separator {
    border-bottom: 2px solid black;
    width: calc(100% - 524px + 480px + 40px); /* 100% - 640px: width du input; 480px: width du span; 40px: width du calendrier; */
    clear: both;
  }
  
  .tirage-formulaire-label-wrapper-title {
    width: 100%;
  }
  
  #tirage-formulaire .tirage-formulaire-label-wrapper-title span {
    font-family: Barlow-Black;
  }
  
  #tirage-formulaire .tirage-formulaire-choix-secteur {
    float: left;
    width: 25%;
  }
  
  @media screen and (max-width: 1480px) {
    #tirage-formulaire .tirage-formulaire-choix-secteur {
        width: 50%;
    }
  }
  
  @media screen and (max-width: 1050px) {
    #tirage-formulaire .tirage-formulaire-choix-secteur {
        width: 100%;
    }
  }

  .tirage-contact-info {
    max-width: 500px;
  }
  #tirage-page .container {
    display: flex;
  }

  #tirage-page .container select, #tirage-page .container input {
    width: 50%;
    box-sizing: border-box;
    margin: 5px;
  }
  
  #tirage-formulaire .tirage-formulaire-choix-secteur select {
    margin-left: 16px;
    /* min-width: 58px; */
  }
  
  #tirage-suivant-wrapper {
    background-color: rgb(255, 103, 76, 1);
    clear: both;
    text-align: right;
    margin-top: 32px;
  }
  
  @media screen and (max-width: 1210px) {
    #tirage-suivant-wrapper {
        text-align: center;
    }
  }
  
  #tirage-suivant-wrapper:hover {
    cursor: pointer;
  }
  
  #tirage-formulaire #tirage-suivant-wrapper span {
    color: white;
    font-family: Barlow-Black;
    padding-right: 8px;
  }