.generic-modal .overlay { /* Mêmes style visuel que la component Overlay */
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    /*font-size: 1.2em; Enlevé, sinon quoi les champs (input, span, etc.) à l'intérieur de .generic-modal .overlay se ramassent 1.2x plus gros que les champs à l'extérieur */
    color: #FFF;
    background: rgba(0, 0, 0, 0.7);
    z-index: 800;
    -webkit-transition: opacity 500ms ease-in;
    transition: opacity 500ms ease-in;
    opacity: 1;
}

.generic-modal .title {
    color: white;
    text-align: left;
    font-family: 'BARLOW-BOLD';
    font-size: 26px;
    float: left;
    margin-left: 16px;
    text-transform: uppercase;
}

.generic-modal .body {
    padding: 16px;
    /*max-height: calc(100% - 100px);
    height: calc(100% - 100px);*/
    overflow-y: auto;
}
@media screen and (max-height: 299px) {
    .generic-modal .body {
        max-height: 100px; /* max-height (ainsi que height) avec pourcentage fonctionne seulement si le parent a height. Or, on ne veut pas un height fix pour le parent, sinon quoi certaines instances de generic-popup deviennent trop grande. Une solution est d'avoir plusieurs max-height hardcodés avec différents "@media screen and (max-height: _)" */
    }
}
@media screen and (min-height: 300px) and (max-height: 399px) {
    .generic-modal .body {
        max-height: 200px; /* max-height (ainsi que height) avec pourcentage fonctionne seulement si le parent a height. Or, on ne veut pas un height fix pour le parent, sinon quoi certaines instances de generic-popup deviennent trop grande. Une solution est d'avoir plusieurs max-height hardcodés avec différents "@media screen and (max-height: _)" */
    }
}
@media screen and (min-height: 400px) and (max-height: 599px) {
    .generic-modal .body {
        max-height: 300px; /* max-height (ainsi que height) avec pourcentage fonctionne seulement si le parent a height. Or, on ne veut pas un height fix pour le parent, sinon quoi certaines instances de generic-popup deviennent trop grande. Une solution est d'avoir plusieurs max-height hardcodés avec différents "@media screen and (max-height: _)" */
    }
}
@media screen and (min-height: 600px) and (max-height: 799px) {
    .generic-modal .body {
        max-height: 400px; /* max-height (ainsi que height) avec pourcentage fonctionne seulement si le parent a height. Or, on ne veut pas un height fix pour le parent, sinon quoi certaines instances de generic-popup deviennent trop grande. Une solution est d'avoir plusieurs max-height hardcodés avec différents "@media screen and (max-height: _)" */
    }
}
@media screen and (min-height: 800px) and (max-height: 999px) {
    .generic-modal .body {
        max-height: 600px; /* max-height (ainsi que height) avec pourcentage fonctionne seulement si le parent a height. Or, on ne veut pas un height fix pour le parent, sinon quoi certaines instances de generic-popup deviennent trop grande. Une solution est d'avoir plusieurs max-height hardcodés avec différents "@media screen and (max-height: _)" */
    }
}
@media screen and (min-height: 1000px) and (max-height: 1199px) {
    .generic-modal .body {
        max-height: 800px; /* max-height (ainsi que height) avec pourcentage fonctionne seulement si le parent a height. Or, on ne veut pas un height fix pour le parent, sinon quoi certaines instances de generic-popup deviennent trop grande. Une solution est d'avoir plusieurs max-height hardcodés avec différents "@media screen and (max-height: _)" */
    }
}
@media screen and (min-height: 1200px) and (max-height: 1399px) {
    .generic-modal .body {
        max-height: 1000px; /* max-height (ainsi que height) avec pourcentage fonctionne seulement si le parent a height. Or, on ne veut pas un height fix pour le parent, sinon quoi certaines instances de generic-popup deviennent trop grande. Une solution est d'avoir plusieurs max-height hardcodés avec différents "@media screen and (max-height: _)" */
    }
}
@media screen and (min-height: 1400px) and (max-height: 1599px) {
    .generic-modal .body {
        max-height: 1200px; /* max-height (ainsi que height) avec pourcentage fonctionne seulement si le parent a height. Or, on ne veut pas un height fix pour le parent, sinon quoi certaines instances de generic-popup deviennent trop grande. Une solution est d'avoir plusieurs max-height hardcodés avec différents "@media screen and (max-height: _)" */
    }
}
@media screen and (min-height: 1600px) and (max-height: 1799px) {
    .generic-modal .body {
        max-height: 1400px; /* max-height (ainsi que height) avec pourcentage fonctionne seulement si le parent a height. Or, on ne veut pas un height fix pour le parent, sinon quoi certaines instances de generic-popup deviennent trop grande. Une solution est d'avoir plusieurs max-height hardcodés avec différents "@media screen and (max-height: _)" */
    }
}
@media screen and (min-height: 1800px) and (max-height: 1999px) {
    .generic-modal .body {
        max-height: 1600px; /* max-height (ainsi que height) avec pourcentage fonctionne seulement si le parent a height. Or, on ne veut pas un height fix pour le parent, sinon quoi certaines instances de generic-popup deviennent trop grande. Une solution est d'avoir plusieurs max-height hardcodés avec différents "@media screen and (max-height: _)" */
    }
}
@media screen and (min-height: 2000px) {
    .generic-modal .body {
        max-height: 1800px; /* max-height (ainsi que height) avec pourcentage fonctionne seulement si le parent a height. Or, on ne veut pas un height fix pour le parent, sinon quoi certaines instances de generic-popup deviennent trop grande. Une solution est d'avoir plusieurs max-height hardcodés avec différents "@media screen and (max-height: _)" */
    }
}

.generic-modal .generic-modal-header {
    display: flow-root;
    background-color: var(--secondary-color);
    padding-top: 5.5px; /* Devient la même hauteur qu'un bouton */
    padding-bottom: 5.5px; /* Devient la même hauteur qu'un bouton */
}

.generic-modal-footer {
    border-top: 6px solid var(--secondary-color);
}

.generic-modal-footer .singular-btn-holder {
    display: flex;
}

.generic-modal-footer .singular-btn-wrapper {
    text-align: center;
    margin-top: 0px;
    width: 100%;
    padding: 8px;
}

.generic-modal-footer .singular-btn-wrapper:hover {
    cursor: pointer;
}

.generic-modal-footer .singular-btn-wrapper span {
    color: white;
    font-family: Barlow-Black;
    font-size: 26px;
}

.generic-modal-footer .singular-btn-wrapper.positive {
    background-color: var(--primary-color);
}

.generic-modal-footer .binary-btn-holder {
    display: flex;
}

.generic-modal-footer .binary-btn-wrapper {
    text-align: center;
    margin-top: 0px;
    width: 50%;
    padding: 8px;
}

.generic-modal-footer .binary-btn-wrapper:hover {
    cursor: pointer;
}

.generic-modal-footer .binary-btn-wrapper span {
    color: white;
    font-family: Barlow-Black;
    font-size: 26px;
}

.generic-modal-footer .binary-btn-wrapper.positive {
    background-color: var(--primary-color);
}

.generic-modal-footer .binary-btn-wrapper.positive-left {
    background-color: var(--primary-color);
    border-right: 6px solid var(--secondary-color);
}

.generic-modal-footer .binary-btn-wrapper.negative {
    background-color: var(--text-color-light);
    border-left: 6px solid var(--secondary-color); /* Avec Windows, le bouton négatif est à droite. On fait la même chose avec le generic popup. */
}

.generic-modal-footer .binary-btn-wrapper.negative-left {
    background-color: var(--text-color-light);
    border-right: 6px solid var(--secondary-color);
}

.generic-modal-footer .binary-btn-wrapper.previous {
    background-color: var(--text-color-light);
    border-right: 6px solid var(--secondary-color);
}

.generic-modal .close:hover {
    cursor: pointer;
}

.generic-modal .content {
    z-index: 900;
    background-color: white;
    color: black;
    margin: auto;
    border: 6px solid var(--secondary-color);
    /*max-height: 100%;
    height: 100%;*/
}